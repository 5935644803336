import { Box, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

const SuccessModal = ({ message, handleSuccessClose }: any) => {
  return (
    <Box display="flex" flexDirection="column" padding="16px">
      <Box
        component="a"
        alignSelf="end"
        sx={{ cursor: "pointer" }}
        onClick={handleSuccessClose}
      >
        <CloseIcon sx={{ fontSize: 20 }} />
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography align="center">
          <CheckCircleOutlineIcon sx={{ fontSize: 60 }} color="success" />
        </Typography>

        <Typography align="center" variant="h5" color="primary">
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default SuccessModal;
