import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableHead,
  TablePagination,
  TableRow,
  TableBody,
  TableContainer,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getPdpaService } from "../../services/pdpa.service";
import { boxStyle } from "../../constants";
import { StyledTableCell, StyledTableRow } from "../../shared/style";
import { useAppDispatch } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "จัดการคำยินยอม",
    subtitles: ["รายการคำยินยอม"],
  },
};

const PdpaList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [pdpaList, setPdpaList] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getPdpa = () => {
    getPdpaService({ size: rowsPerPage, page: page + 1 }).then((resp) => {
      setPdpaList(resp.data.items);
      setCount(resp.data.total_row);
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigateEditPdpa = (id: any) => {
    navigate(`/edit-pdpa/${id}`);
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(getPdpa, [page, rowsPerPage]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box sx={boxStyle}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ width: "20%" }}>ลำดับ</StyledTableCell>
                <StyledTableCell sx={{ width: "70%" }}>
                  วันที่เผยแพร่
                </StyledTableCell>
                <StyledTableCell sx={{ width: "10%" }}>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pdpaList.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.id}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.active_date
                      ? dayjs(row.active_date)
                          .locale("th")
                          .format("DD MMM BBBB")
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box
                      component="a"
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleNavigateEditPdpa(row.id)}
                    >
                      <ModeEditIcon />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!pdpaList.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={3}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default PdpaList;
