import axiosService from "./axios.service";

interface Filters {
  page: any;
  size: any;
  member_id: any;
  username: any;
  phone_no: any;
  identification_number: any;
  zone: any;
  province_id: any;
}

export interface Member {
  type_id: number;
  temp_avatar_filename?: string;
  first_name?: string;
  last_name?: string;
  date_of_birth?: string;
  gender?: string;
  marriage_status?: string;
  height?: number;
  weight?: number;
  current_address?: string;
  current_moo?: string;
  current_subdistrict_id?: number;
  current_district_id?: number;
  current_province_id?: number;
  current_postcode?: string;
}

export const getMembersService = (params: Filters) => {
  return axiosService.get("/member?" + new URLSearchParams({ ...params }));
};

export const getMemberService = (id: any) => {
  return axiosService.get(`/member/${id}`);
};

export const patchMemberService = (id: any, body: Member) => {
  return axiosService.patch(`/member/${id}`, body);
};

export const deleteMemberService = (id: any) => {
  return axiosService.delete(`/member/${id}`);
};

export const postMemberAvatarUpload = (id: any, formData: FormData) => {
  return axiosService.post(`/member/${id}/avatar-upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postVerifyUsername = (params: any) => {
  return axiosService.post(
    "/member/verify-username?" + new URLSearchParams({ ...params })
  );
};

export const postVerifyIdentificationNumber = (params: any) => {
  return axiosService.post(
    "/member/verify-identification-number?" + new URLSearchParams({ ...params })
  );
};

export const postVerifyPhoneNo = (params: any) => {
  return axiosService.post(
    "/member/verify-phone-no?" + new URLSearchParams({ ...params })
  );
};

export const getReportMemberInfo = (params: any, ipAddress: string) => {
  return axiosService.get(
    "/member/report?" + new URLSearchParams({ ...params }),
    {
      params: { fe_ip: ipAddress },
    }
  );
};

export const getReportBmi = (params: any, ipAddress: string) => {
  return axiosService.get(
    "/member/report/bmi?" + new URLSearchParams({ ...params }),
    { params: { fe_ip: ipAddress } }
  );
};

export const getReportActivity = (params: any, ipAddress: string) => {
  return axiosService.get(
    "/member/report/activity?" + new URLSearchParams({ ...params }),
    { params: { fe_ip: ipAddress } }
  );
};

export const validateUsername = async (value: any, member_id: number) => {
  if (value.length < 6 || value.length > 16)
    return {
      inValid: true,
      error: {
        type: "custom",
        message: "ชื่อผู้ใช้ต้องมีความยาว 6-16 ตัวอักษร",
      },
    };

  const resp = await postVerifyUsername({ username: value, member_id });

  if (!resp.data.is_available)
    return {
      inValid: true,
      error: {
        type: "custom",
        message: "ผู้ใช้งานนี้ มีอยู่ในระบบแล้ว",
      },
    };

  return {
    inValid: false,
    error: {},
  };
};

export const validateFormatCitizenId = async (
  value: any,
  member_id: number
) => {
  let sum = 0;
  for (var i = 0; i < value.length - 1; i++) {
    sum += parseFloat(value.charAt(i)) * (13 - i);
  }

  if (
    value.substring(0, 1) === 0 ||
    value.length !== 13 ||
    (11 - (sum % 11)) % 10 !== parseFloat(value.charAt(12))
  ) {
    return {
      inValid: true,
      error: {
        type: "custom",
        message: "เลขบัตรผิด",
      },
    };
  }

  const resp = await postVerifyIdentificationNumber({
    identification_number: value,
    member_id,
  });

  if (!resp.data.is_available)
    return {
      inValid: true,
      error: {
        type: "custom",
        message: "เลขบัตรนี้ มีอยู่ในระบบแล้ว",
      },
    };

  return {
    inValid: false,
    error: {},
  };
};

export const validateWeight = (value: any) => {
  if (parseInt(value) < 10 || parseInt(value) > 200)
    return {
      inValid: true,
      error: { type: "custom", message: "น้ำหนัก จำกัดช่วง 10-200 กก." },
    };

  return {
    inValid: false,
    error: {},
  };
};

export const validateHeight = (value: any) => {
  if (parseInt(value) < 90 || parseInt(value) > 250)
    return {
      inValid: true,
      error: { type: "custom", message: "ส่วนสูง จำกัด 90-250 ซม." },
    };

  return {
    inValid: false,
    error: {},
  };
};

export const validatePhoneNo = async (value: any, member_id: number) => {
  if (value.length !== 10)
    return {
      inValid: true,
      error: {
        type: "custom",
        message: "เบอร์โทรศัพท์ต้องมีความยาว 10 ตัวอักษร",
      },
    };

  const resp = await postVerifyPhoneNo({ phone_no: value, member_id });

  if (!resp.data.is_available)
    return {
      inValid: true,
      error: {
        type: "custom",
        message: "เบอร์โทรศัพท์นี้ มีอยู่ในระบบแล้ว",
      },
    };

  return {
    inValid: false,
    error: {},
  };
};
