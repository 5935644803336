import { CssBaseline, ThemeProvider } from "@mui/material";
import { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// extra css
import "react-toastify/dist/ReactToastify.css";
// alert
import { ToastContainer } from "react-toastify";

// set time
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import timezone from "dayjs/plugin/timezone";
import localeData from "dayjs/plugin/localeData";

import { theme } from "./theme";

import AdminLayout from "./layouts/AdminLayout";
import BannerList from "./pages/banner/BannerList";
import CreateBanner from "./pages/banner/CreateBanner";
import EditBanner from "./pages/banner/EditBanner";
import LoginPage from "./pages/LoginPage";
import MemberList from "./pages/user/Member/MemberList";
import CodeRewardList from "./pages/reward/code_rewards/CodeRewardList";
import EditMember from "./pages/user/Member/EditMember";
import RewardList from "./pages/reward/rewards/RewardList";
import CreateReward from "./pages/reward/rewards/CreateReward";
import EditReward from "./pages/reward/rewards/EditReward";
import NewsList from "./pages/news/NewsList";
import EditNews from "./pages/news/EditNews";
import CreateNews from "./pages/news/CreateNews";
import FaqList from "./pages/faq/FaqList";
import CreateFaq from "./pages/faq/CreateFaq";
import EditFaq from "./pages/faq/EditFaq";
import HealthLiteracyList from "./pages/healthLiteracy/HealthLiteracyList";
import CreateHealthLiteracy from "./pages/healthLiteracy/CreateHealthLiteracy";
import EditHealthLiteracy from "./pages/healthLiteracy/EditHealthLiteracy";
import CourseList from "./pages/course/course/CourseList";
import CreateCourse from "./pages/course/course/CreateCourse";
import EditCourse from "./pages/course/course/EditCourse";
import CreateLesson from "./pages/course/lesson/CreateLesson";
import EditLesson from "./pages/course/lesson/EditLesson";
import CreateLessonQuiz from "./pages/course/lessonQuiz/CreateLessonQuiz";
import EditLessonQuiz from "./pages/course/lessonQuiz/EditLessonQuiz";
import MemberEvaluationList from "./pages/memberEvaluation/MemberEvaluationList";
import QuestionaireList from "./pages/questionaire/QuestionaireList";
import CreateQuestionaire from "./pages/questionaire/CreateQuestionaire";
import EditQuestionaire from "./pages/questionaire/EditQuestionaire";
import EditPdpa from "./pages/pdpa/EditPdpa";
import PdpaList from "./pages/pdpa/PdpaList";
import CommunityList from "./pages/community/CommunityList";
import EditCommunity from "./pages/community/EditCommunity";
import AdminList from "./pages/user/Admin/AdminList";
import CreateAdmin from "./pages/user/Admin/CreateAdmin";
import EditAdmin from "./pages/user/Admin/EditAdmin";
import RegisteredMemberSummary from "./pages/report/RegisteredMemberSummary";
import ActivitySummary from "./pages/report/ActivitySummary";
import ActivityTypeSummary from "./pages/report/ActivityTypeSummary";
import CommunitySummary from "./pages/report/CommunitySummary";
import DpacSummary from "./pages/report/DpacSummary";
import RoleAccess from "./pages/RoleAccess";
import MemberActivityHistory from "./pages/resultExercise/MemberActivityHistory";
import MemberActivitySummary from "./pages/resultExercise/MemberActivitySummary";
import { useBackgroundService } from "./services/task.service";
import HealthPlaceList from "./pages/healthPlace/HealthPlaceList";
import HealthPlaceResgister from "./pages/healthPlace/HealthPlaceResgister";
import HealthPlaceReport from "./pages/healthPlace/HealthPlaceReport";
import EditHealthPlace from "./pages/healthPlace/EditHealthPlace";
import EventList from "./pages/event/EventList";
import CreateEvent from "./pages/event/CreateEvent";
import EditEvent from "./pages/event/EditEvent";

require("dayjs/locale/th");
dayjs.extend(buddhistEra);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.locale("th");

export const App = () => {
  useBackgroundService();

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />

          <Route element={<AdminLayout />}>
            <Route element={<RoleAccess roles={[1]} />}>
              {/* faq */}
              <Route path="/faq-list" element={<FaqList />} />
              <Route path="/create-faq" element={<CreateFaq />} />
              <Route path="/edit-faq/:id" element={<EditFaq />} />
              {/* admin */}
              <Route path="/admin-list" element={<AdminList />} />
              <Route path="/create-admin" element={<CreateAdmin />} />
              <Route path="/edit-admin/:username" element={<EditAdmin />} />
              {/* banner */}
              <Route path="/banner-list" element={<BannerList />} />
              <Route path="/create-banner" element={<CreateBanner />} />
              <Route path="/edit-banner/:id" element={<EditBanner />} />
              {/* code reward */}
              <Route path="/code-reward-list" element={<CodeRewardList />} />
              {/* reward */}
              <Route path="/reward-list" element={<RewardList />} />
              <Route path="/create-reward" element={<CreateReward />} />
              <Route path="/edit-reward/:id" element={<EditReward />} />
              {/* news */}
              <Route path="/news-list" element={<NewsList />} />
              <Route path="/create-news" element={<CreateNews />} />
              <Route path="/edit-news/:id" element={<EditNews />} />
              {/* health literacy */}
              <Route
                path="/health-literacy-list"
                element={<HealthLiteracyList />}
              />
              <Route
                path="/create-health-literacy"
                element={<CreateHealthLiteracy />}
              />
              <Route
                path="/edit-health-literacy/:id"
                element={<EditHealthLiteracy />}
              />
              {/* course member evaluation*/}
              <Route
                path="/member-evaluation-list"
                element={<MemberEvaluationList />}
              />
              {/* lesson */}
              <Route
                path="/create-lesson/:courseId"
                element={<CreateLesson />}
              />
              <Route path="/edit-lesson/:lessonId" element={<EditLesson />} />
              {/* lesson quiz */}
              <Route
                path="/create-lesson-quiz/:courseId/:lessonId"
                element={<CreateLessonQuiz />}
              />
              <Route
                path="/edit-lesson-quiz/:lessonQuizId"
                element={<EditLessonQuiz />}
              />
              {/* questionaires */}
              <Route path="/questionaire-list" element={<QuestionaireList />} />
              <Route
                path="/create-questionaire"
                element={<CreateQuestionaire />}
              />
              <Route
                path="/edit-questionaire/:id"
                element={<EditQuestionaire />}
              />
              {/* pdpa */}
              <Route path="/pdpa-list" element={<PdpaList />} />
              <Route path="/edit-pdpa/:id" element={<EditPdpa />} />
            </Route>

            <Route element={<RoleAccess roles={[1, 2, 4]} />}>
              {/* report */}
              <Route
                path="/report/registered-member"
                element={<RegisteredMemberSummary />}
              />
              <Route path="/report/activity" element={<ActivitySummary />} />
              <Route
                path="/report/activity-type"
                element={<ActivityTypeSummary />}
              />
              <Route path="/report/community" element={<CommunitySummary />} />
              <Route path="/report/dpac" element={<DpacSummary />} />
              {/* member */}
              <Route path="/member-list" element={<MemberList />} />
              <Route path="/edit-member/:id" element={<EditMember />} />
              {/* course */}
              <Route path="/course-list" element={<CourseList />} />
              <Route path="/create-course" element={<CreateCourse />} />
              <Route path="/edit-course/:id" element={<EditCourse />} />
              {/* community */}
              <Route path="/community-list" element={<CommunityList />} />
              <Route path="/edit-community/:id" element={<EditCommunity />} />
              {/* resultExcise */}
              <Route
                path="/member-activity-history"
                element={<MemberActivityHistory />}
              />
              <Route
                path="/member-activity-summary"
                element={<MemberActivitySummary />}
              />
              {/* Health Place */}
              <Route
                path="/health-place-register"
                element={<HealthPlaceResgister />}
              />
              <Route path="/health-place-list" element={<HealthPlaceList />} />
              <Route
                path="/health-place-report"
                element={<HealthPlaceReport />}
              />
              <Route
                path="/edit-health-place/:id"
                element={<EditHealthPlace />}
              />
              {/* Event */}
              <Route path="/event-list" element={<EventList />} />
              <Route path="/create-event" element={<CreateEvent />} />
              <Route path="/edit-event/:id" element={<EditEvent />} />
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
    </Fragment>
  );
};
