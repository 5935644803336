import {
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import RemoveIcon from "@mui/icons-material/Remove";

import { boxStyle, configToast, modalStyle } from "../../constants";
import { StyledTableCell, StyledTableRow } from "../../shared/style";
import { useAppDispatch, useAppSelector } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";
import {
  deleteEventService,
  getEventsService,
} from "../../services/event.service";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import DeleteModal from "../../components/modals/DeleteModal";
import { getTask, setTask } from "../../state/libs/taskSlice";

const contentToolbar = {
  toolbar: {
    title: "กิจกรรม",
    subtitles: ["จัดการกิจกรรม"],
  },
};

const EventList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const taskState = useAppSelector(getTask);

  const [events, setEvents] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [eventId, setEventId] = useState<number | null>(null);

  const handleWarningOpen = (id: number) => {
    setEventId(id);
    setWarningOpen(true);
  };
  const handleWarningClose = () => {
    setEventId(null);
    setWarningOpen(false);
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  const getEvents = () => {
    getEventsService({ size: rowsPerPage, page: page + 1 }).then((resp) => {
      setEvents(resp.data.items);
      setCount(resp.data.total_row);
    });
  };

  const handleNavigateEditEvent = (eventId: string) => {
    navigate(`/edit-event/${eventId}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDevices = (clientType: number) => {
    const devices = [
      {
        id: 1,
        value: "Mobile",
      },
      {
        id: 2,
        value: "Web",
      },
    ];
    const results = [];
    for (const device of devices) {
      if ((device.id.toString(2) as any) & (clientType.toString(2) as any))
        results.push(device.value);
    }
    return results.join(", ");
  };

  const handleConfirmDelete = async () => {
    try {
      const id = eventId as number;
      handleWarningClose();
      await deleteEventService(id);
      getEvents();
      toast.success("ลบแบนเนอร์สำเร็จ", configToast);
    } catch (error) {
      toast.error("ลบแบนเนอร์ไม่สำเร็จ", configToast);
    }
  };

  const handleDownloadRegisteredMemberReport = (id: any) => {
    dispatch(
      setTask({
        id: "registeredMemberReport" + id,
        reportName: "registeredMemberReport",
        process: true,
        processing: false,
      })
    );
  };

  const handleDownloadEventActivityReport = (id: any) => {
    dispatch(
      setTask({
        id: "eventMemberActivityReport" + id,
        reportName: "eventMemberActivityReport",
        process: true,
        processing: false,
      })
    );
  };

  useEffect(getEvents, [page, rowsPerPage]);
  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box sx={boxStyle}>
      <Box>
        <Button
          color="green"
          variant="contained"
          startIcon={<SaveIcon sx={{ color: "white" }} />}
          component={RouterLink}
          to="/create-event"
        >
          <Typography color="white">สร้าง</Typography>
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", borderRadius: 0 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>กิจกรรม</StyledTableCell>
              <StyledTableCell>สถานะ</StyledTableCell>
              <StyledTableCell>สำหรับ</StyledTableCell>
              <StyledTableCell>Require consent</StyledTableCell>
              <StyledTableCell>ต้องยืนยันตัวตน</StyledTableCell>
              <StyledTableCell>วันลงทะเบียน</StyledTableCell>
              <StyledTableCell>วัน-เวลา กิจกรรม</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {row.event_name}
                </StyledTableCell>
                <StyledTableCell>
                  {row.is_publish ? "เผยแพร่" : "ไม่เผยแพร่"}
                </StyledTableCell>
                <StyledTableCell>{getDevices(row.client_type)}</StyledTableCell>
                <StyledTableCell>
                  {row.is_need_consent ? (
                    <DoneIcon color="success" />
                  ) : (
                    <RemoveIcon color="disabled" />
                  )}
                </StyledTableCell>

                <StyledTableCell>
                  {row.is_need_self_verification ? (
                    <DoneIcon color="success" />
                  ) : (
                    <RemoveIcon color="disabled" />
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {dayjs(row.register_start_date)
                    .locale("th")
                    .format("DD MMM BBBB")}
                  {" - "}
                  {dayjs(row.register_end_date)
                    .locale("th")
                    .format("DD MMM BBBB")}
                </StyledTableCell>
                <StyledTableCell>
                  {dayjs(row.event_start_date)
                    .locale("th")
                    .format("DD MMM BBBB HH:mm")}
                  {" - "}
                  {dayjs(row.event_end_date)
                    .locale("th")
                    .format("DD MMM BBBB HH:mm")}
                </StyledTableCell>

                <StyledTableCell>
                  <Box display="flex" gap="8px">
                    <Tooltip title="">
                      <IconButton
                        onClick={() => handleNavigateEditEvent(row.id)}
                      >
                        <ModeEditIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="">
                      <IconButton onClick={() => handleWarningOpen(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    {row.event_code === "HRHE-RUN" && (
                      <>
                        <Tooltip title="รายงานผู้ลงทะเบียน">
                          <IconButton
                            disabled={taskState.tasks.find(
                              (task: any) =>
                                task.id === "registeredMemberReport" + row.id
                            )}
                            onClick={() =>
                              handleDownloadRegisteredMemberReport(row.id)
                            }
                          >
                            <FileDownloadIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="รายงานผู้เข้าร่วมกิจกรรม">
                          <IconButton
                            disabled={taskState.tasks.find(
                              (task: any) =>
                                task.id === "eventMemberActivityReport" + row.id
                            )}
                            onClick={() =>
                              handleDownloadEventActivityReport(row.id)
                            }
                          >
                            <FileDownloadIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {!events.length && (
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  colSpan={8}
                  align="center"
                >
                  ไม่พบข้อมูล
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* modals */}
      <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
        <Box sx={modalStyle} width={340}>
          <DeleteModal
            handleConfirmDelete={handleConfirmDelete}
            handleWarningClose={handleWarningClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default EventList;
