import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";

const CropImageModal = ({
  image,
  openCropImage,
  handleCropImageClose,
  acceptCropAvatar,
}: any) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  const onCropComplete = useCallback(
    async (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  useEffect(() => {
    setZoom(1);
  }, []);

  return (
    <Dialog keepMounted disableEscapeKeyDown open={openCropImage}>
      <DialogContent sx={{ height: "350px", width: "500px" }}>
        <Box
          position="absolute"
          sx={{ top: 0, left: 0, right: 0, bottom: "80px" }}
        >
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: "16px", justifyContent: "center" }}>
        <Button
          color="red"
          variant="outlined"
          type="button"
          onClick={handleCropImageClose}
        >
          <Typography variant="subtitle2">ยกเลิก</Typography>
        </Button>
        <Button
          color="green"
          variant="contained"
          type="button"
          onClick={() => acceptCropAvatar(croppedAreaPixels)}
        >
          <Typography variant="subtitle2" color="white">
            ยอมรับ
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CropImageModal;
