import { Navigate, Outlet } from "react-router-dom";
import { getItem } from "../services/localStorage.service";

const RoleAccess = ({ roles = [] }: any) => {
  const accountProfile = getItem("account_profile");

  return !roles.length ||
    roles.find(
      (t: any) => (t.toString(2) & accountProfile.role_id.toString(2)) > 0
    ) ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  );
};

export default RoleAccess;
