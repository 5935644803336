import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useForm } from "react-hook-form";
import th from "dayjs/locale/th";
import dayjs from "dayjs";

import { boxStyle } from "../../constants";
import { OverwriteAdapterDayjs } from "../../utils/common.util";
import { useAppDispatch, useAppSelector } from "../../state/hook";
import {
  getReportMemberActivitySummaryCsvService,
  getReportMemberActivitySummaryService,
} from "../../services/report.service";
import saveAs from "file-saver";
import { StyledTableCell, StyledTableRow } from "../../shared/style";
import { setApp } from "../../state/libs/appSlice";
import {
  getActivityTypesService,
  getDistrictService,
  getProvinceService,
  getSubdistrictService,
} from "../../services/masterData.service";
import { getItem } from "../../services/localStorage.service";
import { getTask, setTask } from "../../state/libs/taskSlice";

const contentToolbar = {
  toolbar: {
    title: "ส่งผลออกกำลังกาย",
    subtitles: ["ส่งผลรวมรายคน"],
  },
};

const MemberActivitySummary = () => {
  const dispatch = useAppDispatch();
  const taskState = useAppSelector(getTask);

  const accountProfile = getItem("account_profile");

  const [provinces, setProvinces] = useState([]);
  const [provinceId, setProvinceId] = useState<any>("");
  const [districs, setDistrics] = useState([]);
  const [subdistrict, setSubdistricts] = useState([]);
  const [provincesFittered, setProvincesFittered] = useState([]);
  const [summaries, setSummaries] = useState<any[]>([]);
  const [activities, setActivities] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [flag, setFlag] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);

  const { register, getValues, setValue } = useForm({
    defaultValues: {
      subdistrict_id: "",
      district_id: "",
      province_id: "",
      zone: accountProfile.zone,
      activity_type_id: "",
      member_id: "",
      from_date: "",
      to_date: "",
    },
  });

  const getProvinces = () => {
    getProvinceService().then((resp) => {
      setProvinces(resp.data);
      setProvincesFittered(
        !accountProfile.zone
          ? resp.data
          : resp.data.filter((t: any) => t.zone === accountProfile.zone)
      );
    });
  };

  const getDistricts = (provinceId: string) => {
    getDistrictService(provinceId).then((resp) => setDistrics(resp.data));
  };

  const getSubdistricts = (districtId: any) => {
    getSubdistrictService(districtId).then((resp) =>
      setSubdistricts(resp.data)
    );
  };

  const handleOnSearch = () => {
    getReportMemberActivityHistories();
  };

  const handleZoneChange = (event: SelectChangeEvent) => {
    setProvincesFittered(
      !event.target.value
        ? provinces
        : provinces.filter((t: any) => t.zone === parseInt(event.target.value))
    );
    setProvinceId("");
    setValue("province_id", "");
    setValue("district_id", "");
    setValue("subdistrict_id", "");

    setDistrics([]);
    setSubdistricts([]);
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setProvinceId(event.target.value);
    getDistricts(event.target.value);
    setValue("district_id", "");
    setValue("subdistrict_id", "");

    setSubdistricts([]);
  };

  const handleDistrictChange = (event: SelectChangeEvent) => {
    getSubdistricts(event.target.value);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExportReport = () => {
    dispatch(
      setTask({
        id: "memberActivitySummaryReport",
        reportName: "memberActivitySummaryReport",
        process: true,
        processing: false,
        filters: getValues(),
      })
    );
  };

  const handleFromDateChange = (value: any) => {
    const date = dayjs(value).format("YYYY-MM-DD");
    setFromDate(date);
    setValue("from_date", date);
  };

  const handleToDateChange = (value: any) => {
    const date = dayjs(value).format("YYYY-MM-DD");
    setToDate(date);
    setValue("to_date", date);
  };

  const getReportMemberActivityHistories = () => {
    if (!flag) {
      setFlag(true);
      return;
    }
    setLoading(true);
    getReportMemberActivitySummaryService({
      size: rowsPerPage,
      page: page + 1,
      ...getValues(),
    }).then((resp) => {
      setLoading(false);
      setSummaries(resp.data.items);
      setCount(resp.data.total_row);
    });
  };

  const getActivityTypes = () => {
    getActivityTypesService().then((resp) => {
      setActivities(resp.data);
    });
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(setContentToolbar, [dispatch]);

  useEffect(getReportMemberActivityHistories, [getValues, page, rowsPerPage]);

  useEffect(getActivityTypes, []);

  useEffect(getProvinces, [accountProfile.zone]);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box sx={boxStyle}>
        <Box
          display="flex"
          justifyContent="space-between"
          gap="16px"
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box display="flex" flexDirection="column" gap={4} width={1}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="zone-label">เขตสุขภาพ</InputLabel>
                  <Select
                    defaultValue={accountProfile.zone}
                    disabled={accountProfile.zone}
                    labelId="zone-label"
                    id="province"
                    label="เขตสุขภาพ"
                    {...register("zone")}
                    onChange={handleZoneChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {Array.from(Array(13).keys()).map((index: any) => (
                      <MenuItem key={index} value={(index + 1).toString()}>
                        {"เขตสุขภาพที่ " + (index + 1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="province-label">จังหวัด</InputLabel>
                  <Select
                    labelId="province-label"
                    id="province"
                    label="จังหวัด"
                    value={provinceId}
                    {...register("province_id")}
                    onChange={handleProvinceChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {provincesFittered.map(
                      (p: { id: number; name: string }) => (
                        <MenuItem key={p.id} value={p.id.toString()}>
                          {p.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="district-label">อำเภอ</InputLabel>
                  <Select
                    labelId="district-label"
                    id="district"
                    label="อำเภอ"
                    {...register("district_id", { required: true })}
                    onChange={handleDistrictChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {districs.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.id.toString()}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="subdistrict-label">ตำบล</InputLabel>
                  <Select
                    labelId="subdistrict-label"
                    id="subdistrict"
                    label="ตำบล"
                    {...register("subdistrict_id", { required: true })}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {subdistrict.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.id.toString()}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="activity-type">ประเภทกีฬา</InputLabel>
                  <Select
                    labelId="activity-type"
                    id="activity_type"
                    label="ประเภทกีฬา"
                    {...register("activity_type_id")}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {activities.map((row: any, index: any) => (
                      <MenuItem key={index} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    type="text"
                    label="EBIB CODE"
                    placeholder="ค้นหา EBIB CODE"
                    {...register("member_id")}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <LocalizationProvider
                    dateAdapter={OverwriteAdapterDayjs}
                    adapterLocale={th}
                  >
                    <DatePicker
                      label="วันเริ่ม"
                      views={["year", "month", "day"]}
                      inputFormat="DD/MMM/BBBB"
                      value={fromDate}
                      onChange={handleFromDateChange}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "วว/ดด/ปปปป",
                            readOnly: true,
                          }}
                          {...register("from_date", { required: true })}
                        />
                      )}
                    ></DatePicker>
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <LocalizationProvider
                    dateAdapter={OverwriteAdapterDayjs}
                    adapterLocale={th}
                  >
                    <DatePicker
                      label="วันสิ้นสุด"
                      views={["year", "month", "day"]}
                      inputFormat="DD/MMM/BBBB"
                      value={toDate}
                      onChange={handleToDateChange}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "วว/ดด/ปปปป",
                            readOnly: true,
                          }}
                          {...register("to_date", { required: true })}
                        />
                      )}
                    ></DatePicker>
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box display="flex" gap="8px" height="fit-content">
            <Box sx={{ position: "relative" }}>
              <Button
                variant="contained"
                startIcon={<SearchIcon sx={{ color: "white" }} />}
                color="green"
                disabled={loading}
                onClick={handleOnSearch}
              >
                <Typography color="white">ค้นหา</Typography>
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>

            <Box sx={{ position: "relative" }}>
              <Button
                variant="contained"
                color="blue"
                disabled={taskState.tasks.find(
                  (task: any) => task.id === "memberActivitySummaryReport"
                )}
                onClick={handleExportReport}
              >
                <Typography color="white">EXPORT</Typography>
              </Button>
              {taskState.tasks.find(
                (task: any) => task.id === "memberActivitySummaryReport"
              ) && (
                <CircularProgress
                  color="blue"
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={boxStyle}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ลำดับ</StyledTableCell>
                <StyledTableCell>EBIB CODE</StyledTableCell>
                <StyledTableCell>ชื่อ</StyledTableCell>
                <StyledTableCell>นามสกุล</StyledTableCell>
                <StyledTableCell>เบอร์โทรศัพท์</StyledTableCell>
                <StyledTableCell>เวลา</StyledTableCell>
                <StyledTableCell>ระยะทาง</StyledTableCell>
                <StyledTableCell>จำนวนแคลอรี่</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {summaries.map((row, index) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {(page - 1) * rowsPerPage + rowsPerPage + (index + 1)}
                  </StyledTableCell>
                  <StyledTableCell>{row.member_id}</StyledTableCell>
                  <StyledTableCell>{row.first_name}</StyledTableCell>
                  <StyledTableCell>{row.last_name}</StyledTableCell>
                  <StyledTableCell>{row.phone_no}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.minutes?.toLocaleString() || 0}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.distances?.toLocaleString() || 0}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.calories?.toLocaleString() || 0}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!summaries.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={7}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default MemberActivitySummary;
