import {
  Box,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import th from "dayjs/locale/th";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-classic";
import dayjs from "dayjs";

import { boxStyle, configToast, statusTypes } from "../../constants";
import { postQuestionaireService } from "../../services/questionaire.service";
import { OverwriteAdapterDayjs } from "../../utils/common.util";
import { useAppDispatch } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "จัดการแบบสอบถาม",
    subtitles: ["จัดการแบบสอบถาม", "สร้างแบบสอบถาม"],
  },
};

const CreateQuestionaire = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [saving, setSaving] = useState<boolean>(false);
  const [publishDate, setPublishDate] = useState<string | null>(null);

  const { register, handleSubmit, formState, setValue, trigger } = useForm({
    mode: "all",
    defaultValues: {
      title: "",
      header: "",
      detail: "",
      publish_date: "",
      is_publish: null,
    },
  });

  const { isValid, isDirty } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const handleNavigateQuestionaireList = () => {
    navigate("/questionaire-list");
  };

  const handleEditorHeaderChange = (event: any, editor: any) => {
    const data = editor.getData();
    setValue("header", data);
  };
  const handleEditorDetailnChange = (event: any, editor: any) => {
    const data = editor.getData();
    setValue("detail", data);
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      setSaving(true);

      await postQuestionaireService({
        ...values,
        is_publish: !values.is_publish ? true : false,
        publish_date: publishDate,
      });

      toast.success("สร้างแบบสอบถามสำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateQuestionaireList(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "สร้างแบบสอบถามไม่สำเร็จ", configToast);
      setSaving(false);
    }
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      display="flex"
      flexDirection={{ xs: "column-reverse", md: "row" }}
      gap="16px"
    >
      <Box sx={{ ...boxStyle }} width={{ xs: 1, md: "60%" }}>
        <FormControl>
          <TextField
            fullWidth
            type="text"
            label="ชื่อหัวข้อ"
            placeholder="กรอกชื่อหัวข้อ"
            {...register("title", { required: true })}
            size="medium"
          />
        </FormControl>

        <FormControl>
          <CKEditor
            editor={Editor}
            config={{ placeholder: "ชื่อแบบสอบถาม" }}
            onChange={handleEditorHeaderChange}
          />
        </FormControl>

        <FormControl>
          <CKEditor
            editor={Editor}
            config={{ placeholder: "รายละเอียด" }}
            onChange={handleEditorDetailnChange}
          />
        </FormControl>

        <FormControl>
          <LocalizationProvider
            dateAdapter={OverwriteAdapterDayjs}
            adapterLocale={th}
          >
            <DatePicker
              label="วันเริ่มต้น เผยแพร่"
              views={["year", "month", "day"]}
              inputFormat="DD/MMM/BBBB"
              value={publishDate}
              onChange={async (newValue: any) => {
                const date = dayjs(newValue).format("YYYY-MM-DD");
                await trigger("publish_date", { shouldFocus: true });
                setPublishDate(date);
                setValue("publish_date", date);
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "วว/ดด/ปปปป",
                    readOnly: true,
                  }}
                  {...register("publish_date", { required: true })}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl>
          <InputLabel id="status-label">สถานะ</InputLabel>
          <Select
            labelId="status-label"
            id="status-label"
            placeholder="เลือกสถานะ"
            label="สถานะ"
            {...register("is_publish", { required: true })}
            size="medium"
          >
            {statusTypes.map((status: string, index: number) => (
              <MenuItem key={index} value={index}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box display="flex" justifyContent="center" gap="16px">
          <Box>
            <Button
              variant="contained"
              startIcon={<SaveIcon sx={{ color: "white" }} />}
              color="green"
              size="large"
              type="submit"
              disabled={!isValid || !isDirty || saving}
            >
              <Typography color="white">บันทึก</Typography>
            </Button>
          </Box>

          <Box>
            <Button
              variant="contained"
              startIcon={<CancelIcon sx={{ color: "white" }} />}
              color="red"
              size="large"
              type="button"
              component={RouterLink}
              to="/questionaire-list"
            >
              <Typography color="white">ยกเลิก</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateQuestionaire;
