import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import SearchIcon from "@mui/icons-material/Search";

import { boxStyle, configToast } from "../../constants";
import { StyledTableCell, StyledTableRow } from "../../shared/style";
import { useAppDispatch, useAppSelector } from "../../state/hook";
import { useEffect, useState } from "react";
import { getHealthPlacesService } from "../../services/healthPlace.service";
import { useForm } from "react-hook-form";
import {
  getDistrictService,
  getProvinceService,
  getSubdistrictService,
} from "../../services/masterData.service";
import { setApp } from "../../state/libs/appSlice";
import { getItem } from "../../services/localStorage.service";

import { getTask, setTask } from "../../state/libs/taskSlice";
import { toast } from "react-toastify";

const contentToolbar = {
  toolbar: {
    title: "Health Place",
    subtitles: ["Health Place", "รายงาน"],
  },
};

const HealthPlaceReport = () => {
  const dispatch = useAppDispatch();
  const taskState = useAppSelector(getTask);

  const { register, getValues, setValue } = useForm({
    defaultValues: {
      zone: "",
      province_id: "",
      health_place_name: "",
      health_place_type: "",
      district_id: "",
      subdistrict_id: "",
    },
  });

  const [provinces, setProvinces] = useState<any[]>([]);
  const [provincesFittered, setProvincesFittered] = useState<any[]>([]);
  const [provinceId, setProvinceId] = useState<any>("");
  const [districtId, setDistrictId] = useState<any>("");
  const [subdistrictId, setSubdistrictId] = useState<any>("");
  const [districs, setDistrics] = useState([]);
  const [subDistrics, setSubDistrics] = useState([]);
  const [healthPlaces, setHealthPlaces] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getHealthPlaces = () => {
    getHealthPlacesService({
      size: rowsPerPage,
      page: page + 1,
      ...getValues(),
    }).then((resp) => {
      setHealthPlaces(resp.data.items);
      setCount(resp.data.total_row);
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setProvinceId(event.target.value);
    setDistrictId("");
    setSubdistrictId("");

    getDistricts(event.target.value);

    setValue("district_id", "");
    setValue("subdistrict_id", "");

    setSubDistrics([]);
  };

  const handleDistrictChange = (event: SelectChangeEvent) => {
    setDistrictId(event.target.value);
    setSubdistrictId("");

    getSubDistricts(event.target.value);

    setValue("subdistrict_id", "");
  };

  const handleSubDistrictChange = (event: SelectChangeEvent) => {
    setSubdistrictId(event.target.value);
  };

  const handleZoneChange = (event: SelectChangeEvent) => {
    setProvinceId("");
    setDistrictId("");
    setSubdistrictId("");

    setValue("province_id", "");
    setValue("subdistrict_id", "");
    setValue("district_id", "");

    setDistrics([]);
    setSubDistrics([]);

    setProvincesFittered(
      !event.target.value
        ? provinces
        : provinces.filter((t: any) => t.zone === parseInt(event.target.value))
    );
  };

  const getProvinces = () => {
    getProvinceService().then((resp) => {
      setProvinces(resp.data);
      setProvincesFittered(resp.data);
    });
  };

  const getDistricts = (provinceId: string) => {
    getDistrictService(provinceId).then((resp) => setDistrics(resp.data));
  };

  const getSubDistricts = (districtId: string) => {
    getSubdistrictService(districtId).then((resp) => setSubDistrics(resp.data));
  };

  const handleOnSearch = () => {
    getHealthPlaces();
  };

  const handleExportReport = () => {
    const isEmpty = Object.values(getValues()).every(
      (x) => x === null || x === ""
    );
    if (isEmpty) {
      toast.error("กรุณาใส่คำค้นหา", configToast);
      return;
    }

    dispatch(
      setTask({
        id: "healthPlaceReport",
        reportName: "healthPlaceReport",
        process: true,
        processing: false,
        filters: getValues(),
      })
    );
  };

  const handleNavigate = (url: string) => {
    window.open(url, "_blank");
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(setContentToolbar, [dispatch]);

  useEffect(getHealthPlaces, [getValues, page, rowsPerPage]);

  useEffect(getProvinces, []);

  return (
    <Box component="form" flexDirection="column" display="flex" gap="16px">
      <Box sx={boxStyle}>
        <Box sx={boxStyle}>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            gap="16px"
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="zone-label">เขตสุขภาพ</InputLabel>
                  <Select
                    labelId="zone-label"
                    id="province"
                    label="เขตสุขภาพ"
                    {...register("zone")}
                    onChange={handleZoneChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {Array.from(Array(13).keys()).map((index: any) => (
                      <MenuItem key={index} value={(index + 1).toString()}>
                        {"เขตสุขภาพที่ " + (index + 1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="province-label">จังหวัด</InputLabel>
                  <Select
                    labelId="province-label"
                    id="province"
                    label="จังหวัด"
                    {...register("province_id")}
                    value={provinceId}
                    onChange={handleProvinceChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {provincesFittered.map(
                      (p: { id: number; name: string }) => (
                        <MenuItem key={p.id} value={p.id.toString()}>
                          {p.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="district-label">อำเภอ</InputLabel>
                  <Select
                    labelId="district-label"
                    id="district"
                    label="อำเภอ"
                    {...register("district_id", { required: true })}
                    value={districtId}
                    onChange={handleDistrictChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {districs.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.id.toString()}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="subdistrict-label">ตำบล</InputLabel>
                  <Select
                    labelId="subdistrict-label"
                    id="subdistrict"
                    label="ตำบล"
                    {...register("subdistrict_id", { required: true })}
                    value={subdistrictId}
                    onChange={handleSubDistrictChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {subDistrics.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.id.toString()}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="health-place-type">ประเภทสถานที่</InputLabel>

                  <Select
                    fullWidth
                    labelId="health-place-type"
                    id="health_place_type"
                    label="ประเภทสถานที่"
                    {...register("health_place_type", { required: true })}
                  >
                    <MenuItem value="">ทั้งหมด</MenuItem>
                    <MenuItem value={1}>สวนสาธารณะ</MenuItem>
                    <MenuItem value={2}>สนามกีฬา</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    type="text"
                    placeholder="ค้นหาด้วยชื่อสถานที่"
                    {...register("health_place_name")}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <Box display="flex" gap="8px">
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<SearchIcon sx={{ color: "white" }} />}
                    color="green"
                    onClick={handleOnSearch}
                  >
                    <Typography color="white">ค้นหา</Typography>
                  </Button>

                  <Box sx={{ position: "relative" }}>
                    <Button
                      variant="contained"
                      color="blue"
                      disabled={taskState.tasks.find(
                        (task: any) => task.id === "healthPlaceReport"
                      )}
                      onClick={handleExportReport}
                    >
                      <Typography color="white">EXPORT</Typography>
                    </Button>
                    {taskState.tasks.find(
                      (task: any) => task.id === "healthPlaceReport"
                    ) && (
                      <CircularProgress
                        color="blue"
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box sx={boxStyle}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ชื่อสถานที่ออกกำลังกาย</StyledTableCell>
                <StyledTableCell>ประเภทสถานที่</StyledTableCell>
                <StyledTableCell>จังหวัด</StyledTableCell>
                <StyledTableCell>อำเภอ</StyledTableCell>
                <StyledTableCell>ตำบล</StyledTableCell>
                <StyledTableCell>ผู้ลงทะเบียน</StyledTableCell>
                <StyledTableCell>Google Map</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {healthPlaces.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.name}</StyledTableCell>
                  <StyledTableCell>{row.place_type_name}</StyledTableCell>
                  <StyledTableCell>{row.province}</StyledTableCell>
                  <StyledTableCell>{row.district}</StyledTableCell>
                  <StyledTableCell>{row.subdistrict}</StyledTableCell>
                  <StyledTableCell>{row.registered_by}</StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex" gap="8px">
                      <Box
                        component="a"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleNavigate(row.google_map_url)}
                      >
                        <LaunchIcon />
                      </Box>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!healthPlaces.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={7}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default HealthPlaceReport;
