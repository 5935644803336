import { ChangeEvent, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  Modal,
} from "@mui/material";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-classic";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  deleteLessonQuizService,
  getLessonByIdService,
  getLessonQuizService,
  patchLessonService,
  postLessonUploadService,
} from "../../../services/course.service";
import { toast } from "react-toastify";
import { boxStyle, configToast, modalStyle } from "../../../constants";
import { getFileNameFromUrl } from "../../../utils/common.util";
import { StyledTableCell, StyledTableRow } from "../../../shared/style";
import dayjs from "dayjs";
import DeleteModal from "../../../components/modals/DeleteModal";
import { useAppDispatch } from "../../../state/hook";
import { setApp } from "../../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "การจัดการหลักสูตร",
    subtitles: ["รายการหลักสูตร", "แก้ไขหลักสูตร", "แก้ไขบทเรียน"],
  },
};

const EditLesson = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { lessonId } = useParams();

  const [lesson, setLosson] = useState<any>(null);
  const [courseId, setCourseId] = useState<any>(null);
  const [lessonQuiz, setLossonQuiz] = useState<any[]>([]);
  const [lessonFile, setLessonFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [lessonQuizId, setLessonQuizId] = useState<number | null>(null);

  const inputRef = useRef<HTMLInputElement | any>(null);

  const handleWarningOpen = (id: number) => {
    setLessonQuizId(id);
    setWarningOpen(true);
  };
  const handleWarningClose = () => {
    setLessonQuizId(null);
    setWarningOpen(false);
  };
  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setLessonFile(file);
    inputRef.current.value = "";
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const { register, handleSubmit, formState, setValue, trigger } = useForm({
    mode: "all",
  });
  const { isValid } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const editorConfig = { placeholder: "รายละเอียด" };

  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData();
    setValue("detail", data);
  };

  const getLessonById = () => {
    getLessonByIdService(lessonId).then((resp) => {
      setCourseId(resp.data.course_id);
      setLosson(resp.data);
    });
  };

  const getLessonQuiz = () => {
    getLessonQuizService(lessonId).then((resp) => {
      setLossonQuiz(resp.data);
    });
  };

  const setForm = () => {
    if (lesson) {
      setValue("title", lesson.title);
      setValue("detail", lesson.detail);
      setValue(
        "minutes_minimum",
        Math.floor(lesson.minimum_learning_time / 60)
      );
      setValue("second_minimum", lesson.minimum_learning_time % 60);
      setValue("minutes", Math.floor(lesson.learning_time / 60));
      setValue("second", lesson.learning_time % 60);

      setValue("passed_criteria", lesson.passed_criteria);
      setValue("clip_url", lesson.clip_url);
      setValue("lesson_order", lesson.lesson_order);
      setValue("gap_time", lesson.gap_time);

      trigger();
    }
  };

  const navigatePreviousRoute = () => {
    navigate(-1);
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      const {
        title,
        detail,
        minutes,
        second,
        minutes_minimum,
        second_minimum,
        passed_criteria,
        clip_url,
        lesson_order,
        gap_time,
      } = values;

      await patchLessonService(lessonId, {
        title,
        detail,
        minimum_learning_time: minutes_minimum * 60 + parseInt(second_minimum),
        learning_time: minutes * 60 + parseInt(second),
        passed_criteria,
        clip_url,
        lesson_order,
        gap_time,
      });

      if (lessonFile) {
        const formdata = new FormData();
        formdata.append("file", lessonFile as File);
        postLessonUploadService(lessonId, formdata);
      }

      toast.success("แก้ไขบทเรียนสำเร็จ", {
        ...configToast,
        onClose: navigatePreviousRoute,
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "แก้ไขบทเรียนไม่สำเร็จ", configToast);
      handleLoaded();
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const id = lessonQuizId as number;
      handleWarningClose();
      await deleteLessonQuizService(id);
      getLessonQuiz();
      toast.success("ลบคำถามสำเร็จ", configToast);
    } catch (error) {
      toast.error("ลบคำถามไม่สำเร็จ", configToast);
    }
  };

  const handleNavigateEditLessonQuiz = (lessonQuizId: string) => {
    navigate(`/edit-lesson-quiz/${lessonQuizId}`);
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(setForm, [lesson, setValue, trigger]);

  useEffect(getLessonById, [lessonId]);

  useEffect(getLessonQuiz, [lessonId]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      display="flex"
      flexDirection={{ xs: "column-reverse", md: "row" }}
      gap="16px"
    >
      {lesson && (
        <>
          <Box
            sx={{ ...boxStyle, height: "fit-content" }}
            width={{ xs: 1, md: "60%" }}
          >
            <FormControl>
              <TextField
                fullWidth
                type="text"
                label="ชื่อบทเรียน"
                placeholder="กรอกชื่อบทเรียน"
                {...register("title", { required: true })}
                size="medium"
              />
            </FormControl>

            <FormControl>
              <CKEditor
                data={lesson.detail}
                editor={Editor}
                onChange={handleEditorChange}
                config={editorConfig}
              />
            </FormControl>

            <FormControl>
              <TextField
                fullWidth
                type="text"
                label="ลิงค์วิดีโอ"
                placeholder="กรอกลิงค์วิดีโอ"
                {...register("clip_url", { required: true })}
                size="medium"
              />
            </FormControl>

            <FormControl>
              <TextField
                fullWidth
                type="number"
                label="ลำดับบทเรียน"
                placeholder="กรอกลำดับบทเรียน"
                {...register("lesson_order", { required: true, min: 0 })}
                size="medium"
              />
            </FormControl>

            <Box display="flex" gap="16px">
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type="number"
                  label="เวลาเรียนรู้ (นาที)"
                  placeholder="กรอกเวลาเรียนรู้ (นาที)"
                  {...register("minutes", { required: true, min: 0 })}
                  size="medium"
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  type="number"
                  label="เวลาเรียนรู้ (วินาที)"
                  placeholder="กรอกเวลาเรียนรู้ (วินาที)"
                  {...register("second", { required: true, min: 0 })}
                  size="medium"
                />
              </FormControl>
            </Box>

            <Box display="flex" gap="16px">
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type="number"
                  label="เวลาเรียนรู้ขั้นต่ำ (นาที)"
                  placeholder="กรอกเวลาเรียนรู้ขั้นต่ำ (นาที)"
                  {...register("minutes_minimum", { required: true, min: 0 })}
                  size="medium"
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  type="number"
                  label="เวลาเรียนรู้ขั้นต่ำ (วินาที)"
                  placeholder="กรอกเวลาเรียนรู้ขั้นต่ำ (วินาที)"
                  {...register("second_minimum", { required: true, min: 0 })}
                  size="medium"
                />
              </FormControl>
            </Box>

            <FormControl>
              <TextField
                fullWidth
                type="number"
                label="เกณฑ์การผ่านบทเรียน (ข้อ)"
                placeholder="กรอกเกณฑ์การผ่านบทเรียน (ข้อ)"
                {...register("passed_criteria", { required: true, min: 0 })}
                size="medium"
              />
            </FormControl>

            <FormControl>
              <TextField
                fullWidth
                type="number"
                label="ระยะห่าง การทำแบบทดสอบ (ช.ม.)"
                placeholder="ระยะห่าง การทำแบบทดสอบ (ช.ม.)"
                {...register("gap_time", { required: true, min: 0 })}
                size="medium"
              />
            </FormControl>

            <Box display="flex" justifyContent="center" gap="16px">
              <Box>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon sx={{ color: "white" }} />}
                  color="green"
                  size="large"
                  type="submit"
                  disabled={!isValid || loading}
                >
                  <Typography color="white">บันทึก</Typography>
                </Button>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  startIcon={<CancelIcon sx={{ color: "white" }} />}
                  color="red"
                  size="large"
                  type="button"
                  onClick={navigatePreviousRoute}
                >
                  <Typography color="white">ยกเลิก</Typography>
                </Button>
              </Box>
            </Box>

            <Typography typography="h3" color="cGrey.main">
              แบบทดสอบ
            </Typography>

            <Box display="flex" flexDirection="column" gap="16px">
              <Box display="flex" gap="8px">
                <Box>
                  <Button
                    variant="contained"
                    startIcon={<SaveIcon sx={{ color: "white" }} />}
                    color="green"
                    component={RouterLink}
                    to={`/create-lesson-quiz/${courseId}/${lessonId}`}
                  >
                    <Typography color="white">สร้าง</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>

            <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", borderRadius: 0 }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>ลำดับ</StyledTableCell>
                    <StyledTableCell>คำถาม</StyledTableCell>
                    <StyledTableCell>วันที่สร้าง</StyledTableCell>
                    <StyledTableCell>วันที่แก้ไข</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lessonQuiz.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        {row.quiz_order}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.question}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.created_date
                          ? dayjs(row.created_date)
                              .locale("th")
                              .format("DD MMM BBBB")
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.last_updated_date
                          ? dayjs(row.last_updated_date)
                              .locale("th")
                              .format("DD MMM BBBB")
                          : "-"}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box display="flex" gap="8px">
                          <Box
                            component="a"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleNavigateEditLessonQuiz(row.id)}
                          >
                            <ModeEditIcon />
                          </Box>
                          <Box
                            component="a"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleWarningOpen(row.id)}
                          >
                            <DeleteIcon />
                          </Box>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box
            sx={{ ...boxStyle, height: "fit-content" }}
            width={{ xs: 1, md: "40%" }}
          >
            <Typography color="cGrey.main">อัพโหลดไฟล์</Typography>

            <Box>
              <Button
                variant="contained"
                color="green"
                onClick={handleUploadClick}
              >
                <Box
                  component="input"
                  hidden
                  accept="application/pdf"
                  type="file"
                  ref={inputRef}
                  onChange={handleFileChange}
                />
                <Typography color="white">UPLOAD FILE</Typography>
              </Button>
            </Box>

            {lessonFile && (
              <Typography color="cGrey.main">{lessonFile.name}</Typography>
            )}

            {!lessonFile && (
              <Box
                display="flex"
                gap="4px"
                component={RouterLink}
                to={lesson.lesson_url}
                target="_blank"
              >
                <PictureAsPdfIcon />
                <Typography color="cGrey.main">
                  {getFileNameFromUrl(lesson.lesson_url)}
                </Typography>
              </Box>
            )}
          </Box>

          {/* modals */}
          <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
            <Box sx={modalStyle} width={340}>
              <DeleteModal
                handleConfirmDelete={handleConfirmDelete}
                handleWarningClose={handleWarningClose}
              />
            </Box>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default EditLesson;
