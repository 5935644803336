import {
  Box,
  TextField,
  Button,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Grid,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Modal,
  Menu,
  CircularProgress,
  ListItemIcon,
} from "@mui/material";
import { useEffect, useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import SearchIcon from "@mui/icons-material/Search";
import { useForm } from "react-hook-form";

import { boxStyle, configToast, modalStyle } from "../../../constants";
import {
  deleteMemberService,
  getMembersService,
} from "../../../services/member.service";
import { useNavigate } from "react-router-dom";
import { StyledTableCell, StyledTableRow } from "../../../shared/style";
import { getProvinceService } from "../../../services/masterData.service";
import { useAppDispatch, useAppSelector } from "../../../state/hook";
import { setApp } from "../../../state/libs/appSlice";
import { getItem } from "../../../services/localStorage.service";
import DeleteModal from "../../../components/modals/DeleteModal";
import { toast } from "react-toastify";
import { getTask, setTask } from "../../../state/libs/taskSlice";

const contentToolbar = {
  toolbar: {
    title: "จัดการกลุ่มผู้ใช้งาน",
    subtitles: ["ผู้ใช้งานหน้าบ้าน"],
  },
};

const MemberList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const taskState = useAppSelector(getTask);

  const accountProfile = getItem("account_profile");

  const [members, setMembers] = useState<any[]>([]);
  const [provinces, setProvinces] = useState([]);
  const [provincesFittered, setProvincesFittered] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [memberId, setMemberId] = useState<number | null>(null);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { register, getValues, setValue } = useForm({
    defaultValues: {
      identification_number: "",
      member_id: "",
      phone_no: "",
      username: "",
      province_id: "",
      zone: accountProfile.zone,
      activity_type_id: "",
    },
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigateEditMember = (memberId: string) => {
    navigate(`/edit-member/${memberId}`);
  };

  const handleWarningOpen = (id: number) => {
    setMemberId(id);
    setWarningOpen(true);
  };
  const handleWarningClose = () => {
    setMemberId(null);
    setWarningOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const id = memberId as number;
      handleWarningClose();
      await deleteMemberService(id);
      getMembers();
      toast.success("ลบผู้ใช้งานสำเร็จ", configToast);
    } catch (error) {
      toast.error("ลบผู้ใช้งานไม่สำเร็จ", configToast);
    }
  };

  const handleDownloadReportMemberInfo = () => {
    const isEmpty = Object.values(getValues()).every(
      (x) => x === null || x === ""
    );
    if (isEmpty) {
      toast.error("กรุณาใส่คำค้นหา", configToast);
      return;
    }
    dispatch(
      setTask({
        id: "memberInfoReport",
        reportName: "memberInfoReport",
        process: true,
        processing: false,
        filters: getValues(),
      })
    );
  };

  const handleDownloadReportBmi = () => {
    const isEmpty = Object.values(getValues()).every(
      (x) => x === null || x === ""
    );
    if (isEmpty) {
      toast.error("กรุณาใส่คำค้นหา", configToast);
      return;
    }

    dispatch(
      setTask({
        id: "bmiReport",
        reportName: "bmiReport",
        process: true,
        processing: false,
        filters: getValues(),
      })
    );
  };

  const handleDownloadReportActivity = () => {
    const isEmpty = Object.values(getValues()).every(
      (x) => x === null || x === ""
    );
    if (isEmpty) {
      toast.error("กรุณาใส่คำค้นหา", configToast);
      return;
    }
    dispatch(
      setTask({
        id: "activityReport",
        reportName: "activityReport",
        process: true,
        processing: false,
        filters: getValues(),
      })
    );
  };

  const getMembers = () => {
    const isEmpty = Object.values(getValues()).every(
      (x) => x === null || x === ""
    );
    if (isEmpty) {
      toast.error("กรุณาใส่คำค้นหา", configToast);
      return;
    }
    setLoading(true);
    getMembersService({
      size: rowsPerPage,
      page,
      ...getValues(),
    })
      .then((resp: any) => {
        setLoading(false);
        setMembers(resp.data.items);
        setCount(resp.data.total_row);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message, configToast);
      });
  };

  const getProvinces = () => {
    getProvinceService().then((resp) => {
      setProvinces(resp.data);
      setProvincesFittered(
        !accountProfile.zone
          ? resp.data
          : resp.data.filter((t: any) => t.zone === accountProfile.zone)
      );
    });
  };

  const handleOnSearch = () => {
    getMembers();
  };

  const handleZoneChange = (event: SelectChangeEvent) => {
    setProvincesFittered(
      !event.target.value
        ? provinces
        : provinces.filter((t: any) => t.zone === parseInt(event.target.value))
    );
    setValue("province_id", "");
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(getProvinces, [accountProfile.zone]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box sx={boxStyle}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          gap="16px"
        >
          <Box display="flex" flexDirection="column" gap={4} width={1}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="ค้นหาเบอร์โทรศัพท์"
                  {...register("phone_no", {
                    required: true,
                    minLength: 10,
                    maxLength: 10,
                  })}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="ค้นหาเลขบัตรประชาชน"
                  {...register("identification_number", {
                    required: true,
                    minLength: 13,
                    maxLength: 13,
                  })}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="ค้นหา EBIB CODE"
                  {...register("member_id", {
                    required: true,
                  })}
                />
              </Grid>
              {/* 
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="activity-type">ประเภทกีฬา</InputLabel>
                  <Select
                    labelId="activity-type"
                    id="activity_type"
                    label="ประเภทกีฬา"
                    {...register("activity_type_id")}
                  >
                    {activities.map((row: any, index: any) => (
                      <MenuItem key={index} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="ค้นหาผู้ใช้"
                  {...register("username", {
                    required: true,
                  })}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Select
                    defaultValue={accountProfile.zone}
                    disabled={accountProfile.zone}
                    labelId="zone-label"
                    {...register("zone")}
                    sx={{
                      "& .MuiSelect-select .notranslate::after":
                        "ค้นหาเขตสุขภาพ"
                          ? {
                              content: `"ค้นหาเขตสุขภาพ"`,
                              opacity: 0.42,
                            }
                          : {},
                    }}
                    onChange={handleZoneChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {Array.from(Array(13).keys()).map((index: any) => (
                      <MenuItem key={index} value={(index + 1).toString()}>
                        {"เขตสุขภาพที่ " + (index + 1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Select
                    labelId="province-label"
                    {...register("province_id")}
                    sx={{
                      "& .MuiSelect-select .notranslate::after": "ค้นหาจังหวัด"
                        ? {
                            content: `"ค้นหาจังหวัด"`,
                            opacity: 0.42,
                          }
                        : {},
                    }}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {provincesFittered.map(
                      (p: { id: number; name: string }) => (
                        <MenuItem key={p.id} value={p.id.toString()}>
                          {p.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={4}></Grid>
          </Box>

          <Box display="flex" gap="8px" height="fit-content">
            <Box sx={{ position: "relative" }}>
              <Button
                variant="contained"
                startIcon={<SearchIcon sx={{ color: "white" }} />}
                color="green"
                disabled={loading}
                onClick={handleOnSearch}
              >
                <Typography color="white">ค้นหา</Typography>
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>

            <Box sx={{ position: "relative" }}>
              <Button variant="contained" color="blue" onClick={handleClick}>
                <Typography color="white">EXPORT</Typography>
              </Button>

              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                  onClick={handleDownloadReportMemberInfo}
                  disabled={taskState.tasks.find(
                    (task: any) => task.id === "memberInfoReport"
                  )}
                >
                  {taskState.tasks.find(
                    (task: any) => task.id === "memberInfoReport"
                  ) && (
                    <ListItemIcon>
                      <CircularProgress color="blue" size={20} />
                    </ListItemIcon>
                  )}
                  ข้อมูลทั่วไป
                </MenuItem>
                <MenuItem
                  onClick={handleDownloadReportActivity}
                  disabled={taskState.tasks.find(
                    (task: any) => task.id === "activityReport"
                  )}
                >
                  {taskState.tasks.find(
                    (task: any) => task.id === "activityReport"
                  ) && (
                    <ListItemIcon>
                      <CircularProgress color="blue" size={20} />
                    </ListItemIcon>
                  )}
                  ข้อมูลออกกำลังกาย
                </MenuItem>

                <MenuItem
                  onClick={handleDownloadReportBmi}
                  disabled={taskState.tasks.find(
                    (task: any) => task.id === "bmiReport"
                  )}
                >
                  {taskState.tasks.find(
                    (task: any) => task.id === "bmiReport"
                  ) && (
                    <ListItemIcon>
                      <CircularProgress color="blue" size={20} />
                    </ListItemIcon>
                  )}
                  ข้อมูล BMI
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={boxStyle}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ลำดับ</StyledTableCell>
                <StyledTableCell>EBIB CODE</StyledTableCell>
                <StyledTableCell>Username</StyledTableCell>
                <StyledTableCell>ชื่อ</StyledTableCell>
                <StyledTableCell>นามสกุล</StyledTableCell>
                <StyledTableCell>เบอร์โทรศัพท์</StyledTableCell>
                <StyledTableCell>จังหวัด</StyledTableCell>
                <StyledTableCell>อำเภอ</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell>{row.member_id}</StyledTableCell>
                  <StyledTableCell>{row.username}</StyledTableCell>
                  <StyledTableCell>{row.first_name}</StyledTableCell>
                  <StyledTableCell>{row.last_name}</StyledTableCell>
                  <StyledTableCell>{row.phone_no}</StyledTableCell>
                  <StyledTableCell>{row.current_province}</StyledTableCell>
                  <StyledTableCell>{row.current_district}</StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex" gap="8px">
                      <Box
                        component="a"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleNavigateEditMember(row.member_id)}
                      >
                        <ModeEditIcon />
                      </Box>
                      <Box
                        component="a"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleWarningOpen(row.member_id)}
                      >
                        <DeleteIcon />
                      </Box>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              {!members.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={9}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {/* modals */}
        <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
          <Box sx={modalStyle} width={340}>
            {memberId && (
              <DeleteModal
                handleConfirmDelete={handleConfirmDelete}
                handleWarningClose={handleWarningClose}
                confirm={true}
              />
            )}
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default MemberList;
