import axiosService from "./axios.service";

interface Filters {
  page: any;
  size: any;
}

export interface Banner {
  banner_url?: string;
  redirect_url?: string;
  is_publish?: boolean;
}

export const getBannersService = (params: Filters) => {
  return axiosService.get("/banner?" + new URLSearchParams({ ...params }));
};

export const getBannerService = (id: any) => {
  return axiosService.get(`/banner/${id}`);
};

export const postBannerService = (body: Banner) => {
  return axiosService.post("/banner", body);
};

export const patchBannerService = (id: any, body: Banner) => {
  return axiosService.patch(`/banner/${id}`, body);
};

export const deleteBannerService = (id: any) => {
  return axiosService.delete(`/banner/${id}`);
};

export const postBannerUploadService = (bannerId: any, formData: FormData) => {
  return axiosService.post(`/banner/banner-upload/${bannerId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
