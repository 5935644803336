import axiosService from "./axios.service";

interface Filters {
  page?: any;
  size?: any;
  zone?: any;
  province_id?: any;
  district_id?: any;
  subdistrict_id?: any;
  health_place_name?: any;
  health_place_type?: any;
}

export const getHealthPlacesService = (params: Filters) => {
  return axiosService.get("/hp?" + new URLSearchParams({ ...params }));
};

export const getHealthPlaceByIdService = (id: any) => {
  return axiosService.get(`/hp/${id}`);
};

export const postHealthPlaceService = (body: any) => {
  return axiosService.post("/hp", body);
};

export const patchHealthPlaceService = (id: any, body: any) => {
  return axiosService.patch(`/hp/${id}`, body);
};

export const deleteHealthPlaceByIdService = (id: any) => {
  return axiosService.delete(`/hp/${id}`);
};

export const postHealthPlaceImageUpload = (id: any, formData: FormData) => {
  return axiosService.post(`/hp/hp-upload/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getHealthPlaceReportService = (params: Filters) => {
  return axiosService.get(
    "/hp/report/job?" + new URLSearchParams({ ...params })
  );
};
