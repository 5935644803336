import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import th from "dayjs/locale/th";
import dayjs from "dayjs";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import * as xlsx from "xlsx";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-classic";

import {
  boxStyle,
  codeTypes,
  configToast,
  statusTypes,
} from "../../../constants";
import { OverwriteAdapterDayjs } from "../../../utils/common.util";
import {
  patchRewardService,
  postRewardUploadService,
  postRewardImportCodesService,
  getRewardService,
} from "../../../services/reward.service";
import CodeUploadModal from "../../../components/modals/CodeUploadModal";
import { useAppDispatch } from "../../../state/hook";
import { setApp } from "../../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "แลกของรางวัล",
    subtitles: ["จัดการแลกของรางวัล", "แก้ไขของรางวัล"],
  },
};

const EditReward = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [reward, setReward] = useState<any>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [rewardFile, setRewardFile] = useState<File | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [uploadCodes, setUploadCodes] = useState<any[]>([]);
  const [codes, setCodes] = useState<any[]>([]);
  const [fileDataURL, setFileDataURL] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | any>(null);

  const inputUploadCopdesRef = useRef<HTMLInputElement | any>(null);
  const [openPreViewCodes, setOpenPreViewCodes] = useState<boolean>(false);

  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const handleUploadCodesClick = () => {
    if (inputUploadCopdesRef.current) inputUploadCopdesRef.current.click();
  };

  const handlePreViewCodesOpen = () => setOpenPreViewCodes(true);
  const handlePreViewCodesClose = () => setOpenPreViewCodes(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    Resizer.imageFileResizer(
      file,
      500,
      500,
      "PNG",
      100,
      0,
      (file: any) => {
        setRewardFile(file);
      },
      "file"
    );

    inputRef.current.value = "";
  };

  const handleNavigateRewardList = () => {
    navigate("/reward-list");
  };

  const handleFileReader = () => {
    let fileReader: any,
      isCancel = false;
    if (rewardFile) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(rewardFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  };

  const handleCodeTypeChange = () => {
    setValue("quantity", 0);
    setValue("code", "");
    setUploadCodes([]);
  };

  const readUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const results: any[] = [];
        for (const property in worksheet) {
          if (worksheet[property].v) {
            const isExist = results.find(
              (r) => r.code === worksheet[property].v
            );
            results.push({
              code: worksheet[property].v,
              isExist: isExist ? true : false,
            });
          }
        }
        setCodes(results);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
    inputUploadCopdesRef.current.value = "";
  };

  const acceptCodesUpload = () => {
    setValue("quantity", codes.length as any);
    setUploadCodes(codes.map((c) => c.code));
    handlePreViewCodesClose();
  };

  const { register, handleSubmit, formState, watch, setValue, trigger } =
    useForm({ mode: "all" });
  const { isValid, errors } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);
  const watchCodeType = watch("code_type");

  const getReward = () => {
    getRewardService(id).then((resp) => {
      setReward(resp.data);
    });
  };

  const setForm = () => {
    if (reward) {
      setValue("title", reward.title);
      setValue("is_publish", reward.is_publish ? 0 : 1);
      setValue("description", reward.description);
      setValue("used_point", reward.used_point);
      setValue("quantity", reward.quantity);
      setValue("code", reward.code);
      setValue("code_type", reward.code_type);
      setValue("detail", reward.detail);
      setValue("sponsor", reward.sponsor);
      setValue("reward_code", reward.reward_code);

      const startData = dayjs(reward.start_date).format("YYYY-MM-DD");
      const endData = dayjs(reward.end_date).format("YYYY-MM-DD");
      setValue("start_date", startData);
      setValue("end_date", endData);
      setStartDate(startData);
      setEndDate(endData);
      setFileDataURL(reward.image_url);

      trigger();
    }
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      setSaving(true);
      await patchRewardService(id, {
        ...values,
        is_publish: !values.is_publish ? true : false,
        start_date: startDate,
        publish_date: startDate,
        end_date: endDate,
        quantity: parseInt(values.quantity),
      });

      if (rewardFile) {
        const formdata = new FormData();
        formdata.append("file", rewardFile as File);
        postRewardUploadService(id, formdata);
      }

      if (uploadCodes.length)
        await postRewardImportCodesService(id, uploadCodes);

      toast.success("แก้ไขของรางวัลสำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateRewardList(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(
        resMessage.toString() || "แก้ไขของรางวัลไม่สำเร็จ",
        configToast
      );
      setSaving(false);
    }
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(setForm, [reward, setValue, trigger]);

  useEffect(getReward, [id]);

  useEffect(handleFileReader, [rewardFile]);

  useEffect(() => {
    if (codes.length) handlePreViewCodesOpen();
  }, [codes]);

  useEffect(() => {
    register("detail", { required: true });
  }, [register]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      display="flex"
      flexDirection={{ xs: "column-reverse", md: "row" }}
      gap="16px"
    >
      {reward && (
        <>
          <Box
            sx={{ ...boxStyle, height: "fit-content" }}
            width={{ xs: 1, md: "60%" }}
          >
            <FormControl>
              <TextField
                fullWidth
                type="text"
                label="รายชื่อของรางวัล"
                placeholder="กรอกรายชื่อของรางวัล"
                {...register("title", { required: true })}
                size="medium"
              />
            </FormControl>

            <FormControl>
              <CKEditor
                data={reward.detail}
                editor={Editor}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData();
                  setValue("detail", data);
                }}
              />
            </FormControl>

            <FormControl>
              <TextField
                fullWidth
                type="text"
                label="point"
                placeholder="กรอก point"
                multiline
                {...register("used_point", { required: true })}
                size="medium"
                onInput={(e: any) => {
                  e.target.value = e.target.value.toString().replace(/\D/g, "");
                }}
              />
            </FormControl>

            <FormControl>
              <InputLabel id="status-label">สถานะ</InputLabel>
              <Select
                defaultValue={reward.is_publish ? 0 : 1}
                labelId="status-label"
                id="status-label"
                placeholder="เลือกสถานะ"
                label="สถานะ"
                {...register("is_publish", { required: true })}
                size="medium"
              >
                {statusTypes.map((status: string, index: number) => (
                  <MenuItem value={index}>{status}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="code-type-label">ประเภทของรางวัล</InputLabel>
              <Select
                defaultValue={reward.code_type}
                labelId="code-type-label"
                id="code-type-label"
                placeholder="เลือกประเภทของรางวัล"
                label="ประเภทของรางวัล"
                {...register("code_type", {
                  required: true,
                  onChange: handleCodeTypeChange,
                })}
                size="medium"
              >
                {codeTypes.map((codeType: string, index: number) => (
                  <MenuItem value={index + 1}>{codeType}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <TextField
                fullWidth
                type="text"
                label="จำนวนของรางวัล"
                placeholder="กรอกจำนวนของรางวัล"
                multiline
                {...register("quantity", { required: true })}
                size="medium"
                onInput={(e: any) => {
                  e.target.value = e.target.value.toString().replace(/\D/g, "");
                }}
              />
            </FormControl>

            <FormControl>
              <TextField
                fullWidth
                type="text"
                label="ผู้สนับสนุน"
                placeholder="กรอกผู้สนับสนุน"
                multiline
                {...register("sponsor", {
                  required: true,
                  maxLength: {
                    value: 200,
                    message: "รหัสของรางวัลต้องไม่เกิน 200 ตัวอักษร",
                  },
                })}
                helperText={
                  errors["sponsor"] ? (errors["sponsor"].message as string) : ""
                }
                error={!!errors["sponsor"]}
                size="medium"
              />
            </FormControl>
            <FormControl>
              <TextField
                fullWidth
                type="text"
                label="รหัสของรางวัล"
                placeholder="กรอกรหัสของรางวัล"
                {...register("reward_code", {
                  required: true,
                  maxLength: {
                    value: 6,
                    message: "รหัสของรางวัลต้องไม่เกิน 6 ตัวอักษร",
                  },
                })}
                helperText={
                  errors["reward_code"]
                    ? (errors["reward_code"].message as string)
                    : ""
                }
                error={!!errors["reward_code"]}
                size="medium"
                onInput={(e: any) => {
                  console.log(errors);
                  e.target.value = e.target.value
                    .toString()
                    .replace(/[^A-Za-z0-9-_]/gi, "");
                }}
              />
            </FormControl>

            {watchCodeType === 1 && (
              <>
                <FormControl>
                  <TextField
                    fullWidth
                    type="text"
                    label="Barcode"
                    placeholder="กรอก Barcode"
                    {...register("code", {
                      required: true,
                      maxLength: {
                        value: 16,
                        message: "รหัสของรางวัลต้องไม่เกิน 16 ตัวอักษร",
                      },
                    })}
                    helperText={
                      errors["code"] ? (errors["code"].message as string) : ""
                    }
                    error={!!errors["code"]}
                    size="medium"
                  />
                </FormControl>
              </>
            )}

            {watchCodeType === 2 && (
              <>
                <Box display="flex" flexDirection="column" gap="8px">
                  <Typography color="cGrey.main">อัพโหลด file</Typography>

                  <Box>
                    <Button
                      variant="contained"
                      color="green"
                      onClick={handleUploadCodesClick}
                    >
                      <Typography color="white">UPLOAD FILE </Typography>
                    </Button>
                    <input
                      hidden
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      type="file"
                      ref={inputUploadCopdesRef}
                      onChange={readUploadFile}
                    />
                  </Box>

                  <Typography color="red.main" variant="body2">
                    *กรุณาอัพโหลดไฟล์เท่ากับจำนวนโค้ดรางวัล
                  </Typography>
                </Box>
              </>
            )}

            <Box display="flex" justifyContent="center" gap="16px">
              <Box>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon sx={{ color: "white" }} />}
                  color="green"
                  size="large"
                  type="submit"
                  disabled={!isValid || saving}
                >
                  <Typography color="white">บันทึก</Typography>
                </Button>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  startIcon={<CancelIcon sx={{ color: "white" }} />}
                  color="red"
                  size="large"
                  type="button"
                  component={RouterLink}
                  to="/reward-list"
                >
                  <Typography color="white">ยกเลิก</Typography>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{ ...boxStyle, height: "fit-content" }}
            width={{ xs: 1, md: "40%" }}
          >
            <Typography color="cGrey.main">อัพโหลดรูปภาพ</Typography>
            <Box>
              <Button
                variant="contained"
                color="green"
                onClick={handleUploadClick}
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  ref={inputRef}
                  onChange={handleFileChange}
                />
                <Typography color="white">UPLOAD FILE</Typography>
              </Button>
            </Box>

            <Box
              component="img"
              src={fileDataURL}
              sx={{
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                objectFit: "contain",
                width: 1,
              }}
            />

            <FormControl>
              <LocalizationProvider
                dateAdapter={OverwriteAdapterDayjs}
                adapterLocale={th}
              >
                <DatePicker
                  label="วันเริ่มต้น เผยแพร่"
                  views={["year", "month", "day"]}
                  inputFormat="DD/MMM/BBBB"
                  value={startDate}
                  onChange={async (newValue: any) => {
                    const date = dayjs(newValue).format("YYYY-MM-DD");
                    setStartDate(date);
                    setValue("start_date", date);
                    await trigger("start_date", { shouldFocus: true });
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "วว/ดด/ปปปป",
                        readOnly: true,
                      }}
                      {...register("start_date", { required: true })}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl>
              <LocalizationProvider
                dateAdapter={OverwriteAdapterDayjs}
                adapterLocale={th}
              >
                <DatePicker
                  label="วันสิ้นสุด เผยแพร่"
                  views={["year", "month", "day"]}
                  inputFormat="DD/MMM/BBBB"
                  value={endDate}
                  onChange={async (newValue: any) => {
                    const date = dayjs(newValue).format("YYYY-MM-DD");
                    setEndDate(date);
                    setValue("end_date", date);
                    await trigger("end_date", { shouldFocus: true });
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "วว/ดด/ปปปป",
                        readOnly: true,
                      }}
                      {...register("end_date", { required: true })}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
        </>
      )}

      <CodeUploadModal
        codes={codes}
        openPreViewCodes={openPreViewCodes}
        acceptCodesUpload={acceptCodesUpload}
        handlePreViewCodesClose={handlePreViewCodesClose}
      />
    </Box>
  );
};

export default EditReward;
