import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import dayjs from "dayjs";

import { boxStyle, codeTypes } from "../../../constants";
import { getRewardsService } from "../../../services/reward.service";
import { StyledTableCell, StyledTableRow } from "../../../shared/style";
import { useAppDispatch, useAppSelector } from "../../../state/hook";
import { setApp } from "../../../state/libs/appSlice";
import { getTask, setTask } from "../../../state/libs/taskSlice";

const contentToolbar = {
  toolbar: {
    title: "แลกของรางวัล",
    subtitles: ["จัดการแลกของรางวัล"],
  },
};

const RewardList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const taskState = useAppSelector(getTask);

  const [rewards, setRewards] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleNavigateEditReward = (rewardId: string) => {
    navigate(`/edit-reward/${rewardId}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadReportReport = (row: any) => {
    dispatch(
      setTask({
        id: "rewardReport" + row.id,
        reportName: "rewardReport",
        process: true,
        processing: false,
        extraFileName: row.title,
      })
    );
  };

  const getRewards = () => {
    getRewardsService({ size: rowsPerPage, page: page + 1 }).then((resp) => {
      setRewards(resp.data.items);
      setCount(resp.data.total_row);
    });
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(getRewards, [page, rowsPerPage]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box sx={boxStyle}>
      <Box>
        <Button
          color="green"
          variant="contained"
          startIcon={<SaveIcon sx={{ color: "white" }} />}
          component={RouterLink}
          to="/create-reward"
        >
          <Typography color="white">สร้าง</Typography>
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", borderRadius: 0 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ลำดับ</StyledTableCell>
              <StyledTableCell>ชื่อรายการสินค้า</StyledTableCell>
              <StyledTableCell>รหัสของรางวัล</StyledTableCell>
              <StyledTableCell>โค้ด</StyledTableCell>
              <StyledTableCell>ประเภทของรางวัล</StyledTableCell>
              <StyledTableCell>สถานะ</StyledTableCell>
              <StyledTableCell>วันเริ่มต้น</StyledTableCell>
              <StyledTableCell>วันสิ้นสุด</StyledTableCell>
              <StyledTableCell>จำนวนโค้ด</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rewards.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {row.id}
                </StyledTableCell>
                <StyledTableCell>{row.title}</StyledTableCell>
                <StyledTableCell>{row.reward_code || "-"}</StyledTableCell>
                <StyledTableCell>{row.code || "-"}</StyledTableCell>
                <StyledTableCell>
                  {codeTypes[row.code_type - 1]}
                </StyledTableCell>
                <StyledTableCell>
                  {row.is_publish ? "เผยแพร่" : "ไม่เผยแพร่"}
                </StyledTableCell>
                <StyledTableCell>
                  {row.start_date
                    ? dayjs(row.start_date).locale("th").format("DD MMM BBBB")
                    : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {row.end_date
                    ? dayjs(row.end_date).locale("th").format("DD MMM BBBB")
                    : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {row.quantity?.toLocaleString() || "-"}
                </StyledTableCell>
                <StyledTableCell>
                  <Box display="flex" gap="8px">
                    <IconButton
                      onClick={() => handleNavigateEditReward(row.id)}
                    >
                      <ModeEditIcon />
                    </IconButton>
                    <IconButton
                      disabled={taskState.tasks.find(
                        (task: any) => task.id === "rewardReport" + row.id
                      )}
                      onClick={() => handleDownloadReportReport(row)}
                    >
                      <FileDownloadIcon />
                    </IconButton>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {!rewards.length && (
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  colSpan={9}
                  align="center"
                >
                  ไม่พบข้อมูล
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default RewardList;
