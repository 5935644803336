import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { boxStyle, communityTypes } from "../../constants";
import { useAppDispatch } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";
import { getCommunitiesService } from "../../services/community.service";
import { StyledTableCell, StyledTableRow } from "../../shared/style";
import { getProvinceService } from "../../services/masterData.service";
import { getItem } from "../../services/localStorage.service";

const contentToolbar = {
  toolbar: {
    title: "จัดการกลุ่ม",
    subtitles: ["จัดการกลุ่ม"],
  },
};

const CommunityList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const accountProfile = getItem("account_profile");

  const [provinces, setProvinces] = useState<any[]>([]);
  const [provincesFittered, setProvincesFittered] = useState<any[]>([]);
  const [communityList, setCommunityList] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { register, getValues } = useForm({
    defaultValues: {
      zone: accountProfile.zone,
      province_id: "",
      type_id: "",
      community_code: "",
    },
  });

  const getProvinces = () => {
    getProvinceService().then((resp) => {
      setProvinces(resp.data);
      setProvincesFittered(
        !accountProfile.zone
          ? resp.data
          : resp.data.filter((t: any) => t.zone === accountProfile.zone)
      );
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleZoneChange = (event: SelectChangeEvent) => {
    setProvincesFittered(
      !event.target.value
        ? provinces
        : provinces.filter((t: any) => t.zone === parseInt(event.target.value))
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOnSearch = () => {
    getCommunities();
  };

  const handleNavigateEditCommunity = (id: any) => {
    navigate(`/edit-community/${id}`);
  };

  const getCommunities = () => {
    getCommunitiesService({
      size: rowsPerPage,
      page: page + 1,
      ...getValues(),
    }).then((resp) => {
      setCommunityList(resp.data.items);
      setCount(resp.data.total_row);
    });
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(getCommunities, [getValues, page, rowsPerPage]);

  useEffect(setContentToolbar, [dispatch]);

  useEffect(getProvinces, [accountProfile.zone]);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box sx={boxStyle}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          gap="16px"
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="zone-label">เขตสุขภาพ</InputLabel>
                <Select
                  defaultValue={accountProfile.zone}
                  disabled={accountProfile.zone}
                  labelId="zone-label"
                  id="province"
                  label="เขตสุขภาพ"
                  {...register("zone")}
                  onChange={handleZoneChange}
                >
                  <MenuItem key={0} value="">
                    ทั้งหมด
                  </MenuItem>
                  {Array.from(Array(13).keys()).map((index: any) => (
                    <MenuItem key={index} value={(index + 1).toString()}>
                      {"เขตสุขภาพที่ " + (index + 1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="province-label">จังหวัด</InputLabel>
                <Select
                  labelId="province-label"
                  id="province"
                  label="จังหวัด"
                  {...register("province_id")}
                >
                  <MenuItem key={0} value="">
                    ทั้งหมด
                  </MenuItem>
                  {provincesFittered.map((p: { id: number; name: string }) => (
                    <MenuItem key={p.id} value={p.id.toString()}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <Select
                fullWidth
                sx={{
                  "& .MuiSelect-select .notranslate::after":
                    "กรุณาเลือกประเภทกลุ่ม"
                      ? {
                          content: `"กรุณาเลือกประเภทกลุ่ม"`,
                          opacity: 0.42,
                        }
                      : {},
                }}
                {...register("type_id", { required: true })}
              >
                <MenuItem value={""}>ทั้งหมด</MenuItem>
                {communityTypes.map((str: string, index: number) => (
                  <MenuItem key={index} value={index + 1}>
                    {str}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                type="text"
                placeholder="ค้นหารหัสกลุ่ม"
                {...register("community_code")}
              />
            </Grid>
          </Grid>

          <Box display="flex" flexDirection="column" gap="8px">
            <Button
              variant="contained"
              startIcon={<SearchIcon sx={{ color: "white" }} />}
              color="green"
              onClick={handleOnSearch}
            >
              <Typography color="white">ค้นหา</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={boxStyle}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ลำดับ</StyledTableCell>
                <StyledTableCell>รหัสกลุ่ม</StyledTableCell>
                <StyledTableCell>ชื่อกลุ่ม</StyledTableCell>
                <StyledTableCell>จำนวนสมาชิก</StyledTableCell>
                <StyledTableCell>ประเภทกลุ่ม</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {communityList.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.community_code}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.community_name}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.member_count}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.type}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Box display="flex" gap="8px">
                      <Box
                        component="a"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleNavigateEditCommunity(row.id)}
                      >
                        <ModeEditIcon />
                      </Box>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              {!communityList.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={6}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default CommunityList;
