import {
  Box,
  Button,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-classic";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  boxStyle,
  configToast,
  modalStyle,
  statusTypes,
} from "../../../constants";
import {
  deleteLessonService,
  getCourseByIdService,
  getLessonService,
  patchCourseService,
  postCourseUploadService,
} from "../../../services/course.service";
import { getFileNameFromUrl } from "../../../utils/common.util";
import { toast } from "react-toastify";
import { StyledTableCell, StyledTableRow } from "../../../shared/style";
import dayjs from "dayjs";
import DeleteModal from "../../../components/modals/DeleteModal";
import { useAppDispatch } from "../../../state/hook";
import { setApp } from "../../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "การจัดการหลักสูตร",
    subtitles: ["รายการหลักสูตร", "แก้ไขหลักสูตร"],
  },
};

const EditCourse = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [course, setCourse] = useState<any>(null);
  const [lessons, setLessons] = useState<any[]>([]);
  const [courseFile, setCourseFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [lessonId, setLessonId] = useState<number | null>(null);

  const handleWarningOpen = (id: number) => {
    setLessonId(id);
    setWarningOpen(true);
  };
  const handleWarningClose = () => {
    setLessonId(null);
    setWarningOpen(false);
  };

  const inputRef = useRef<HTMLInputElement | any>(null);

  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setCourseFile(file);
    inputRef.current.value = "";
  };

  const getCourseById = () => {
    getCourseByIdService(id).then((resp) => {
      setCourse(resp.data.course);
    });
  };

  const getLesson = () => {
    getLessonService(id).then((resp) => {
      setLessons(resp.data);
    });
  };

  const { register, handleSubmit, formState, setValue, trigger } = useForm({
    mode: "all",
  });
  const { isValid } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const editorDescriptionConfig = { placeholder: "รายละเอียด" };
  const editorQuestionConfig = { placeholder: "คำถาม" };

  const handleEditorDescriptionChange = (event: any, editor: any) => {
    const data = editor.getData();
    setValue("description", data);
  };
  const handleEditorQuestionChange = (event: any, editor: any) => {
    const data = editor.getData();
    setValue("question", data);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const handleNavigateCourseList = () => {
    navigate("/course-list");
  };

  const handleConfirmDelete = async () => {
    try {
      const id = lessonId as number;
      handleWarningClose();
      await deleteLessonService(id);
      getLesson();
      toast.success("ลบบทเรียนสำเร็จ", configToast);
    } catch (error) {
      toast.error("ลบบทเรียนไม่สำเร็จ", configToast);
    }
  };

  const setForm = () => {
    if (course) {
      setValue("title", course.title);
      setValue("question", course.question);
      setValue("description", course.description);
      setValue("course_url", course.course_url);
      setValue("is_publish", course.is_publish ? 0 : 1);

      trigger();
    }
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      await patchCourseService(id, {
        ...values,
        is_publish: !values.is_publish ? true : false,
      });

      if (courseFile) {
        const formdata = new FormData();
        formdata.append("file", courseFile as File);
        postCourseUploadService(id, formdata);
      }

      toast.success("แก้ไขหลักสูตรสำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateCourseList(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "แก้ไขหลักสูตรไม่สำเร็จ", configToast);
      handleLoaded();
    }
  };

  const handleNavigateEditLesson = (lessonId: string) => {
    navigate(`/edit-lesson/${lessonId}`);
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(setForm, [course, setValue, trigger]);

  useEffect(getCourseById, [id]);

  useEffect(getLesson, [id]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      display="flex"
      flexDirection={{ xs: "column-reverse", md: "row" }}
      gap="16px"
    >
      {course && (
        <>
          <Box
            sx={{ ...boxStyle, height: "fit-content" }}
            width={{ xs: 1, md: "60%" }}
          >
            <FormControl>
              <TextField
                fullWidth
                type="text"
                label="ชื่อคอร์สเรียน"
                placeholder="กรอกชื่อคอร์สเรียน"
                {...register("title", { required: true })}
                size="medium"
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                <Typography
                  typography="subtitle2"
                  color="black.main"
                  fontWeight={600}
                >
                  รายละเอียด
                </Typography>
              </FormLabel>
              <CKEditor
                data={course.description}
                config={editorDescriptionConfig}
                editor={Editor}
                onChange={handleEditorDescriptionChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                <Typography
                  typography="subtitle2"
                  color="black.main"
                  fontWeight={600}
                >
                  คำถาม
                </Typography>
              </FormLabel>
              <CKEditor
                data={course.question}
                editor={Editor}
                onChange={handleEditorQuestionChange}
                config={editorQuestionConfig}
              />
            </FormControl>

            <FormControl>
              <InputLabel id="status-label">สถานะ</InputLabel>
              <Select
                defaultValue={course.is_publish ? 0 : 1}
                labelId="status-label"
                id="status-label"
                placeholder="เลือกสถานะ"
                label="สถานะ"
                {...register("is_publish", { required: true })}
                size="medium"
              >
                {statusTypes.map((status: string, index: number) => (
                  <MenuItem key={index} value={index}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box display="flex" justifyContent="center" gap="16px">
              <Box>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon sx={{ color: "white" }} />}
                  color="green"
                  size="large"
                  type="submit"
                  disabled={!isValid || loading}
                >
                  <Typography color="white">บันทึก</Typography>
                </Button>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  startIcon={<CancelIcon sx={{ color: "white" }} />}
                  color="red"
                  size="large"
                  type="button"
                  component={RouterLink}
                  to="/course-list"
                >
                  <Typography color="white">ยกเลิก</Typography>
                </Button>
              </Box>
            </Box>

            <Typography typography="h3" color="cGrey.main">
              บทเรียน
            </Typography>

            <Box display="flex" flexDirection="column" gap="16px">
              <Box display="flex" gap="8px">
                <Box>
                  <Button
                    variant="contained"
                    startIcon={<SaveIcon sx={{ color: "white" }} />}
                    color="green"
                    component={RouterLink}
                    to={`/create-lesson/${id}`}
                  >
                    <Typography color="white">สร้าง</Typography>
                  </Button>
                </Box>
              </Box>

              <TableContainer
                component={Paper}
                sx={{ boxShadow: "none", borderRadius: 0 }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ลำดับ</StyledTableCell>
                      <StyledTableCell>ชื่อบทเรียน</StyledTableCell>
                      <StyledTableCell>วันที่สร้าง</StyledTableCell>
                      <StyledTableCell>วันที่แก้ไข</StyledTableCell>
                      <StyledTableCell>Actions</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lessons.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {row.lesson_order}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.title}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.created_date
                            ? dayjs(row.created_date)
                                .locale("th")
                                .format("DD MMM BBBB")
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.last_updated_date
                            ? dayjs(row.last_updated_date)
                                .locale("th")
                                .format("DD MMM BBBB")
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Box display="flex" gap="8px">
                            <Box
                              component="a"
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleNavigateEditLesson(row.id)}
                            >
                              <ModeEditIcon />
                            </Box>
                            <Box
                              component="a"
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleWarningOpen(row.id)}
                            >
                              <DeleteIcon />
                            </Box>
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}

                    {!lessons.length && (
                      <StyledTableRow>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          colSpan={6}
                          align="center"
                        >
                          ไม่พบข้อมูล
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>

          <Box
            sx={{ ...boxStyle, height: "fit-content" }}
            width={{ xs: 1, md: "40%" }}
          >
            <Typography color="cGrey.main">อัพโหลดไฟล์</Typography>

            <Box>
              <Button
                variant="contained"
                color="green"
                onClick={handleUploadClick}
              >
                <Box
                  component="input"
                  hidden
                  accept="application/pdf"
                  type="file"
                  ref={inputRef}
                  onChange={handleFileChange}
                />
                <Typography color="white">UPLOAD FILE</Typography>
              </Button>
            </Box>

            {courseFile && (
              <Typography color="cGrey.main">{courseFile.name}</Typography>
            )}

            {!courseFile && (
              <Box
                display="flex"
                gap="4px"
                component={RouterLink}
                to={course.course_url}
                target="_blank"
              >
                <PictureAsPdfIcon />
                <Typography color="cGrey.main">
                  {getFileNameFromUrl(course.course_url)}
                </Typography>
              </Box>
            )}
          </Box>

          {/* modals */}
          <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
            <Box sx={modalStyle} width={340}>
              <DeleteModal
                handleConfirmDelete={handleConfirmDelete}
                handleWarningClose={handleWarningClose}
              />
            </Box>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default EditCourse;
