import axiosService from "./axios.service";

interface Filters {
  page: any;
  size: any;
}

export const getHealthLiteracyService = (params: Filters) => {
  return axiosService.get(
    "/health-literacy?" + new URLSearchParams({ ...params })
  );
};

export const getHealthLiteracyByIdService = (id: any) => {
  return axiosService.get(`/health-literacy/${id}`);
};

export const postHealthLiteracyService = (body: any) => {
  return axiosService.post("/health-literacy", body);
};

export const patchHealthLiteracyService = (id: any, body: any) => {
  return axiosService.patch(`/health-literacy/${id}`, body);
};

export const deleteHealthLiteracyService = (id: any) => {
  return axiosService.delete(`/health-literacy/${id}`);
};

export const postHealthLiteracyUploadService = (
  id: any,
  formData: FormData
) => {
  return axiosService.post(
    `/health-literacy/health-leteracy-upload/${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
