import {
  Box,
  Dialog,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  FormControl,
  InputLabel,
  Button,
  Typography,
  TablePagination,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
  getMemberEvaluationService,
  patchMemberEvaluationService,
} from "../../services/course.service";
import { boxStyle, configToast } from "../../constants";
import { StyledTableCell, StyledTableRow } from "../../shared/style";
import ViewEvaluation from "../../components/dialogs/ViewEvaluation";
import { useAppDispatch } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";

const status = ["รอตรวจประเมิน", "ยังไม่ผ่านการประเมิน", "ผ่านการประเมินแล้ว"];

const contentToolbar = {
  toolbar: {
    title: "การจัดการหลักสูตร",
    subtitles: ["รายการรอตรวจประเมิน"],
  },
};

const MemberEvaluationList = () => {
  const dispatch = useAppDispatch();

  const [memberEvaluationList, setMemberEvaluationList] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [evaluation, setEvaluation] = useState<any>(null);
  const [openEvaluation, setEvaluationOpen] = useState<any>(null);

  const handleEvaluationOpen = (id: any) => {
    setEvaluation(id);
    setEvaluationOpen(true);
  };
  const handleEvaluationClose = () => {
    setEvaluation(null);
    setEvaluationOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleOnSearch = () => {
    getMemberEvaluation();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProofAnswerSubmit = async (isPassed: boolean) => {
    try {
      await patchMemberEvaluationService(evaluation.id, {
        is_passed: isPassed,
        evaluated_date: new Date().toISOString(),
      });

      toast.success("ตรวจคำตอบสำเร็จ", {
        ...configToast,
      });
      handleEvaluationClose();
      getMemberEvaluation();
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "ตรวจคำตอบไม่สำเร็จ", configToast);
    }
  };

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      ebib: "",
      course_title: "",
      status: "",
      identification_number: "",
    },
  });

  const getMemberEvaluation = () => {
    getMemberEvaluationService({
      size: rowsPerPage,
      page: page + 1,
      ...getValues(),
    }).then((resp) => {
      setMemberEvaluationList(resp.data.items);
      setCount(resp.data.total_row);
    });
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(getMemberEvaluation, [getValues, page, rowsPerPage]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box sx={boxStyle}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{ xs: "column", md: "row" }}
          component="form"
          gap="48px"
          onSubmit={handleSubmit(handleOnSearch)}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="ebib"
                  {...register("ebib")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  placeholder="เลขบัตรประชาชน"
                  {...register("identification_number")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  placeholder="ชื่อหลักสูตร"
                  {...register("course_title")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="status-label">สถานะ</InputLabel>
                <Select
                  fullWidth
                  placeholder="เลือกสถานะ"
                  label="สถานะ"
                  {...register("status")}
                  size="medium"
                >
                  <MenuItem value={""}>ทั้งหมด</MenuItem>
                  {status.map((item, index) => (
                    <MenuItem value={index}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="end">
            <Button
              variant="contained"
              startIcon={<SearchIcon sx={{ color: "white" }} />}
              color="green"
              type="submit"
            >
              <Typography color="white">ค้นหา</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={boxStyle}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <StyledTableCell>ebib</StyledTableCell>
              <StyledTableCell>ชื่อหลักสูตร</StyledTableCell>
              <StyledTableCell>เลขบัตรประชาชน</StyledTableCell>
              <StyledTableCell>ผู้ทำแบบประเมิน</StyledTableCell>
              <StyledTableCell>วันที่ทำแบบประเมิน</StyledTableCell>
              <StyledTableCell>สถานะ</StyledTableCell>
              <StyledTableCell>ผู้ตรวจ</StyledTableCell>
              <StyledTableCell>วันที่ตรวจแบบประเมิน</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableHead>
            <TableBody>
              {memberEvaluationList.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.ebib}
                  </StyledTableCell>
                  <StyledTableCell>{row.title}</StyledTableCell>
                  <StyledTableCell>{row.identification_number}</StyledTableCell>
                  <StyledTableCell>{row.learner}</StyledTableCell>
                  <StyledTableCell>
                    {row.tested_date
                      ? dayjs(row.tested_date)
                          .locale("th")
                          .format("DD MMM BBBB")
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell>{status[row.status]}</StyledTableCell>
                  <StyledTableCell>{row.evaluated_by || "-"}</StyledTableCell>
                  <StyledTableCell>
                    {row.evaluated_date
                      ? dayjs(row.evaluated_date)
                          .locale("th")
                          .format("DD MMM BBBB")
                      : "-"}
                  </StyledTableCell>

                  <StyledTableCell>
                    {row.status === "0" ? (
                      <Box display="flex" gap="8px">
                        <Box
                          component="a"
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleEvaluationOpen(row)}
                        >
                          <PreviewOutlinedIcon />
                        </Box>
                      </Box>
                    ) : (
                      "-"
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              {!memberEvaluationList.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={8}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Dialog
        open={openEvaluation}
        onClose={handleEvaluationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {evaluation && (
          <ViewEvaluation
            evaluation={evaluation}
            handleSubmit={handleProofAnswerSubmit}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default MemberEvaluationList;
