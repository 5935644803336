import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuIcon from "@mui/icons-material/Menu";
import { getItem } from "../services/localStorage.service";
import { useAppSelector } from "../state/hook";
import { getApp } from "../state/libs/appSlice";
import SideBar from "../components/admin/SideBar";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 230;

const AdminLayout = (props: Props) => {
  const appstate = useAppSelector(getApp);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const navigate = useNavigate();
  const prevLocation = useLocation();

  useEffect(() => {
    const session = getItem("session");
    if (!session) navigate(`/login`);
  }, [navigate, prevLocation.pathname]);

  return (
    <Box display="flex">
      {/* top bar */}
      <AppBar
        component="nav"
        sx={{
          height: 60,
          flexDirection: "row",
          padding: "10px 16px",
          gap: "8px",
        }}
      >
        <Box
          component="img"
          src="/icons/main-logo.png"
          height={40}
          width={40}
        />

        <Typography alignSelf="center" typography="h4">
          KAOTAJAI
        </Typography>
      </AppBar>

      {/* sidebar */}
      <Box display="flex">
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            marginTop: "60px",
            background: "#FFFFFF",
            height: "90px",
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
          }}
        >
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <Box display="flex" flexDirection="column" paddingY="18px">
              <Typography variant="h3" color="black.main">
                {appstate?.toolbar?.title}
              </Typography>
              <Typography variant="body1" color="cGrey.main">
                {appstate?.toolbar?.subtitles.map((str: string, i: number) => (
                  <>
                    {str}
                    {i !== appstate.toolbar.subtitles.length - 1 && (
                      <ArrowForwardIosIcon
                        sx={{
                          fontSize: "12px",
                          marginX: "2px",
                        }}
                      />
                    )}
                  </>
                ))}
                {/* {`ก้าวท้าใจ > หน้าหลัก (รายงาน)`} */}
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>

        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                marginTop: "60px",
              },
            }}
          >
            <SideBar />
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                marginTop: "60px",
              },
            }}
            open
          >
            <SideBar />
          </Drawer>
        </Box>
      </Box>

      <Box
        component="main"
        sx={{
          p: "32px 24px",
          marginTop: "80px",
          width: 1,
          overflowX: "auto",
        }}
      >
        <Toolbar />

        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminLayout;
