import axiosService from "./axios.service";

interface Filters {
  page: any;
  size: any;
}

export const getQuestionairesService = (params: Filters) => {
  return axiosService.get(
    "/questionaire?" + new URLSearchParams({ ...params })
  );
};

export const getQuestionaireByIdService = (id: any) => {
  return axiosService.get(`/questionaire/${id}`);
};

export const postQuestionaireService = (body: any) => {
  return axiosService.post("/questionaire", body);
};

export const patchQuestionaireService = (id: any, body: any) => {
  return axiosService.patch(`/questionaire/${id}`, body);
};

export const deleteQuestionaireService = (id: any) => {
  return axiosService.delete(`/questionaire/${id}`);
};

export const getQuestionaireQuestionsService = (id: any) => {
  return axiosService.get(`/questionaire/${id}/question`);
};

export const postQuestionaireQuestionsService = (body: any) => {
  return axiosService.post("/questionaire/question", body);
};

export const patchQuestionaireQuestionsService = (id: any, body: any) => {
  return axiosService.patch(`/questionaire/question/${id}`, body);
};

export const deleteQuestionaireQuestionsService = (id: any) => {
  return axiosService.delete(`/questionaire/question/${id}`);
};

export const getQuestionaireReportService = (id: any) => {
  return axiosService.get(`/questionaire/${id}/report`, {
    responseType: "blob",
  });
};
