import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Modal,
  Dialog,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import th from "dayjs/locale/th";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-classic";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  boxStyle,
  configToast,
  modalStyle,
  statusTypes,
  questionTypes,
} from "../../constants";
import { OverwriteAdapterDayjs } from "../../utils/common.util";
import {
  deleteQuestionaireQuestionsService,
  getQuestionaireByIdService,
  getQuestionaireQuestionsService,
  patchQuestionaireService,
} from "../../services/questionaire.service";
import { StyledTableCell, StyledTableRow } from "../../shared/style";
import DeleteModal from "../../components/modals/DeleteModal";
import CreateQuestionDialog from "../../components/dialogs/questionaire/CreateQuestionDialog";
import EditQuestionDialog from "../../components/dialogs/questionaire/EditQuestionDialog";
import { useAppDispatch } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "จัดการแบบสอบถาม",
    subtitles: ["จัดการแบบสอบถาม", "แก้ไขแบบสอบถาม"],
  },
};

const EditQuestionaire = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [questionaire, setQuestionaire] = useState<any>(null);
  const [questions, setQuestions] = useState<any[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [publishDate, setPublishDate] = useState<string | null>(null);
  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [openCreateQuestion, setCreateQuestionOpen] = useState<boolean>(false);
  const [openEditQuestion, setEditQuestionOpen] = useState<boolean>(false);
  const [question, setQuestion] = useState<any>(null);
  const [questionId, setQuestionId] = useState<number | null>(null);

  const handleWarningOpen = (id: number) => {
    setQuestionId(id);
    setWarningOpen(true);
  };
  const handleWarningClose = () => {
    setQuestionId(null);
    setWarningOpen(false);
  };

  const handleCreateQuestionOpen = () => {
    setCreateQuestionOpen(true);
  };
  const handleCreateQuestionClose = (loading: any = false) => {
    if (loading) getQuestionaireQuestions();
    setCreateQuestionOpen(false);
  };

  const { register, handleSubmit, formState, setValue, trigger } = useForm({
    mode: "all",
  });

  const { isValid } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const handleNavigateQuestionaireList = () => {
    navigate("/questionaire-list");
  };

  const handleConfirmDelete = async () => {
    try {
      const id = questionId as number;
      handleWarningClose();
      await deleteQuestionaireQuestionsService(id);
      getQuestionaireQuestions();
      toast.success("ลบคำถามสำเร็จ", configToast);
    } catch (error) {
      toast.error("ลบคำถามไม่สำเร็จ", configToast);
    }
  };

  const handleEditQuestionOpen = (row: any) => {
    setQuestion(row);
    setEditQuestionOpen(true);
  };

  const handleEditQuestionClose = (loading: any = false) => {
    if (loading) getQuestionaireQuestions();
    setEditQuestionOpen(false);
  };

  const handleEditorHeaderChange = (event: any, editor: any) => {
    const data = editor.getData();
    setValue("header", data);
  };
  const handleEditorDetailnChange = (event: any, editor: any) => {
    const data = editor.getData();
    setValue("detail", data);
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      setSaving(true);

      await patchQuestionaireService(id, {
        ...values,
        is_publish: !values.is_publish ? true : false,
        publish_date: publishDate,
      });

      toast.success("แก้ไขแบบสอบถามสำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateQuestionaireList(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "แก้ไขแบบสอบถามไม่สำเร็จ", configToast);
      setSaving(false);
    }
  };

  const setForm = () => {
    if (questionaire) {
      setValue("title", questionaire.title);
      setValue("header", questionaire.header);
      setValue("detail", questionaire.detail);
      setValue("is_publish", questionaire.is_publish ? 0 : 1);

      const publishData = dayjs(questionaire.publish_date).format("YYYY-MM-DD");
      setValue("publish_date", publishData);
      setPublishDate(publishData);
      trigger();
    }
  };

  const getQuestionaire = () => {
    getQuestionaireByIdService(id).then((resp) => {
      setQuestionaire(resp.data);
    });
  };

  const getQuestionaireQuestions = () => {
    getQuestionaireQuestionsService(id).then((resp) => {
      setQuestions(
        resp.data.sort((a: any, b: any) => a.question_order - b.question_order)
      );
    });
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(setForm, [questionaire, setValue, trigger]);

  useEffect(getQuestionaire, [id]);

  useEffect(getQuestionaireQuestions, [id, setValue]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap="16px">
      {questionaire && (
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler, onError)}
          sx={{ ...boxStyle, height: "fit-content" }}
          width={{ xs: 1, md: "50%" }}
        >
          <FormControl>
            <TextField
              fullWidth
              type="text"
              label="ชื่อหัวข้อ"
              placeholder="กรอกชื่อหัวข้อ"
              {...register("title", { required: true })}
              size="medium"
            />
          </FormControl>

          <FormControl>
            <CKEditor
              data={questionaire.header}
              editor={Editor}
              config={{ placeholder: "ชื่อแบบสอบถาม" }}
              onChange={handleEditorHeaderChange}
            />
          </FormControl>

          <FormControl>
            <CKEditor
              data={questionaire.detail}
              editor={Editor}
              config={{ placeholder: "รายละเอียด" }}
              onChange={handleEditorDetailnChange}
            />
          </FormControl>

          <FormControl>
            <LocalizationProvider
              dateAdapter={OverwriteAdapterDayjs}
              adapterLocale={th}
            >
              <DatePicker
                label="วันเริ่มต้น เผยแพร่"
                views={["year", "month", "day"]}
                inputFormat="DD/MMM/BBBB"
                value={publishDate}
                onChange={async (newValue: any) => {
                  const date = dayjs(newValue).format("YYYY-MM-DD");
                  await trigger("publish_date", { shouldFocus: true });
                  setPublishDate(date);
                  setValue("publish_date", date);
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "วว/ดด/ปปปป",
                      readOnly: true,
                    }}
                    {...register("publish_date", { required: true })}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl>
            <InputLabel id="status-label">สถานะ</InputLabel>
            <Select
              defaultValue={questionaire.is_publish ? 0 : 1}
              labelId="status-label"
              id="status-label"
              placeholder="เลือกสถานะ"
              label="สถานะ"
              {...register("is_publish", { required: true })}
              size="medium"
            >
              {statusTypes.map((status: string, index: number) => (
                <MenuItem key={index} value={index}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box display="flex" justifyContent="center" gap="16px">
            <Box>
              <Button
                variant="contained"
                startIcon={<SaveIcon sx={{ color: "white" }} />}
                color="green"
                size="large"
                type="submit"
                disabled={!isValid || saving}
              >
                <Typography color="white">บันทึก</Typography>
              </Button>
            </Box>

            <Box>
              <Button
                variant="contained"
                startIcon={<CancelIcon sx={{ color: "white" }} />}
                color="red"
                size="large"
                type="button"
                component={RouterLink}
                to="/questionaire-list"
              >
                <Typography color="white">ยกเลิก</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <Box sx={{ ...boxStyle }} width={{ xs: 1, md: "50%" }}>
        <Box display="flex" gap="8px">
          <Box>
            <Button
              variant="contained"
              startIcon={<SaveIcon sx={{ color: "white" }} />}
              color="green"
              onClick={handleCreateQuestionOpen}
            >
              <Typography color="white">สร้าง</Typography>
            </Button>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ลำดับ</StyledTableCell>
                <StyledTableCell>คำถาม</StyledTableCell>
                <StyledTableCell>ประเภท</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.map((row, index) => (
                <StyledTableRow
                  key={index}
                  sx={{
                    backgroundColor: !row.question_type
                      ? "rgba(0, 0, 0, 0.04)"
                      : "white !important",
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.question_order}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Typography
                      dangerouslySetInnerHTML={{ __html: row.question }}
                    />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Typography>{questionTypes[row.question_type]}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex" gap="8px">
                      <Box
                        component="a"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleEditQuestionOpen(row)}
                      >
                        <ModeEditIcon />
                      </Box>
                      <Box
                        component="a"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleWarningOpen(row.id)}
                      >
                        <DeleteIcon />
                      </Box>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!questions.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={4}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* daialog */}
      <Dialog open={openCreateQuestion} fullWidth>
        {openCreateQuestion && (
          <CreateQuestionDialog
            handleClose={handleCreateQuestionClose}
            questionaireId={id}
            order={questions.length}
          />
        )}
      </Dialog>

      <Dialog open={openEditQuestion} fullWidth>
        {question && (
          <EditQuestionDialog
            handleClose={handleEditQuestionClose}
            question={question}
          />
        )}
      </Dialog>

      {/* modals */}
      <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
        <Box sx={modalStyle} width={340}>
          <DeleteModal
            handleConfirmDelete={handleConfirmDelete}
            handleWarningClose={handleWarningClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default EditQuestionaire;
