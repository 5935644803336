/* eslint-disable react-hooks/rules-of-hooks */
import {
  Avatar,
  Box,
  Button,
  Divider,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { authLogoutService } from "../../services/auth.service";
import { getItem, removeItem } from "../../services/localStorage.service";

import { appMenu } from "../../constants";

declare module "react" {
  interface CSSProperties {
    "--tree-view-color"?: string;
    "--tree-view-bg-color"?: string;
    "--tree-bg-color"?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  labelText: string;
  level: number;
  url?: string;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    marginBottom: "1px",
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: "var(--tree-bg-color)",
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props: StyledTreeItemProps) {
  const { labelText, level, ...other } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 0.5,
            pr: 0,
            height: 40,
          }}
        >
          <Typography variant="body1" color="black.main">
            {labelText}
          </Typography>
        </Box>
      }
      style={{
        "--tree-bg-color":
          level === 1 ? "#D9E2EB" : level === 2 ? "#C4D0DB" : "#FFFFFF",
        "--tree-view-color": "#89939E",
        "--tree-view-bg-color": "#E8EBF0",
      }}
      {...other}
    />
  );
}

const sideBar = () => {
  const navigate = useNavigate();

  const accountProfile = getItem("account_profile");

  const handleNavigate = (item: any) => {
    if (item.url) navigate(item.url);
  };

  const logOut = async () => {
    await authLogoutService();
    removeItem("session");
    navigate("/login");
  };

  const handleRole = (roles: any[]) => {
    return roles.find(
      (t: any) => (t.toString(2) & accountProfile.role_id.toString(2)) > 0
    );
  };

  return (
    <>
      <Toolbar sx={{ height: "90px" }}>
        <Box display="flex" justifyContent="center" width={1}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar
              alt="Remy Sharp"
              src={accountProfile.avatar_url || "/static/images/avatar/1.jpg"}
            />
            <Typography variant="body1" color="black.main">
              {accountProfile.first_name} {accountProfile.last_name}
            </Typography>
          </Box>
        </Box>
      </Toolbar>

      <Divider />

      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        sx={{
          height: 264,
          flexGrow: 1,
          maxWidth: 400,
          overflowY: "auto",
          marginBottom: "60px",
          paddingBottom: "90px",
        }}
      >
        {appMenu.map((m) => (
          <>
            {handleRole(m.roles) && (
              <StyledTreeItem
                onClick={() => {
                  handleNavigate(m);
                }}
                key={m.id}
                nodeId={m.id.toString()}
                labelText={m.labelText}
                level={m.level}
              >
                {m.childrens?.map((c: any) => (
                  <>
                    {handleRole(c.roles) && (
                      <StyledTreeItem
                        onClick={() => {
                          handleNavigate(c);
                        }}
                        key={c.id}
                        nodeId={c.id.toString()}
                        labelText={c.labelText}
                        level={c.level}
                      >
                        {c.childrens?.map((t: any) => (
                          <StyledTreeItem
                            onClick={() => {
                              handleNavigate(t);
                            }}
                            key={t.id}
                            nodeId={t.id.toString()}
                            labelText={t.labelText}
                            level={t.level}
                          />
                        ))}
                      </StyledTreeItem>
                    )}
                  </>
                ))}
              </StyledTreeItem>
            )}
          </>
        ))}

        <Box display="flex" justifyContent="center" mt="16px">
          <Button
            color="lightBlue"
            sx={{
              borderRadius: "100px",
              height: "40px",
              width: "195px",
            }}
            variant="contained"
            startIcon={<LogoutIcon sx={{ color: "black.main" }} />}
            type="button"
            onClick={logOut}
          >
            <Typography variant="body1" color="black.main">
              ออกจากระบบ
            </Typography>
          </Button>
        </Box>
      </TreeView>
    </>
  );
};

export default sideBar;
