import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { boxStyle, configToast, statusTypes } from "../../constants";
import {
  getHealthLiteracyByIdService,
  patchHealthLiteracyService,
  postHealthLiteracyUploadService,
} from "../../services/healthLiteracy.service";
import { useAppDispatch } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "จัดการรอบรู้สุขภาพ",
    subtitles: ["รายการรอบรู้สุขภาพ", "แก้ไขรอบรู้สุขภาพ"],
  },
};

const EditHealthLiteracy = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [healthLiteracy, setHealthLiteracy] = useState<any>(null);
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [fileDataURL, setFileDataURL] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | any>(null);

  const { register, handleSubmit, formState, setValue, trigger } = useForm();
  const { isValid, errors } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    Resizer.imageFileResizer(
      file,
      500,
      500,
      "PNG",
      100,
      0,
      (file: any) => {
        setFile(file);
      },
      "file"
    );

    inputRef.current.value = "";
  };

  const handleFileReader = () => {
    let fileReader: any,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  };

  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const handleHealthLiteracyList = () => {
    navigate("/health-literacy-list");
  };

  const getHealthLiteracy = () => {
    getHealthLiteracyByIdService(id).then((resp) => {
      setHealthLiteracy(resp.data);
    });
  };

  const setForm = () => {
    if (healthLiteracy) {
      setValue("title", healthLiteracy.title);
      setValue("sub_title", healthLiteracy.sub_title);
      setValue("reference", healthLiteracy.reference);
      setValue("is_publish", healthLiteracy.is_publish ? 0 : 1);

      setFileDataURL(healthLiteracy.image_url);
      trigger();

      handleLoaded();
    }
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      await patchHealthLiteracyService(id, {
        ...values,
        is_publish: !values.is_publish ? true : false,
      });

      if (file) {
        const formdata = new FormData();
        formdata.append("file", file as File);
        postHealthLiteracyUploadService(id, formdata);
      }

      toast.success("แก้ไขรอบรู้สุขภาพสำเร็จ", {
        ...configToast,
        onClose: () => handleHealthLiteracyList(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "แก้ไขรอบรู้สุขภาพไม่สำเร็จ", configToast);
      handleLoaded();
    }
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };
  
  useEffect(getHealthLiteracy, [id]);

  useEffect(setForm, [healthLiteracy, setValue, trigger]);

  useEffect(handleFileReader, [file]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      display="flex"
      flexDirection={{ xs: "column-reverse", md: "row" }}
      gap="16px"
    >
      {healthLiteracy && (
        <>
          <Box
            sx={{ ...boxStyle, height: "fit-content" }}
            width={{ xs: 1, md: "60%" }}
          >
            <FormControl>
              <TextField
                fullWidth
                type="text"
                label="ชื่อหัวข้อ"
                placeholder="กรอกชื่อหัวข้อ"
                {...register("title", { required: true })}
                size="medium"
              />
            </FormControl>

            <FormControl>
              <TextField
                fullWidth
                type="text"
                label="รายละเอียด"
                rows={3}
                multiline
                placeholder="กรอกรายละเอียด"
                {...register("sub_title", { required: true })}
                size="medium"
              />
            </FormControl>

            <FormControl>
              <TextField
                fullWidth
                type="text"
                label="ลิงค์ภายนอก"
                placeholder="กรอกลิงค์ภายนอก"
                {...register("reference", {
                  required: true,
                  pattern: {
                    value:
                      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
                    message: "ลิงค์ภายนอกไม่ถูกต้อง",
                  },
                })}
                helperText={
                  errors["reference"]
                    ? (errors["reference"].message as string)
                    : ""
                }
                error={!!errors["reference"]}
                size="medium"
              />
            </FormControl>

            <FormControl>
              <InputLabel id="status-label">สถานะ</InputLabel>
              <Select
                defaultValue={healthLiteracy?.is_publish ? 0 : 1}
                labelId="status-label"
                id="status-label"
                placeholder="เลือกสถานะ"
                label="สถานะ"
                {...register("is_publish", { required: true })}
                size="medium"
              >
                {statusTypes.map((status: string, index: number) => (
                  <MenuItem value={index}>{status}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box display="flex" justifyContent="center" gap="16px">
              <Box>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon sx={{ color: "white" }} />}
                  color="green"
                  size="large"
                  type="submit"
                  disabled={!isValid || loading}
                >
                  <Typography color="white">บันทึก</Typography>
                </Button>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  startIcon={<CancelIcon sx={{ color: "white" }} />}
                  color="red"
                  size="large"
                  type="button"
                  component={RouterLink}
                  to="/health-literacy-list"
                >
                  <Typography color="white">ยกเลิก</Typography>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{ ...boxStyle, height: "fit-content" }}
            width={{ xs: 1, md: "40%" }}
          >
            <Typography color="cGrey.main">อัพโหลดรูปภาพ</Typography>

            <Box>
              <Button
                variant="contained"
                color="green"
                onClick={handleUploadClick}
              >
                <Box
                  component="input"
                  hidden
                  accept="image/*"
                  type="file"
                  ref={inputRef}
                  onChange={handleFileChange}
                />
                <Typography color="white">UPLOAD FILE</Typography>
              </Button>
            </Box>

            <Box
              component="img"
              src={fileDataURL}
              sx={{
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                objectFit: "contain",
                width: 1,
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default EditHealthLiteracy;
