import axiosService from "./axios.service";

interface Filters {
  page: any;
  size: any;
}

export const getFaqService = (params: Filters) => {
  return axiosService.get("/faq?" + new URLSearchParams({ ...params }));
};

export const getFaqByIdService = (id: any) => {
  return axiosService.get(`/faq/${id}`);
};

export const postFaqService = (body: any) => {
  return axiosService.post("/faq", body);
};

export const patchFaqService = (id: any, body: any) => {
  return axiosService.patch(`/faq/${id}`, body);
};

export const deleteFaqService = (id: any) => {
  return axiosService.delete(`/faq/${id}`);
};
