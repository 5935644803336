import axios from "axios";
import { memoizedRefreshToken } from "./auth.service";
import { getItem, removeItem } from "./localStorage.service";

const axiosService = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
});

axiosService.interceptors.request.use(
  (config) => {
    config.headers.set(authHeader());
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const authHeader = () => {
  const session = getItem("session");
  if (session?.access_token) {
    return { Authorization: `Bearer ${session?.access_token}` }; // for Spring Boot back-end
  } else {
    return { Authorization: "" }; // for Spring Boot back-end
  }
};

axiosService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      try {
        config.sent = true;

        const session = await memoizedRefreshToken();

        if (session?.access_token) {
          config.headers = {
            ...config.headers,
            authorization: `Bearer ${session?.access_token}`,
          };
        }

        return axios(config);
      } catch (error) {
        removeItem("session");
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosService;
