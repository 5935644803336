import axiosService from "./axios.service";

interface Filters {
  page: any;
  size: any;
}

export const getNewsService = (params: Filters) => {
  return axiosService.get("/news?" + new URLSearchParams({ ...params }));
};

export const postNewsService = (body: any) => {
  return axiosService.post("/news", body);
};

export const patchNewsService = (id: any, body: any) => {
  return axiosService.patch(`/news/${id}`, body);
};

export const deleteNewsService = (id: any) => {
  return axiosService.delete(`/news/${id}`);
};

export const postNewsUploadService = (id: any, formData: FormData) => {
  return axiosService.post(`/news/news-upload/${id}`, formData);
};

export const getNewsByIdService = (id: any) => {
  return axiosService.get(`/news/${id}`);
};
