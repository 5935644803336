import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { SubmitHandler, useForm } from "react-hook-form";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import th from "dayjs/locale/th";

import { boxStyle, configToast, statusTypes } from "../../constants";
import { OverwriteAdapterDayjs } from "../../utils/common.util";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
  postNewsService,
  postNewsUploadService,
} from "../../services/news.service";
import { useAppDispatch } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "จัดการข่าวสาร",
    subtitles: ["รายการข่าวสาร", "สร้างข่าวสาร"],
  },
};

const CreateNews = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [startDate, setStartDate] = useState<string | null>(null);
  const [newsFile, setNewsFile] = useState<File | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [fileDataURL, setFileDataURL] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | any>(null);

  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    Resizer.imageFileResizer(
      file,
      500,
      500,
      "PNG",
      100,
      0,
      (file: any) => {
        setNewsFile(file);
      },
      "file"
    );

    inputRef.current.value = "";
  };

  const handleNavigateNewsList = () => {
    navigate("/news-list");
  };

  const handleFileReader = () => {
    let fileReader: any,
      isCancel = false;
    if (newsFile) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(newsFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  };

  const { register, handleSubmit, formState, setValue, trigger } = useForm({
    mode: "all",
    defaultValues: {
      title: "",
      sub_title: "",
      detail: "",
      reference: null,
      is_publish: null,
      news_date: "",
      // end_date: "",
    },
  });
  const { isValid, isDirty } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      setSaving(true);
      const newsCreated = await postNewsService({
        ...values,
        is_publish: !values.is_publish ? true : false,
        news_date: startDate,
      });

      const formdata = new FormData();
      formdata.append("file", newsFile as File);
      postNewsUploadService(newsCreated.data, formdata);

      toast.success("สร้างข่าวสารสำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateNewsList(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "สร้างข่าวสารไม่สำเร็จ", configToast);
      setSaving(false);
    }
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(handleFileReader, [newsFile]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      display="flex"
      flexDirection={{ xs: "column-reverse", md: "row" }}
      gap="16px"
    >
      <Box
        sx={{ ...boxStyle, height: "fit-content" }}
        width={{ xs: 1, md: "60%" }}
      >
        <FormControl>
          <TextField
            fullWidth
            type="text"
            label="ชื่อหัวข้อ"
            placeholder="กรอกชื่อหัวข้อ"
            {...register("title", { required: true })}
            size="medium"
          />
        </FormControl>

        <FormControl>
          <TextField
            fullWidth
            type="text"
            label="ชื่อเรื่องย่อย"
            placeholder="กรอกชื่อเรื่องย่อย"
            {...register("sub_title", { required: true })}
            size="medium"
          />
        </FormControl>

        <FormControl>
          <TextField
            fullWidth
            type="text"
            label="รายละเอียด"
            placeholder="กรอกรายละเอียด"
            multiline
            rows={4}
            {...register("detail", { required: true })}
            size="medium"
          />
        </FormControl>

        <FormControl>
          <InputLabel id="status-label">สถานะ</InputLabel>
          <Select
            labelId="status-label"
            id="status-label"
            placeholder="เลือกสถานะ"
            label="สถานะ"
            {...register("is_publish", { required: true })}
            size="medium"
          >
            {statusTypes.map((status: string, index: number) => (
              <MenuItem key={index} value={index}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <TextField
            fullWidth
            type="text"
            label="แหล่งอ้างอิง"
            placeholder="กรอกแหล่งอ้างอิง"
            {...register("reference", { required: true })}
            size="medium"
          />
        </FormControl>

        <Box display="flex" justifyContent="center" gap="16px">
          <Box>
            <Button
              variant="contained"
              startIcon={<SaveIcon sx={{ color: "white" }} />}
              color="green"
              size="large"
              type="submit"
              disabled={!isValid || !isDirty || !newsFile || saving}
            >
              <Typography color="white">บันทึก</Typography>
            </Button>
          </Box>

          <Box>
            <Button
              variant="contained"
              startIcon={<CancelIcon sx={{ color: "white" }} />}
              color="red"
              size="large"
              type="button"
              component={RouterLink}
              to="/news-list"
            >
              <Typography color="white">ยกเลิก</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ ...boxStyle, height: "fit-content" }}
        width={{ xs: 1, md: "40%" }}
      >
        <Typography color="cGrey.main">อัพโหลดรูปภาพ</Typography>

        <Box>
          <Button variant="contained" color="green" onClick={handleUploadClick}>
            <Box
              component="input"
              hidden
              accept="image/*"
              type="file"
              ref={inputRef}
              onChange={handleFileChange}
            />
            <Typography color="white">UPLOAD FILE</Typography>
          </Button>
        </Box>

        <Box
          component="img"
          src={fileDataURL}
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            objectFit: "contain",
            width: 1,
          }}
        />

        <FormControl>
          <LocalizationProvider
            dateAdapter={OverwriteAdapterDayjs}
            adapterLocale={th}
          >
            <DatePicker
              label="วันเริ่มต้น เผยแพร่"
              views={["year", "month", "day"]}
              inputFormat="DD/MMM/BBBB"
              value={startDate}
              onChange={async (newValue: any) => {
                const date = dayjs(newValue).format("YYYY-MM-DD");
                await trigger("news_date", { shouldFocus: true });
                setStartDate(date);
                setValue("news_date", date);
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "วว/ดด/ปปปป",
                    readOnly: true,
                  }}
                  {...register("news_date", { required: true })}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      </Box>
    </Box>
  );
};

export default CreateNews;
