import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Modal,
  IconButton,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
  deleteCourseService,
  getCourseByIdService,
  getCourseReport,
  getCourseService,
} from "../../../services/course.service";
import { boxStyle, configToast, modalStyle } from "../../../constants";
import { StyledTableCell, StyledTableRow } from "../../../shared/style";
import DeleteModal from "../../../components/modals/DeleteModal";
import { useAppDispatch, useAppSelector } from "../../../state/hook";
import { setApp } from "../../../state/libs/appSlice";
import saveAs from "file-saver";
import CourseReport from "../../../components/dialogs/course/CourseReport";
import { getTask, setTask } from "../../../state/libs/taskSlice";

const contentToolbar = {
  toolbar: {
    title: "การจัดการหลักสูตร",
    subtitles: ["รายการหลักสูตร"],
  },
};

const CourseList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const taskState = useAppSelector(getTask);

  const [courseList, setCourseList] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [courseId, setCourseId] = useState<number | null>(null);
  const [courseSummary, setCourseSummary] = useState<any>(null);
  const [openCourseSummary, setCourseSummaryOpen] = useState<boolean>(false);

  const { register, getValues } = useForm({
    defaultValues: {
      searchText: "",
    },
  });

  const handleWarningOpen = (id: number) => {
    setCourseId(id);
    setWarningOpen(true);
  };
  const handleWarningClose = () => {
    setCourseId(null);
    setWarningOpen(false);
  };
  const handleCourseSummaryOpen = () => setCourseSummaryOpen(true);
  const handleCourseSummaryClose = () => {
    setCourseSummary(null);
    setCourseSummaryOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleConfirmDelete = async () => {
    try {
      const id = courseId as number;
      handleWarningClose();
      await deleteCourseService(id);
      getCourse();
      toast.success("ลบหลักสูตรสำเร็จ", configToast);
    } catch (error) {
      toast.error("ลบหลักสูตรไม่สำเร็จ", configToast);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOnSearch = () => {
    getCourse();
  };

  const handleDownloadCourseReport = (id: any) => {
    dispatch(
      setTask({
        id: "courseReport" + id,
        reportName: "courseReport",
        process: true,
        processing: false,
      })
    );
  };

  const handleGetCourseSummary = (id: string) => {
    getCourseByIdService(id).then((resp) => {
      setCourseSummary(resp.data.courseSummary);
      handleCourseSummaryOpen();
    });
  };

  const onKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOnSearch();
    }
  };

  const getCourse = () => {
    getCourseService({
      size: rowsPerPage,
      page: page + 1,
      ...getValues(),
    }).then((resp) => {
      setCourseList(resp.data.items);
      setCount(resp.data.total_row);
    });
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  const handleNavigateEditCourse = (courseId: string) => {
    navigate(`/edit-course/${courseId}`);
  };

  useEffect(getCourse, [getValues, page, rowsPerPage]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box sx={boxStyle}>
        <Box display="flex" justifyContent="space-between" gap="16px">
          <Box>
            <TextField
              fullWidth
              type="text"
              placeholder="ค้นหา"
              onKeyUp={onKeyPress}
              {...register("searchText")}
            />
          </Box>

          <Box>
            <Button
              variant="contained"
              startIcon={<SearchIcon sx={{ color: "white" }} />}
              color="green"
              onClick={handleOnSearch}
            >
              <Typography color="white">ค้นหา</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={boxStyle}>
        <Box display="flex" gap="8px">
          <Box>
            <Button
              variant="contained"
              startIcon={<SaveIcon sx={{ color: "white" }} />}
              color="green"
              component={RouterLink}
              to="/create-course"
            >
              <Typography color="white">สร้าง</Typography>
            </Button>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ลำดับ</StyledTableCell>
                <StyledTableCell>หัวข้อคำถาม</StyledTableCell>
                <StyledTableCell>สถานะ</StyledTableCell>
                <StyledTableCell>วันที่สร้าง</StyledTableCell>
                <StyledTableCell>วันที่แก้ไข</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courseList.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.title}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.is_publish ? "เผยแพร่" : "ไม่เผยแพร่"}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.created_date
                      ? dayjs(row.created_date)
                          .locale("th")
                          .format("DD MMM BBBB")
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.last_updated_date
                      ? dayjs(row.last_updated_date)
                          .locale("th")
                          .format("DD MMM BBBB")
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex" gap="8px">
                      <IconButton
                        onClick={() => handleNavigateEditCourse(row.id)}
                      >
                        <ModeEditIcon />
                      </IconButton>
                      <IconButton
                        disabled={taskState.tasks.find(
                          (task: any) => task.id === "courseReport" + row.id
                        )}
                        onClick={() => handleDownloadCourseReport(row.id)}
                      >
                        <FileDownloadIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleGetCourseSummary(row.id)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton onClick={() => handleWarningOpen(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              {!courseList.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={6}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      {/* modals */}
      <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
        <Box sx={modalStyle} width={340}>
          <DeleteModal
            handleConfirmDelete={handleConfirmDelete}
            handleWarningClose={handleWarningClose}
          />
        </Box>
      </Modal>

      {/* Dialog */}
      {courseSummary && (
        <CourseReport
          open={openCourseSummary}
          handleClose={handleCourseSummaryClose}
          summary={courseSummary}
        />
      )}
    </Box>
  );
};

export default CourseList;
