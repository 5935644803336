import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

import { boxStyle, configToast, modalStyle } from "../../constants";
import { StyledTableCell, StyledTableRow } from "../../shared/style";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  deleteQuestionaireService,
  getQuestionaireReportService,
  getQuestionairesService,
} from "../../services/questionaire.service";
import dayjs from "dayjs";
import DeleteModal from "../../components/modals/DeleteModal";
import { setApp } from "../../state/libs/appSlice";
import { useAppDispatch, useAppSelector } from "../../state/hook";
import { getTask, setTask } from "../../state/libs/taskSlice";

const contentToolbar = {
  toolbar: {
    title: "จัดการแบบสอบถาม",
    subtitles: ["รายการแบบสอบถาม"],
  },
};

const QuestionaireList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const taskState = useAppSelector(getTask);

  const [questionaires, setQuestionaires] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [questionaireId, setQuestionaireId] = useState<number | null>(null);

  const { register, getValues } = useForm({
    defaultValues: {
      searchText: "",
    },
  });

  const handleWarningOpen = (id: number) => {
    setQuestionaireId(id);
    setWarningOpen(true);
  };
  const handleWarningClose = () => {
    setQuestionaireId(null);
    setWarningOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigateEditQuestionaire = (id: string) => {
    navigate(`/edit-questionaire/${id}`);
  };

  const handleExportReport = (id: string) => {
    dispatch(
      setTask({
        id: "questionaireReport" + id,
        reportName: "questionaireReport",
        process: true,
        processing: false,
      })
    );
  };

  const handleConfirmDelete = async () => {
    try {
      const id = questionaireId as number;
      handleWarningClose();
      await deleteQuestionaireService(id);
      getQuestionaires();
      toast.success("ลบแบบสอบถามสำเร็จ", configToast);
    } catch (error) {
      toast.error("ลบแบบสอบถามไม่สำเร็จ", configToast);
    }
  };

  const handleOnSearch = () => {
    getQuestionaires();
  };

  const onKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOnSearch();
    }
  };

  const getQuestionaires = () => {
    getQuestionairesService({
      size: rowsPerPage,
      page: page + 1,
      ...getValues(),
    }).then((resp) => {
      setQuestionaires(resp.data.items);
      setCount(resp.data.total_row);
    });
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(getQuestionaires, [getValues, page, rowsPerPage]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box sx={boxStyle}>
        <Box display="flex" justifyContent="space-between" gap="16px">
          <Box>
            <TextField
              fullWidth
              type="text"
              placeholder="ค้นหา"
              onKeyUp={onKeyPress}
              {...register("searchText")}
            />
          </Box>

          <Box>
            <Button
              variant="contained"
              startIcon={<SearchIcon sx={{ color: "white" }} />}
              color="green"
              onClick={handleOnSearch}
            >
              <Typography color="white">ค้นหา</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={boxStyle}>
        <Box display="flex" gap="8px">
          <Box>
            <Button
              variant="contained"
              startIcon={<SaveIcon sx={{ color: "white" }} />}
              color="green"
              component={RouterLink}
              to="/create-questionaire"
            >
              <Typography color="white">สร้าง</Typography>
            </Button>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ลำดับ</StyledTableCell>
                <StyledTableCell>ชื่อแบบสอบถาม</StyledTableCell>
                <StyledTableCell>วันที่สร้าง</StyledTableCell>
                <StyledTableCell>วันที่เผยแพร่</StyledTableCell>
                <StyledTableCell>สภานะ</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questionaires.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.id}
                  </StyledTableCell>
                  <StyledTableCell>{row.title}</StyledTableCell>
                  <StyledTableCell>
                    {row.created_date
                      ? dayjs(row.created_date)
                          .locale("th")
                          .format("DD MMM BBBB")
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.publish_date
                      ? dayjs(row.publish_date)
                          .locale("th")
                          .format("DD MMM BBBB")
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.is_publish ? "เผยแพร่" : "ไม่เผยแพร่"}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex" gap="8px">
                      <IconButton
                        onClick={() => handleNavigateEditQuestionaire(row.id)}
                      >
                        <ModeEditIcon />
                      </IconButton>
                      <IconButton
                        disabled={taskState.tasks.find(
                          (task: any) =>
                            task.id === "questionaireReport" + row.id
                        )}
                        onClick={() => handleExportReport(row.id)}
                      >
                        <FileDownloadIcon />
                      </IconButton>
                      <IconButton onClick={() => handleWarningOpen(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!questionaires.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={6}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {/* modals */}
        <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
          <Box sx={modalStyle} width={340}>
            <DeleteModal
              handleConfirmDelete={handleConfirmDelete}
              handleWarningClose={handleWarningClose}
            />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default QuestionaireList;
