import { ToastOptions } from "react-toastify";

// app
export const appMenu = [
  {
    id: 1,
    labelText: "หน้าหลัก (รายงาน)",
    level: 0,
    url: "",
    roles: [1, 4],
    childrens: [
      {
        id: 3,
        labelText: "รายงานผู้ลงทะเบียน",
        url: "report/registered-member",
        level: 1,
        parent1: 1,
        roles: [1, 4],
      },
      {
        id: 4,
        labelText: "รายงานผลออกกำลังกาย",
        url: "/report/activity",
        level: 1,
        parent1: 1,
        roles: [1, 4],
      },
      {
        id: 5,
        labelText: "รายงานประเภทกีฬา",
        url: "/report/activity-type",
        level: 1,
        parent1: 1,
        roles: [1, 4],
      },
      {
        id: 6,
        labelText: "รายงานกลุ่ม",
        url: "/report/community",
        level: 1,
        parent1: 1,
        roles: [1, 4],
      },
      {
        id: 7,
        labelText: "รายงาน DPAC",
        url: "/report/dpac",
        level: 1,
        parent1: 1,
        roles: [1, 4],
      },
    ],
  },
  {
    id: 8,
    labelText: "การตั้งค่าเว็บไซต์",
    level: 0,
    url: "",
    roles: [1],
    childrens: [
      {
        id: 11,
        labelText: "จัดการคำถามที่พบบ่อย",
        url: "/faq-list",
        level: 1,
        parent1: 8,
        roles: [1],
      },
    ],
  },
  {
    id: 12,
    labelText: "จัดการกลุ่มผู้ใช้งาน",
    level: 0,
    url: "",
    roles: [1, 4],
    childrens: [
      {
        id: 13,
        labelText: "ผู้ใช้งานหลังบ้าน",
        url: "/admin-list",
        level: 1,
        parent1: 12,
        roles: [1],
      },
      {
        id: 14,
        labelText: "ผู้ใช้งานหน้าบ้าน",
        url: "/member-list",
        level: 1,
        parent1: 12,
        roles: [1, 4],
      },
    ],
  },
  {
    id: 15,
    labelText: "ส่งผลออกกำลังกาย",
    level: 0,
    url: "",
    roles: [1, 4],
    childrens: [
      {
        id: 16,
        labelText: "ส่งผลรายครั้ง",
        url: "/member-activity-history",
        level: 1,
        parent1: 13,
        roles: [1],
      },
      {
        id: 17,
        labelText: "ส่งผลรวมรายคน",
        url: "/member-activity-summary",
        level: 1,
        parent1: 13,
        roles: [1, 4],
      },
    ],
  },
  {
    id: 18,
    labelText: "การจัดการหลักสูตร",
    level: 0,
    url: "",
    roles: [1],
    childrens: [
      {
        id: 19,
        labelText: "รายการหลักสูตร",
        url: "/course-list",
        level: 1,
        parent1: 18,
        roles: [1],
      },
      {
        id: 23,
        labelText: "รายการรอตรวจประเมิน",
        url: "/member-evaluation-list",
        level: 1,
        parent1: 18,
        roles: [1],
      },
    ],
  },
  {
    id: 34,
    labelText: "Health Place",
    level: 0,
    url: "",
    roles: [1, 2, 4],
    childrens: [
      {
        id: 35,
        labelText: "ลงทะเบียน",
        url: "/health-place-register",
        level: 1,
        parent1: 34,
        roles: [1, 2, 4],
      },
      {
        id: 36,
        labelText: "รายการ Health Place",
        url: "/health-place-list",
        level: 1,
        parent1: 34,
        roles: [1, 2, 4],
      },
      {
        id: 37,
        labelText: "รายงาน",
        url: "/health-place-report",
        level: 1,
        parent1: 34,
        roles: [1, 2, 4],
      },
    ],
  },
  {
    id: 26,
    labelText: "การจัดการแบนเนอร์",
    level: 0,
    url: "/banner-list",
    roles: [1],
  },
  {
    id: 27,
    labelText: "จัดการข่าวสาร",
    level: 0,
    url: "/news-list",
    roles: [1],
  },
  {
    id: 28,
    labelText: "จัดการรอบรู้สุขภาพ",
    level: 0,
    url: "/health-literacy-list",
    roles: [1],
  },
  {
    id: 29,
    labelText: "จัดการกลุ่ม",
    level: 0,
    url: "/community-list",
    roles: [1, 4],
  },
  {
    id: 31,
    labelText: "จัดการคำยินยอม",
    level: 0,
    url: "/pdpa-list",
    roles: [1],
  },
  {
    id: 32,
    labelText: "จัดการแบบสอบถาม",
    level: 0,
    url: "/questionaire-list",
    roles: [1],
  },
  {
    id: 33,
    labelText: "แลกของรางวัล",
    level: 0,
    url: "",
    roles: [1],
    childrens: [
      {
        id: 35,
        labelText: "จัดการแลกของรางวัล",
        url: "/reward-list",
        level: 1,
        parent1: 33,
        roles: [1],
      },
    ],
  },
  {
    id: 36,
    labelText: "จัดการกิจกรรม",
    level: 0,
    url: "/event-list",
    roles: [1],
  },
];

// styles
export const boxStyle = {
  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
  display: "flex",
  flexDirection: "column",
  padding: "24px 16px",
  borderRadius: "10px",
  background: "#FFFFFF",
  gap: "16px",
};

export const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
};

export const configToast: ToastOptions = {
  position: "top-center",
  autoClose: 1000,
  delay: 100,
};

export const codeTypes = ["Universal code", "One code"];
export const statusTypes = ["เผยแพร่", "ไม่เผยแพร่"];
export const statusSponsores = ["หน่วยงาน", "บริษัท"];
export const questionTypes = [
  "กลุ่มคำถาม",
  "คำถามปลายเปิด",
  "คำถามปลายเปิด เฉพาะตัวเลข",
  "คำถามแบบกำหนดช่วงคำตอบ",
  "คำถามแบบตัวเลือก",
  "คำถามระบุเวลา",
];
export const communityTypes = ["ชุมชน", "โรงเรียน", "องค์กร", "สาธารณสุข"];
export const roleTypes = [
  { id: 1, label: "admin ส่วนกลาง" },
  { id: 2, label: "admin health place" },
  { id: 4, label: "admin เขต" },
];
export const statusUsers = ["เปิดการใช้งาน", "ปิดการใช้งาน"];
