import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { boxStyle } from "../../constants";
import {
  getDistrictService,
  getProvinceService,
} from "../../services/masterData.service";
import { useAppDispatch, useAppSelector } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";
import { getReportDpacService } from "../../services/report.service";
import { StyledTableCell, StyledTableRow } from "../../shared/style";
import { getTask, setTask } from "../../state/libs/taskSlice";

const contentToolbar = {
  toolbar: {
    title: "หน้าหลัก (รายงาน)",
    subtitles: ["รายงาน DPAC"],
  },
};

const DpacSummary = () => {
  const dispatch = useAppDispatch();
  const taskState = useAppSelector(getTask);

  const [provinceId, setProvinceId] = useState<any>("");
  const [provinces, setProvinces] = useState([]);
  const [districs, setDistrics] = useState([]);
  const [provincesFittered, setProvincesFittered] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dpacSummary, setDpacSummary] = useState<any>({
    registered_summary: [],
    lesson_summary: [],
    course_summary: [],
  });

  const { register, getValues, setValue } = useForm({
    defaultValues: {
      district_id: "",
      province_id: "",
      zone: "",
    },
  });

  const getProvinces = () => {
    getProvinceService().then((resp) => setProvinces(resp.data));
  };

  const getDistricts = (provinceId: string) => {
    getDistrictService(provinceId).then((resp) => setDistrics(resp.data));
  };

  const handleZoneChange = (event: SelectChangeEvent) => {
    setProvincesFittered(
      !event.target.value
        ? provinces
        : provinces.filter((t: any) => t.zone === parseInt(event.target.value))
    );
    setProvinceId("");
    setValue("province_id", "");
    setValue("district_id", "");

    setDistrics([]);
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setProvinceId(event.target.value);
    getDistricts(event.target.value);
    setValue("district_id", "");
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  const handleOnSearch = () => {
    getDpacSummaryMember();
  };

  const handleExportReport = () => {
    dispatch(
      setTask({
        id: "dpacExcelReport",
        reportName: "dpacExcelReport",
        process: true,
        processing: false,
        filters: getValues(),
      })
    );
  };

  const getDpacSummaryMember = () => {
    setLoading(true);
    getReportDpacService(getValues()).then((resp) => {
      setLoading(false);
      setDpacSummary(resp.data);
    });
  };

  useEffect(setContentToolbar, [dispatch]);

  useEffect(getProvinces, []);

  useEffect(getDpacSummaryMember, []);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box sx={boxStyle}>
        <Box
          display="flex"
          justifyContent="space-between"
          gap="16px"
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box display="flex" flexDirection="column" gap={4} width={1}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="zone-label">เขตสุขภาพ</InputLabel>
                  <Select
                    labelId="zone-label"
                    id="province"
                    label="เขตสุขภาพ"
                    {...register("zone")}
                    onChange={handleZoneChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {Array.from(Array(13).keys()).map((index: any) => (
                      <MenuItem key={index} value={(index + 1).toString()}>
                        {"เขตสุขภาพที่ " + (index + 1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="province-label">จังหวัด</InputLabel>
                  <Select
                    labelId="province-label"
                    id="province"
                    label="จังหวัด"
                    value={provinceId}
                    {...register("province_id")}
                    onChange={handleProvinceChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {provincesFittered.map(
                      (p: { id: number; name: string }) => (
                        <MenuItem key={p.id} value={p.id.toString()}>
                          {p.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="district-label">อำเภอ</InputLabel>
                  <Select
                    labelId="district-label"
                    id="district"
                    label="อำเภอ"
                    {...register("district_id", { required: true })}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {districs.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.id.toString()}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box display="flex" gap="8px" height="fit-content">
            <Box sx={{ position: "relative" }}>
              <Button
                variant="contained"
                startIcon={<SearchIcon sx={{ color: "white" }} />}
                color="green"
                disabled={loading}
                onClick={handleOnSearch}
              >
                <Typography color="white">ค้นหา</Typography>
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>

            <Box sx={{ position: "relative" }}>
              <Button
                variant="contained"
                color="blue"
                disabled={taskState.tasks.find(
                  (task: any) => task.id === "dpacExcelReport"
                )}
                onClick={handleExportReport}
              >
                <Typography color="white">EXPORT</Typography>
              </Button>
              {taskState.tasks.find(
                (task: any) => task.id === "dpacExcelReport"
              ) && (
                <CircularProgress
                  color="blue"
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={boxStyle}>
        <Typography typography="h4">จำนวนผู้เข้าเรียน</Typography>

        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ที่อยู่</StyledTableCell>
                <StyledTableCell>จำนวนคนเข้าเรียนทั้งหมด</StyledTableCell>
                <StyledTableCell>ประชาชนทั่วไป</StyledTableCell>
                <StyledTableCell>อสม./อสค.</StyledTableCell>
                <StyledTableCell>นักเรียน/นักศึกษา</StyledTableCell>
                <StyledTableCell>
                  เจ้าหน้าที่สังกัดกระทรวงสาธารณสุข
                </StyledTableCell>
                <StyledTableCell>เจ้าหน้าที่ในสถานประกอบการ</StyledTableCell>
                <StyledTableCell>
                  พนักงานองค์กรปกครองส่วนท้องถิ่น
                </StyledTableCell>
                <StyledTableCell>บุคลากรในสถานศึกษา</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dpacSummary.registered_summary.map((row: any, index: number) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.location}</StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_type_1).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_type_2).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_type_3).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_type_4).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_type_5).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_type_6).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_type_7).toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!dpacSummary.registered_summary.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={9}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={boxStyle}>
        <Typography typography="h4">ข้อมูลบทเรียน</Typography>

        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell colSpan={2} align="center">
                  บทนำ
                </StyledTableCell>
                <StyledTableCell colSpan={3} align="center">
                  บทที่ 1
                </StyledTableCell>
                <StyledTableCell colSpan={3} align="center">
                  บทที่ 2
                </StyledTableCell>
                <StyledTableCell colSpan={3} align="center">
                  บทที่ 3
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>ที่อยู่</StyledTableCell>
                <StyledTableCell align="center">เข้าเรียน</StyledTableCell>
                <StyledTableCell align="center">ผ่านระยะเวลา</StyledTableCell>

                <StyledTableCell align="center">เข้าเรียน</StyledTableCell>
                <StyledTableCell align="center">ผ่านระยะเวลา</StyledTableCell>
                <StyledTableCell align="center">ผ่านแบบทดสอบ</StyledTableCell>

                <StyledTableCell align="center">เข้าเรียน</StyledTableCell>
                <StyledTableCell align="center">ผ่านระยะเวลา</StyledTableCell>
                <StyledTableCell align="center">ผ่านแบบทดสอบ</StyledTableCell>

                <StyledTableCell align="center">เข้าเรียน</StyledTableCell>
                <StyledTableCell align="center">ผ่านระยะเวลา</StyledTableCell>
                <StyledTableCell align="center">ผ่านแบบทดสอบ</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dpacSummary.lesson_summary.map((row: any, index: number) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.location}</StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_lesson_0).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_lesson_0_time_passed).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_lesson_1).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_lesson_1_time_passed).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_lesson_1_test_passed).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_lesson_2).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_lesson_2_time_passed).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_lesson_2_test_passed).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_lesson_3).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_lesson_3_time_passed).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {Number(row.total_of_lesson_3_test_passed).toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!dpacSummary.lesson_summary.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={12}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={boxStyle}>
        <Typography typography="h4">ข้อมูลสอบประเมิน</Typography>

        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ที่อยู่</StyledTableCell>
                <StyledTableCell align="center">
                  <Box>สอบรอบที่ 1</Box>
                  <Box>ครั้งที่ 1</Box>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Box>สอบรอบที่ 1</Box>
                  <Box>ครั้งที่ 2</Box>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Box>สอบรอบที่ 2</Box>
                  <Box>ครั้งที่ 1</Box>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Box>สอบรอบที่ 2</Box>
                  <Box>ครั้งที่ 2</Box>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Box>สอบมากกว่า</Box>
                  <Box>2 รอบ</Box>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dpacSummary.course_summary.map((row: any, index: number) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.location}</StyledTableCell>
                  <StyledTableCell align="center">
                    {Number(row.total_of_first_test_passed).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {Number(row.total_of_second_test_passed).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {Number(row.total_of_third_test_passed).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {Number(row.total_of_fourth_test_passed).toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {Number(row.total_of_fifth_test_passed).toLocaleString()}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!dpacSummary.course_summary.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={6}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DpacSummary;
