import {
  Box,
  TextField,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Modal,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { boxStyle, configToast, modalStyle } from "../../constants";
import { StyledTableCell, StyledTableRow } from "../../shared/style";
import { deleteNewsService, getNewsService } from "../../services/news.service";
import DeleteModal from "../../components/modals/DeleteModal";
import { useAppDispatch } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "จัดการข่าวสาร",
    subtitles: ["รายการข่าวสาร"],
  },
};

const NewsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [news, setNews] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { register, getValues } = useForm({
    defaultValues: {
      searchText: "",
    },
  });

  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [newsId, setNewsId] = useState<number | null>(null);

  const handleWarningOpen = (id: number) => {
    setNewsId(id);
    setWarningOpen(true);
  };
  const handleWarningClose = () => {
    setNewsId(null);
    setWarningOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleConfirmDelete = async () => {
    try {
      const id = newsId as number;
      handleWarningClose();
      await deleteNewsService(id);
      getNews();
      toast.success("ลบข่าวสารสำเร็จ", configToast);
    } catch (error) {
      toast.error("ลบข่าวสารไม่สำเร็จ", configToast);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigateEditNews = (id: string) => {
    navigate(`/edit-news/${id}`);
  };

  const handleOnSearch = () => {
    getNews();
  };

  const onKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleOnSearch();
    }
  };

  const getNews = () => {
    getNewsService({ size: rowsPerPage, page: page + 1, ...getValues() }).then(
      (resp) => {
        setNews(resp.data.items);
        setCount(resp.data.total_row);
      }
    );
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(getNews, [getValues, page, rowsPerPage]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box sx={boxStyle}>
        <Box display="flex" justifyContent="space-between" gap="16px">
          <Box>
            <TextField
              fullWidth
              type="text"
              placeholder="ค้นหา"
              onKeyUp={onKeyPress}
              {...register("searchText")}
            />
          </Box>

          <Box>
            <Button
              variant="contained"
              startIcon={<SearchIcon sx={{ color: "white" }} />}
              color="green"
              onClick={handleOnSearch}
            >
              <Typography color="white">ค้นหา</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={boxStyle}>
        <Box display="flex" gap="8px">
          <Box>
            <Button
              variant="contained"
              startIcon={<SaveIcon sx={{ color: "white" }} />}
              color="green"
              component={RouterLink}
              to="/create-news"
            >
              <Typography color="white">สร้าง</Typography>
            </Button>
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ลำดับ</StyledTableCell>
                <StyledTableCell>ชื่อข่าวสาร</StyledTableCell>
                <StyledTableCell>วันที่สร้าง</StyledTableCell>
                <StyledTableCell>วันเริ่มต้น เผยแพร่</StyledTableCell>
                <StyledTableCell>วันสุดสิ้น เผยแพร่</StyledTableCell>
                <StyledTableCell>สถานะ</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {news.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.id}
                  </StyledTableCell>
                  <StyledTableCell>{row.title}</StyledTableCell>
                  <StyledTableCell>
                    {row.created_date
                      ? dayjs(row.created_date)
                          .locale("th")
                          .format("DD MMM BBBB")
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.news_date
                      ? dayjs(row.news_date).locale("th").format("DD MMM BBBB")
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.end_date
                      ? dayjs(row.end_date).locale("th").format("DD MMM BBBB")
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.is_publish ? "เผยแพร่" : "ไม่เผยแพร่"}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex" gap="8px">
                      <Box
                        component="a"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleNavigateEditNews(row.id)}
                      >
                        <ModeEditIcon />
                      </Box>
                      <Box
                        component="a"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleWarningOpen(row.id)}
                      >
                        <DeleteIcon />
                      </Box>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!news.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={7}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {/* modals */}
        <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
          <Box sx={modalStyle} width={340}>
            <DeleteModal
              handleConfirmDelete={handleConfirmDelete}
              handleWarningClose={handleWarningClose}
            />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default NewsList;
