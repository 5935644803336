import axiosService from "./axios.service";

export const getReportRegisteredMemberService = (params: any) => {
  return axiosService.get(
    "/report/registered_member-summary?" + new URLSearchParams({ ...params })
  );
};

export const getReportRegisteredMemberExcelService = (params: any) => {
  return axiosService.get(
    "/report/registered_member-summary/excel?" +
      new URLSearchParams({ ...params }),
    {
      responseType: "blob",
    }
  );
};

export const getReportActivitySummaryService = (params: any) => {
  return axiosService.get(
    "/report/activity-summary?" + new URLSearchParams({ ...params })
  );
};

export const getReportActivitySummaryExcelService = (params: any) => {
  return axiosService.get(
    "/report/activity-summary/excel?" + new URLSearchParams({ ...params }),
    {
      responseType: "blob",
    }
  );
};

export const getReportActivityTypeSummaryService = (params: any) => {
  return axiosService.get(
    "/report/activity-type-summary?" + new URLSearchParams({ ...params })
  );
};

export const getReportActivityTypeSummaryExcelService = (params: any) => {
  return axiosService.get(
    "/report/activity-type-summary/excel?" + new URLSearchParams({ ...params }),
    {
      responseType: "blob",
    }
  );
};

export const getReportCommunitySummaryService = (params: any) => {
  return axiosService.get(
    "/report/community-info?" + new URLSearchParams({ ...params })
  );
};

export const getReportCommunitySummaryExcelService = (params: any) => {
  return axiosService.get(
    "/report/community-info/excel?" + new URLSearchParams({ ...params }),
    {
      responseType: "blob",
    }
  );
};

export const getReportMemberActivitySummaryService = (params: any) => {
  return axiosService.get(
    "/report/member-activity-summary?" + new URLSearchParams({ ...params })
  );
};

export const getReportMemberActivitySummaryCsvService = (params: any) => {
  return axiosService.get(
    "/report/member-activity-summary/csv?" + new URLSearchParams({ ...params }),
    {
      responseType: "blob",
    }
  );
};

export const getReportMemberActivityHistoryService = (params: any) => {
  return axiosService.get(
    "/report/member-activity-history?" + new URLSearchParams({ ...params })
  );
};

export const getReportMemberActivityHistoryCsvService = (params: any) => {
  return axiosService.get(
    "/report/member-activity-history/csv?" + new URLSearchParams({ ...params }),
    {
      responseType: "blob",
    }
  );
};

export const getReportDpacService = (params: any) => {
  return axiosService.get(
    "/course/1/summary/report?" + new URLSearchParams({ ...params })
  );
};

export const getReportDpacExcelService = (params: any) => {
  return axiosService.get(
    "/course/1/summary/report/excel?" + new URLSearchParams({ ...params }),
    {
      responseType: "blob",
    }
  );
};

export const getDownloadReport = (id: any) => {
  return axiosService.get(`/report/job/${id}/download`, {
    responseType: "blob",
  });
};

export const getGenerateReport = (url: string, params: any) => {
  return axiosService.get(`${url}`, {
    params,
  });
};

export const getStatusJobReport = (id: any) => {
  return axiosService.get(`/report/job/${id}`);
};
