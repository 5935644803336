import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-classic";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import th from "dayjs/locale/th";
import dayjs from "dayjs";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  postEventService,
  postEventUploadService,
} from "../../services/event.service";
import { useAppDispatch } from "../../state/hook";
import { boxStyle, configToast } from "../../constants";
import { setApp } from "../../state/libs/appSlice";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { OverwriteAdapterDayjs } from "../../utils/common.util";

const contentToolbar = {
  toolbar: {
    title: "กิจกรรม",
    subtitles: ["จัดการกิจกรรม", "สร้างกิจกรรม"],
  },
};

const CreateEvent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [registerStartDate, setRegisterStartDate] = useState<string | null>(
    null
  );
  const [registerEndDate, setRegisterEndDate] = useState<string | null>(null);
  const [eventFile, setEventFile] = useState<File | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [fileDataURL, setFileDataURL] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | any>(null);

  const [devices, setDevices] = useState<any[]>([]);

  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    Resizer.imageFileResizer(
      file,
      500,
      500,
      "PNG",
      100,
      0,
      (file: any) => {
        setEventFile(file);
        setValue("banner_url", file.name);
        trigger();
      },
      "file"
    );

    inputRef.current.value = "";
  };

  const handleNavigateEventList = () => {
    navigate("/event-list");
  };

  // Handler for checkbox changes
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let arr = [...devices];
    const value = e.target.value;

    if (arr.includes(value)) {
      arr = arr.filter((f) => f !== value);
    } else {
      arr.push(value);
    }

    setDevices(arr);
    setValue(
      "client_type",
      arr.reduce((partialSum, a) => partialSum + Number(a), 0)
    );
  };

  const handlePdpaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { is_need_consent } = getValues();
    setValue("is_need_consent", !is_need_consent);
    setValue("pdpa", "");
  };

  const handleSelfVerificationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { is_need_self_verification } = getValues();
    setValue("is_need_self_verification", !is_need_self_verification);
  };

  const handleFileReader = () => {
    let fileReader: any,
      isCancel = false;
    if (eventFile) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(eventFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  };

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    trigger,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      event_name: "",
      event_code: "",
      pdpa: "",
      is_publish: null,
      banner_url: "",
      client_type: null,
      register_start_date: "",
      register_end_date: "",
      event_start_date: "",
      event_end_date: "",
      is_need_consent: false,
      is_need_self_verification: false,
    },
  });

  const watchIsNeedConsent = watch("is_need_consent");

  const { isValid, isDirty, errors } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      setSaving(true);
      const eventCreated = await postEventService({
        ...values,
        is_publish: !values.is_publish ? true : false,
        register_start_date: registerStartDate,
        register_end_date: registerEndDate,
        event_start_date: startDate,
        event_end_date: endDate,
        event_code: values.event_code || undefined,
      });
      const formdata = new FormData();
      formdata.append("file", eventFile as File);
      postEventUploadService(eventCreated.data, formdata);
      toast.success("สร้างกิจกรรมสำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateEventList(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "สร้างกิจกรรมไม่สำเร็จ", configToast);
      setSaving(false);
    }
  };

  useEffect(setContentToolbar, [dispatch]);

  useEffect(() => {
    register("pdpa");
    register("banner_url", { required: true });
  }, [register]);

  useEffect(handleFileReader, [eventFile]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      display="flex"
      flexDirection={{ xs: "column-reverse", md: "row" }}
      gap="16px"
    >
      <Box
        sx={{ ...boxStyle, height: "fit-content" }}
        width={{ xs: 1, md: 0.6 }}
      >
        <FormControl>
          <TextField
            type="text"
            label="รายชื่อกิจกรรม"
            placeholder="กรอกรายชื่อกิจกรรม"
            {...register("event_name", { required: true })}
            size="medium"
          />
        </FormControl>
        <FormControl>
          <TextField
            type="text"
            label="รหัสกิจกรรม"
            placeholder="กรอกรหัสกิจกรรม"
            {...register("event_code", { required: true })}
            size="medium"
          />
        </FormControl>

        <FormControl>
          <InputLabel id="status-label">สถานะ</InputLabel>
          <Select
            labelId="status-label"
            id="status-label"
            placeholder="เลือกสถานะ"
            label="สถานะ"
            {...register("is_publish", { required: true })}
            size="medium"
          >
            <MenuItem value={1}>เผยแพร่</MenuItem>
            <MenuItem value={0}>ไม่เผยแพร่</MenuItem>
          </Select>
        </FormControl>

        <Box>
          <Typography>สำหรับ</Typography>
          <FormGroup sx={{ flexDirection: "row" }}>
            <FormControlLabel
              control={<Checkbox value={1} onChange={handleChange} />}
              label="Mobile"
            />
            <FormControlLabel
              control={<Checkbox value={2} onChange={handleChange} />}
              label="Web"
            />
          </FormGroup>
        </Box>

        <FormControl>
          <FormGroup sx={{ flexDirection: "row" }}>
            <FormControlLabel
              control={
                <Checkbox
                  value={true}
                  onChange={handleSelfVerificationChange}
                />
              }
              label="ต้องยืนยันตัวตน"
            />
          </FormGroup>
        </FormControl>

        <FormControl
          sx={{ display: "flex", flexDirection: "columm", gap: "8px" }}
        >
          <FormGroup sx={{ flexDirection: "row" }}>
            <FormControlLabel
              control={<Checkbox value={true} onChange={handlePdpaChange} />}
              label="PDPA"
            />
          </FormGroup>

          {watchIsNeedConsent && (
            <CKEditor
              editor={Editor}
              onChange={(_: any, editor: any) => {
                const data = editor.getData();
                setValue("pdpa", data);
              }}
            />
          )}
        </FormControl>

        <Box display="flex" justifyContent="center" gap="16px">
          <Box>
            <Button
              variant="contained"
              startIcon={<SaveIcon sx={{ color: "white" }} />}
              color="green"
              size="large"
              type="submit"
              disabled={!isValid || !isDirty || saving}
            >
              <Typography color="white">บันทึก</Typography>
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              startIcon={<CancelIcon sx={{ color: "white" }} />}
              color="red"
              size="large"
              type="button"
              component={RouterLink}
              to="/event-list"
            >
              <Typography color="white">ยกเลิก</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ ...boxStyle, height: "fit-content" }}
        width={{ xs: 1, md: 0.4 }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography>อัพโหลดรูปภาพ</Typography>

          <Box>
            <Button
              variant="contained"
              color="green"
              onClick={handleUploadClick}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
              />
              <Typography color="white">UPLOAD FILE</Typography>
            </Button>
          </Box>

          <Box
            component="img"
            src={fileDataURL}
            sx={{
              boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
              objectFit: "contain",
              width: 1,
            }}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography>วันลงทะเบียน</Typography>
          <FormControl>
            <LocalizationProvider
              dateAdapter={OverwriteAdapterDayjs}
              adapterLocale={th}
            >
              <DatePicker
                label="วันเริ่มต้น ลงทะเบียน"
                views={["year", "month", "day"]}
                inputFormat="DD/MMM/BBBB"
                value={registerStartDate}
                onChange={async (newValue: any) => {
                  const date = dayjs(newValue).format("YYYY-MM-DD");
                  setRegisterStartDate(date);
                  setValue("register_start_date", date);
                  trigger("register_start_date", { shouldFocus: true });
                }}
                renderInput={(params: any) => (
                  <TextField
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "วว/ดด/ปปปป",
                      readOnly: true,
                    }}
                    {...register("register_start_date", { required: true })}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl>
            <LocalizationProvider
              dateAdapter={OverwriteAdapterDayjs}
              adapterLocale={th}
            >
              <DatePicker
                label="วันสิ้นสุด ลงทะเบียน"
                views={["year", "month", "day"]}
                inputFormat="DD/MMM/BBBB"
                value={registerEndDate}
                onChange={async (newValue: any) => {
                  const date = dayjs(newValue).format("YYYY-MM-DD");
                  setRegisterEndDate(date);
                  setValue("register_end_date", date);
                  trigger("register_end_date", { shouldFocus: true });
                }}
                renderInput={(params: any) => (
                  <TextField
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "วว/ดด/ปปปป",
                      readOnly: true,
                    }}
                    {...register("register_end_date", { required: true })}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography>วัน-เวลา ที่จัดกิจกรรม</Typography>

          <FormControl>
            <LocalizationProvider
              dateAdapter={OverwriteAdapterDayjs}
              adapterLocale={th}
            >
              <DateTimePicker
                label="วันเริ่มต้น กิจกรรม"
                views={["year", "month", "day", "hours", "minutes"]}
                inputFormat="DD/MMM/BBBB HH:mm"
                value={startDate}
                onChange={async (newValue: any) => {
                  const date = dayjs(newValue).format();
                  setStartDate(date);
                  setValue("event_start_date", date);
                  trigger("event_start_date", { shouldFocus: true });
                }}
                renderInput={(params: any) => (
                  <TextField
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "วว/ดด/ปปปป ชม:นาที",
                      readOnly: true,
                    }}
                    {...register("event_start_date", { required: true })}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl>
            <LocalizationProvider
              dateAdapter={OverwriteAdapterDayjs}
              adapterLocale={th}
            >
              <DateTimePicker
                label="วันสิ้นสุด กิจกรรม"
                views={["year", "month", "day", "hours", "minutes"]}
                inputFormat="DD/MMM/BBBB HH:mm"
                value={endDate}
                onChange={async (newValue: any) => {
                  const date = dayjs(newValue).format();
                  setEndDate(date);
                  setValue("event_end_date", date);
                  trigger("event_end_date", { shouldFocus: true });
                }}
                renderInput={(params: any) => (
                  <TextField
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "วว/ดด/ปปปป ชม:นาที",
                      readOnly: true,
                    }}
                    {...register("event_end_date", { required: true })}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateEvent;
