import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-classic";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import th from "dayjs/locale/th";
import dayjs from "dayjs";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  getEventService,
  patchEventService,
  postEventUploadService,
} from "../../services/event.service";
import { useAppDispatch } from "../../state/hook";
import { boxStyle, configToast, statusTypes } from "../../constants";
import { setApp } from "../../state/libs/appSlice";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { OverwriteAdapterDayjs } from "../../utils/common.util";

const contentToolbar = {
  toolbar: {
    title: "กิจกรรม",
    subtitles: ["จัดการกิจกรรม", "แก้ไขกิจกรรม"],
  },
};

const EditEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [event, setEvent] = useState<any>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [registerStartDate, setRegisterStartDate] = useState<string | null>(
    null
  );
  const [registerEndDate, setRegisterEndDate] = useState<string | null>(null);
  const [eventFile, setEventFile] = useState<File | null>(null);
  const [saving, setSaving] = useState<boolean>(false);
  const [fileDataURL, setFileDataURL] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | any>(null);

  const [devices, setDevices] = useState<any[]>([]);

  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    Resizer.imageFileResizer(
      file,
      500,
      500,
      "PNG",
      100,
      0,
      (file: any) => {
        setEventFile(file);
        setValue("banner_url", file.name);
        trigger();
      },
      "file"
    );

    inputRef.current.value = "";
  };

  const handleNavigateEventList = () => {
    navigate("/event-list");
  };

  // Handler for checkbox changes
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let arr = [...devices];
    const value = e.target.value;

    if (arr.includes(value)) {
      arr = arr.filter((f) => f !== value);
    } else {
      arr.push(value);
    }

    setDevices(arr);
    setValue(
      "client_type",
      arr.reduce((partialSum, a) => partialSum + Number(a), 0)
    );
  };

  const handlePdpaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { is_need_consent } = getValues();
    setValue("is_need_consent", !is_need_consent);
    setValue("pdpa", "");
  };

  const handleSelfVerificationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { is_need_self_verification } = getValues();
    setValue("is_need_self_verification", !is_need_self_verification);
  };

  const handleFileReader = () => {
    let fileReader: any,
      isCancel = false;
    if (eventFile) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(eventFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  };

  const isDeviceChecked: any = (number: number) => {
    return ((number.toString(2) as any) &
      (event.client_type.toString(2) as any)) >
      0
      ? true
      : false;
  };

  const {
    register,
    handleSubmit,
    formState,
    watch,
    setValue,
    trigger,
    getValues,
  } = useForm({ mode: "all" });

  const watchIsNeedConsent = watch("is_need_consent");

  const { isValid } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      setSaving(true);
      await patchEventService(id, {
        ...values,
        is_publish: !values.is_publish ? true : false,
        register_start_date: registerStartDate,
        register_end_date: registerEndDate,
        event_start_date: startDate,
        event_end_date: endDate,
        event_code: values.event_code || undefined,
      });

      if (eventFile) {
        const formdata = new FormData();
        formdata.append("file", eventFile as File);
        postEventUploadService(id, formdata);
      }

      toast.success("แก้ไขกิจกรรมสำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateEventList(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "แก้ไขกิจกรรมไม่สำเร็จ", configToast);
      setSaving(false);
    }
  };

  const getEvent = () => {
    getEventService(id).then((resp) => {
      setEvent(resp.data);
    });
  };

  const setForm = () => {
    if (event) {
      setValue("event_name", event.event_name);
      setValue("event_code", event.event_code);
      setValue("pdpa", event.event_name);
      setValue("is_publish", event.is_publish ? 0 : 1);
      setValue("client_type", event.client_type);
      setValue("banner_url", event.banner_url);
      setValue("is_need_consent", event.is_need_consent);
      setValue("is_need_self_verification", event.is_need_self_verification);

      const register_start_date = dayjs(event.register_start_date).format(
        "YYYY-MM-DD"
      );
      const register_end_date = dayjs(event.register_end_date).format(
        "YYYY-MM-DD"
      );

      setRegisterStartDate(register_start_date);
      setRegisterEndDate(register_end_date);

      setValue("register_start_date", register_start_date);
      setValue("register_end_date", register_end_date);

      const event_start_date = dayjs(event.event_start_date).format(
        "YYYY-MM-DD HH:mm"
      );
      const event_end_date = dayjs(event.event_end_date).format(
        "YYYY-MM-DD HH:mm"
      );

      setStartDate(event_start_date);
      setEndDate(event_end_date);

      setValue("event_start_date", event_start_date);
      setValue("event_end_date", event_end_date);

      setFileDataURL(event.banner_url);

      trigger();
    }
  };

  useEffect(setContentToolbar, [dispatch]);

  useEffect(() => {
    register("pdpa");
    register("banner_url", { required: true });
  }, [register]);

  useEffect(handleFileReader, [eventFile]);
  useEffect(getEvent, [id]);
  useEffect(setForm, [event, setValue, trigger]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      display="flex"
      flexDirection={{ xs: "column-reverse", md: "row" }}
      gap="16px"
    >
      {event && (
        <>
          <Box
            sx={{ ...boxStyle, height: "fit-content" }}
            width={{ xs: 1, md: 0.6 }}
          >
            <FormControl>
              <TextField
                type="text"
                label="รายชื่อกิจกรรม"
                placeholder="กรอกรายชื่อกิจกรรม"
                {...register("event_name", { required: true })}
                size="medium"
              />
            </FormControl>
            <FormControl>
              <TextField
                type="text"
                label="รหัสกิจกรรม"
                placeholder="กรอกรหัสกิจกรรม"
                {...register("event_code")}
                size="medium"
              />
            </FormControl>

            <FormControl>
              <InputLabel id="status-label">สถานะ</InputLabel>
              <Select
                defaultValue={event.is_publish ? 0 : 1}
                labelId="status-label"
                id="status-label"
                placeholder="เลือกสถานะ"
                label="สถานะ"
                {...register("is_publish", { required: true })}
                size="medium"
              >
                {statusTypes.map((status: string, index: number) => (
                  <MenuItem value={index}>{status}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box>
              <Typography>สำหรับ</Typography>
              <FormGroup sx={{ flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={isDeviceChecked(1)}
                      value={1}
                      onChange={handleChange}
                    />
                  }
                  label="Mobile"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={isDeviceChecked(2)}
                      value={2}
                      onChange={handleChange}
                    />
                  }
                  label="Web"
                />
              </FormGroup>
            </Box>

            <FormControl>
              <FormGroup sx={{ flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={event.is_need_self_verification}
                      onChange={handleSelfVerificationChange}
                    />
                  }
                  label="ต้องยืนยันตัวตน"
                />
              </FormGroup>
            </FormControl>

            <FormControl
              sx={{ display: "flex", flexDirection: "columm", gap: "8px" }}
            >
              <FormGroup sx={{ flexDirection: "row" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={event.is_need_consent}
                      onChange={handlePdpaChange}
                    />
                  }
                  label="PDPA"
                />
              </FormGroup>

              {watchIsNeedConsent && (
                <CKEditor
                  data={event.pdpa}
                  editor={Editor}
                  onChange={(_: any, editor: any) => {
                    const data = editor.getData();
                    setValue("pdpa", data);
                  }}
                />
              )}
            </FormControl>
            <Box display="flex" justifyContent="center" gap="16px">
              <Box>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon sx={{ color: "white" }} />}
                  color="green"
                  size="large"
                  type="submit"
                  disabled={!isValid || saving}
                >
                  <Typography color="white">บันทึก</Typography>
                </Button>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  startIcon={<CancelIcon sx={{ color: "white" }} />}
                  color="red"
                  size="large"
                  type="button"
                  component={RouterLink}
                  to="/event-list"
                >
                  <Typography color="white">ยกเลิก</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ ...boxStyle, height: "fit-content" }}
            width={{ xs: 1, md: 0.4 }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Typography>อัพโหลดรูปภาพ</Typography>

              <Box>
                <Button
                  variant="contained"
                  color="green"
                  onClick={handleUploadClick}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    ref={inputRef}
                    onChange={handleFileChange}
                  />
                  <Typography color="white">UPLOAD FILE</Typography>
                </Button>
              </Box>

              <Box
                component="img"
                src={fileDataURL}
                sx={{
                  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                  objectFit: "contain",
                  width: 1,
                }}
              />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Typography>วันลงทะเบียน</Typography>
              <FormControl>
                <LocalizationProvider
                  dateAdapter={OverwriteAdapterDayjs}
                  adapterLocale={th}
                >
                  <DatePicker
                    label="วันเริ่มต้น ลงทะเบียน"
                    views={["year", "month", "day"]}
                    inputFormat="DD/MMM/BBBB"
                    value={registerStartDate}
                    onChange={async (newValue: any) => {
                      const date = dayjs(newValue).format("YYYY-MM-DD");
                      await trigger("register_start_date", {
                        shouldFocus: true,
                      });
                      setRegisterStartDate(date);
                      setValue("register_start_date", date);
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "วว/ดด/ปปปป",
                          readOnly: true,
                        }}
                        {...register("register_start_date", { required: true })}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>

              <FormControl>
                <LocalizationProvider
                  dateAdapter={OverwriteAdapterDayjs}
                  adapterLocale={th}
                >
                  <DatePicker
                    label="วันสิ้นสุด ลงทะเบียน"
                    views={["year", "month", "day"]}
                    inputFormat="DD/MMM/BBBB"
                    value={registerEndDate}
                    onChange={async (newValue: any) => {
                      const date = dayjs(newValue).format("YYYY-MM-DD");
                      await trigger("register_end_date", { shouldFocus: true });
                      setRegisterEndDate(date);
                      setValue("register_end_date", date);
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "วว/ดด/ปปปป",
                          readOnly: true,
                        }}
                        {...register("register_end_date", { required: true })}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Typography>วัน-เวลา ที่จัดกิจกรรม</Typography>

              <FormControl>
                <LocalizationProvider
                  dateAdapter={OverwriteAdapterDayjs}
                  adapterLocale={th}
                >
                  <DateTimePicker
                    label="วันเริ่มต้น กิจกรรม"
                    views={["year", "month", "day", "hours", "minutes"]}
                    inputFormat="DD/MMM/BBBB HH:mm"
                    value={startDate}
                    onChange={async (newValue: any) => {
                      const date = dayjs(newValue).format();
                      await trigger("event_start_date", { shouldFocus: true });
                      setStartDate(date);
                      setValue("event_start_date", date);
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "วว/ดด/ปปปป ชม:นาที",
                          readOnly: true,
                        }}
                        {...register("event_start_date", { required: true })}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>

              <FormControl>
                <LocalizationProvider
                  dateAdapter={OverwriteAdapterDayjs}
                  adapterLocale={th}
                >
                  <DateTimePicker
                    label="วันสิ้นสุด กิจกรรม"
                    views={["year", "month", "day", "hours", "minutes"]}
                    inputFormat="DD/MMM/BBBB HH:mm"
                    value={endDate}
                    onChange={async (newValue: any) => {
                      const date = dayjs(newValue).format();
                      await trigger("event_end_date", { shouldFocus: true });
                      setEndDate(date);
                      setValue("event_end_date", date);
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "วว/ดด/ปปปป ชม:นาที",
                          readOnly: true,
                        }}
                        {...register("event_end_date", { required: true })}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default EditEvent;
