import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { setApp } from "../../state/libs/appSlice";
import { useAppDispatch } from "../../state/hook";

import { boxStyle, configToast } from "../../constants";
import {
  getDistrictService,
  getProvinceService,
  getSubdistrictService,
} from "../../services/masterData.service";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import Resizer from "react-image-file-resizer";
import {
  postHealthPlaceImageUpload,
  postHealthPlaceService,
} from "../../services/healthPlace.service";
import { getItem } from "../../services/localStorage.service";
import { isAdminHealthPlace, isAdminZone } from "../../utils/authorize.util";

const contentToolbar = {
  toolbar: {
    title: "Health Place",
    subtitles: ["Health Place", "ลงทะเบียน"],
  },
};

const HealthPlaceResgister = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accountProfile = getItem("account_profile");

  const [saving, setSaving] = useState<boolean>(false);
  const [provinces, setProvinces] = useState([]);
  const [provincesFittered, setProvincesFittered] = useState([]);
  const [provinceId, setProvinceId] = useState<any>(null);
  const [districs, setDistrics] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);
  const [openTime, setOpenTime] = useState<any>(null);
  const [closeTime, setCloseTime] = useState<any>(null);
  const [fileImage, setFileImage] = useState<any>(null);
  const [walkRun, setWalkRun] = useState<boolean>(false);
  const [bicycle, setBicycle] = useState<boolean>(false);
  const [fileDataURL, setFileDataURL] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | any>(null);

  const { register, handleSubmit, formState, setValue, getValues, trigger } =
    useForm({
      mode: "all",
      defaultValues: {
        place_type: "",
        name: "",
        google_map_url: "",
        remark: "",
        open_time_hh: null,
        open_time_mm: null,
        close_time_hh: null,
        close_time_mm: null,
        province_id: "",
        district_id: "",
        subdistrict_id: "",
        facilities: [
          { type: "parking", is_available: false },
          { type: "toilet", is_available: false },
          { type: "disability", is_available: false },
        ],
        sport_types: [],
      },
    });
  const { isValid, isDirty } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const getProvinces = () => {
    getProvinceService().then((resp) => {
      if (isAdminZone()) {
        setProvincesFittered(
          resp.data.filter((t: any) => t.zone === accountProfile.zone)
        );
      } else {
        setProvincesFittered(resp.data);
      }
      setProvinces(resp.data);
    });
  };

  const getDistricts = (provinceId: string) => {
    getDistrictService(provinceId).then((resp) => setDistrics(resp.data));
  };

  const getSubdistricts = (districtId: string) => {
    getSubdistrictService(districtId).then((resp) =>
      setSubdistricts(resp.data)
    );
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setProvinceId(event.target.value);
    getDistricts(event.target.value);

    setValue("district_id", "");
    setValue("subdistrict_id", "");

    setDistrics([]);
    setSubdistricts([]);
  };

  const handleDistrictChange = (event: SelectChangeEvent) => {
    setValue("subdistrict_id", "");

    setSubdistricts([]);
    getSubdistricts(event.target.value);
  };

  const handleFacilityChange = (event: SelectChangeEvent) => {
    const { facilities } = getValues();
    const index = facilities.findIndex((t) => t.type === event.target.value);
    facilities[index].is_available = !facilities[index].is_available;

    setValue("facilities", facilities);
  };

  const handleSporTypesChange = (event: SelectChangeEvent) => {
    const { sport_types }: any = getValues();
    const id = Number(event.target.value);
    const index = sport_types.findIndex((t: any) => t.id === id);

    if (index !== -1) {
      sport_types.splice(index, 1);

      if (id === 1) {
        setWalkRun(false);
      } else if (id === 2) {
        setBicycle(false);
      }
    } else {
      let optionData = {};
      if (id === 1) {
        optionData = { kilometre: 0, metre: 0 };
        setWalkRun(true);
      } else if (id === 2) {
        optionData = { kilometre: 0, metre: 0 };
        setBicycle(true);
      }

      sport_types.push({ id, ...optionData });
    }

    setValue("sport_types", sport_types);
  };

  const handleKilometreChange = (id: any, value: number) => {
    const { sport_types }: any = getValues();
    const index = sport_types.findIndex((t: any) => t.id === id);
    sport_types[index] = { ...sport_types[index], kilometre: Number(value) };
    setValue("sport_types", sport_types);
  };

  const handleMetreChange = (id: any, value: number) => {
    const { sport_types }: any = getValues();
    const index = sport_types.findIndex((t: any) => t.id === id);
    sport_types[index] = { ...sport_types[index], metre: Number(value) };
    setValue("sport_types", sport_types);
  };

  const handleOpenTimeChange = (event: any) => {
    const { $H, $m } = event;

    trigger("open_time_hh", { shouldFocus: true });
    trigger("open_time_mm", { shouldFocus: true });

    setValue("open_time_hh", $H);
    setValue("open_time_mm", $m);

    setOpenTime(event);
  };

  const handleCloseTimeChange = (event: any) => {
    const { $H, $m } = event;

    trigger("close_time_hh", { shouldFocus: true });
    trigger("close_time_mm", { shouldFocus: true });

    setValue("close_time_hh", $H);
    setValue("close_time_mm", $m);

    setCloseTime(event);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    Resizer.imageFileResizer(
      file,
      500,
      500,
      "PNG",
      100,
      0,
      (file: any) => {
        setFileImage(file);
      },
      "file"
    );

    inputRef.current.value = "";
  };

  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const handleRemoveImage = () => {
    setFileDataURL("");
    setFileImage("");
  };

  const handleNavigateHealthPlaceList = () => {
    navigate("/health-place-list");
  };

  const handleFileReader = () => {
    let fileReader: any,
      isCancel = false;
    if (fileImage) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(fileImage);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  const registerForm = () => {
    if (isAdminHealthPlace()) {
      setProvinceId(accountProfile.province_id);
      setValue("province_id", accountProfile.province_id);
      getDistricts(accountProfile.province_id);
    }

    register("open_time_hh", { required: true });
    register("open_time_mm", { required: true });
    register("close_time_hh", { required: true });
    register("close_time_mm", { required: true });
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      setSaving(true);
      const {
        place_type,
        name,
        open_time_hh,
        open_time_mm,
        close_time_hh,
        close_time_mm,
        province_id,
        district_id,
        subdistrict_id,
        facilities,
        sport_types,
        remark,
        google_map_url,
      } = values;

      const healthPlaceCreated = await postHealthPlaceService({
        place_type,
        name,
        open_time_hh,
        open_time_mm,
        close_time_hh,
        close_time_mm,
        province_id,
        district_id,
        subdistrict_id,
        remark,
        google_map_url,
        service_option: {
          facilities,
          sport_types,
        },
      });

      if (fileImage) {
        const formdata = new FormData();
        formdata.append("file", fileImage);
        await postHealthPlaceImageUpload(healthPlaceCreated.data, formdata);
      }

      toast.success("ลงทะเบียน Health Place สำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateHealthPlaceList(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(
        resMessage || "ลงทะเบียน Health Place ไม่สำเร็จ",
        configToast
      );
      setSaving(false);
    }
  };

  useEffect(handleFileReader, [fileImage]);

  useEffect(setContentToolbar, [dispatch]);

  useEffect(getProvinces, []);

  useEffect(registerForm, []);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      flexDirection="column"
      display="flex"
      gap="16px"
    >
      <Box sx={boxStyle}>
        <Typography typography="h5">ข้อมูลสถานที่</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <FormLabel>
                <Typography typography="subtitle2">รูปภาพ</Typography>
              </FormLabel>
              <input
                hidden
                accept="image/*"
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
              />
              <TextField
                disabled
                InputProps={{
                  startAdornment: (
                    <Box
                      component="img"
                      src={fileDataURL}
                      sx={{
                        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                        objectFit: "contain",
                        width: 225,
                      }}
                    />
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box display="flex" flexDirection="column">
                        <IconButton edge="end" onClick={handleUploadClick}>
                          <ImageOutlinedIcon />
                        </IconButton>
                        {fileDataURL && (
                          <IconButton edge="end" onClick={handleRemoveImage}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        )}
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <FormLabel>
                <Typography typography="subtitle2">ชื่อสถานที่</Typography>
              </FormLabel>

              <TextField
                type="text"
                size="medium"
                {...register("name", { required: true })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <FormLabel>
                <Typography typography="subtitle2">ประเภทสถานที่</Typography>
              </FormLabel>

              <Select
                labelId="place-type-label"
                id="place_type"
                {...register("place_type", { required: true })}
              >
                <MenuItem value={1}>สวนสุขภาพ</MenuItem>
                <MenuItem value={2}>สนามกีฬา</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <FormLabel>
                <Typography typography="subtitle2">เวลาเปิด</Typography>
              </FormLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <TimePicker
                    ampm={false}
                    openTo="hours"
                    views={["hours", "minutes"]}
                    inputFormat="HH:mm น."
                    mask="__:__"
                    value={openTime}
                    onChange={handleOpenTimeChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <FormLabel>
                <Typography typography="subtitle2">เวลาปิด</Typography>
              </FormLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <TimePicker
                    ampm={false}
                    openTo="hours"
                    views={["hours", "minutes"]}
                    inputFormat="HH:mm น."
                    mask="__:__"
                    value={closeTime}
                    onChange={handleCloseTimeChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <FormLabel>
                <Typography typography="subtitle2">จังหวัด</Typography>
              </FormLabel>
              <Select
                value={provinceId}
                disabled={isAdminHealthPlace()}
                labelId="province-label"
                id="province"
                {...register("province_id", { required: true })}
                onChange={handleProvinceChange}
              >
                {provincesFittered.map((p: { id: number; name: string }) => (
                  <MenuItem key={p.id} value={p.id.toString()}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <FormLabel>
                <Typography typography="subtitle2">อำเภอ</Typography>
              </FormLabel>
              <Select
                labelId="province-label"
                id="district"
                {...register("district_id", { required: true })}
                onChange={handleDistrictChange}
              >
                {districs.map((p: { id: number; name: string }) => (
                  <MenuItem key={p.id} value={p.id.toString()}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <FormLabel>
                <Typography typography="subtitle2">ตำบล</Typography>
              </FormLabel>
              <Select
                labelId="province-label"
                id="subdistrict_id"
                {...register("subdistrict_id", { required: true })}
              >
                {subdistricts.map((p: { id: number; name: string }) => (
                  <MenuItem key={p.id} value={p.id.toString()}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <FormLabel>
                <Typography typography="subtitle2">Google Map</Typography>
              </FormLabel>
              <TextField
                type="text"
                {...register("google_map_url", { required: true })}
                size="medium"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel component="legend">
              <Typography typography="subtitle2">สิ่งอำนวยความสะดวก</Typography>
            </FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    name="parking"
                    value="parking"
                    onChange={handleFacilityChange}
                  />
                }
                label="ที่จอดรถ"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="toilet"
                    value="toilet"
                    onChange={handleFacilityChange}
                  />
                }
                label="ห้องน้ำ"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="disability"
                    value="disability"
                    onChange={handleFacilityChange}
                  />
                }
                label="เหมาะสำหรับผู้พิการ"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>

      <Box sx={boxStyle}>
        <Typography typography="h5">รายละเอียดประเภทการออกกำลังกาย</Typography>

        <Box display="flex" flexDirection="column" gap="16px">
          <Box display="flex" gap="16px">
            <FormControlLabel
              control={<Checkbox value={1} onChange={handleSporTypesChange} />}
              label="เดิน - วิ่ง"
            />

            {walkRun && (
              <>
                <Typography alignSelf="center">ระยะทางเดิน - วิ่ง</Typography>

                <TextField
                  type="number"
                  size="small"
                  defaultValue={0}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">กิโลเมตร</InputAdornment>
                    ),
                  }}
                  onChange={(event: any) => {
                    handleKilometreChange(1, event.target.value);
                  }}
                />

                <TextField
                  type="number"
                  size="small"
                  defaultValue={0}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">เมตร</InputAdornment>
                    ),
                  }}
                  onChange={(event: any) => {
                    handleMetreChange(1, event.target.value);
                  }}
                />
              </>
            )}
          </Box>

          <Box display="flex" gap="16px">
            <FormControlLabel
              control={<Checkbox value={2} onChange={handleSporTypesChange} />}
              label="จักรยาน"
            />

            {bicycle && (
              <>
                <Typography alignSelf="center">ระยะทางจักรยาน</Typography>

                <TextField
                  type="number"
                  size="small"
                  defaultValue={0}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">กิโลเมตร</InputAdornment>
                    ),
                  }}
                  onChange={(event: any) => {
                    handleKilometreChange(2, event.target.value);
                  }}
                />

                <TextField
                  type="number"
                  size="small"
                  defaultValue={0}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">เมตร</InputAdornment>
                    ),
                  }}
                  onChange={(event: any) => {
                    handleMetreChange(2, event.target.value);
                  }}
                />
              </>
            )}
          </Box>

          <Box display="flex" gap="16px">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox onChange={handleSporTypesChange} value={2001} />
                }
                label="อุปกรณ์ออกกำลังกายกลางแจ้ง"
              />

              <FormControlLabel
                control={
                  <Checkbox onChange={handleSporTypesChange} value={11} />
                }
                label="บาสเกตบอล"
              />

              <FormControlLabel
                control={
                  <Checkbox onChange={handleSporTypesChange} value={20} />
                }
                label="วอลเลย์บอล"
              />

              <FormControlLabel
                control={
                  <Checkbox onChange={handleSporTypesChange} value={10} />
                }
                label="แบดมินตัน"
              />

              <FormControlLabel
                control={
                  <Checkbox onChange={handleSporTypesChange} value={8} />
                }
                label="ฟุตบอล / ฟุตซอล"
              />

              <FormControlLabel
                control={
                  <Checkbox onChange={handleSporTypesChange} value={3} />
                }
                label="ยกน้ำหนัก"
              />
            </FormGroup>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox onChange={handleSporTypesChange} value={7} />
                }
                label="ว่ายน้ำ"
              />

              <FormControlLabel
                control={
                  <Checkbox onChange={handleSporTypesChange} value={9} />
                }
                label="เทนนิส"
              />

              <FormControlLabel
                control={
                  <Checkbox onChange={handleSporTypesChange} value={2002} />
                }
                label="เทเบิลเทนนิส"
              />

              <FormControlLabel
                control={
                  <Checkbox onChange={handleSporTypesChange} value={21} />
                }
                label="สเกตบอร์ด"
              />

              <FormControlLabel
                control={
                  <Checkbox onChange={handleSporTypesChange} value={14} />
                }
                label="เปตอง"
              />

              <FormControlLabel
                control={
                  <Checkbox onChange={handleSporTypesChange} value={15} />
                }
                label="ตะกร้อ"
              />
            </FormGroup>
          </Box>
        </Box>
      </Box>

      <Box sx={boxStyle}>
        <Typography typography="h5">ข้อมูลเพิ่มเติม (ถ้ามี)</Typography>

        <TextField
          type="text"
          {...register("remark")}
          size="medium"
          multiline
          rows={3}
        />
      </Box>

      <Box sx={boxStyle}>
        <Box display="flex" justifyContent="end" gap="16px">
          <Box>
            <Button
              variant="contained"
              startIcon={<SaveIcon sx={{ color: "white" }} />}
              color="green"
              size="large"
              type="submit"
              disabled={!isValid || !isDirty || saving}
            >
              <Typography color="white">บันทึก</Typography>
            </Button>
          </Box>

          <Box>
            <Button
              variant="contained"
              startIcon={<CancelIcon sx={{ color: "white" }} />}
              color="red"
              size="large"
              type="button"
              component={RouterLink}
              to="/health-place-list"
            >
              <Typography color="white">ยกเลิก</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HealthPlaceResgister;
