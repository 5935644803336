import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  getLessonQuizByIdService,
  patchLessonQuizService,
} from "../../../services/course.service";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SaveIcon from "@mui/icons-material/Save";
import { useEffect, useState } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { boxStyle, configToast, statusTypes } from "../../../constants";
import { useAppDispatch } from "../../../state/hook";
import { setApp } from "../../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "การจัดการหลักสูตร",
    subtitles: [
      "รายการหลักสูตร",
      "แก้ไขหลักสูตร",
      "แก้ไขบทเรียน",
      "แก้ไขแบบทดสอบ",
    ],
  },
};

const EditLessonQuiz = () => {
  const { lessonQuizId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [lessonQuiz, setLessonQuiz] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const { register, handleSubmit, formState, setValue, control } = useForm({
    mode: "all",
  });
  const { isValid } = formState;

  const onError = (errors: any, e: any) => console.log(errors, e);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "answer",
  });

  const onAddAnswer = () => {
    append({ answer_order: fields.length + 1, answer: "", is_correct: false });
  };

  const setForm = () => {
    if (lessonQuiz) {
      setValue("question", lessonQuiz.question);
      setValue("answer", lessonQuiz.answer);
      setValue("quiz_order", lessonQuiz.quiz_order);
      setValue("is_publish", lessonQuiz.is_publish ? 0 : 1);
    }
  };

  const getLessonQuizById = () => {
    getLessonQuizByIdService(lessonQuizId).then((resp) => {
      setLessonQuiz(resp.data);
    });
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();

      await patchLessonQuizService(lessonQuizId, {
        ...values,
        is_publish: !values.is_publish ? true : false,
      });

      toast.success("แก้ไขแบบทดสอบสำเร็จ", {
        ...configToast,
        onClose: () => navigate(-1),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "แก้ไขแบบทดสอบไม่สำเร็จ", configToast);
      handleLoaded();
    }
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(getLessonQuizById, [lessonQuizId]);

  useEffect(setForm, [lessonQuiz, setValue]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      display="flex"
      flexDirection="column"
      gap="16px"
    >
      {lessonQuiz && (
        <Box sx={boxStyle}>
          <FormControl>
            <TextField
              fullWidth
              type="text"
              label="คำถาม"
              placeholder="กรอกคำถาม"
              {...register("question", { required: true })}
              multiline
              rows={3}
              size="medium"
            />
          </FormControl>

          <FormControl>
            <InputLabel id="status-label">สถานะ</InputLabel>
            <Select
              defaultValue={lessonQuiz.is_publish ? 0 : 1}
              labelId="status-label"
              id="status-label"
              placeholder="เลือกสถานะ"
              label="สถานะ"
              {...register("is_publish", { required: true })}
              size="medium"
            >
              {statusTypes.map((status: string, index: number) => (
                <MenuItem key={index} value={index}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <TextField
              fullWidth
              type="number"
              label="ลำดับคำถาม"
              placeholder="กรอกลำดับคำถาม"
              {...register("quiz_order", { required: true, min: 0 })}
              size="medium"
            />
          </FormControl>

          <Typography typography="h3" color="cGrey.main">
            คำตอบ
          </Typography>

          {fields.map((answer: any, index) => (
            <Box key={answer.id} display="flex" gap="16px">
              <TextField
                fullWidth
                type="text"
                label={`คำตอบที่ ${index + 1}`}
                {...register(`answer.${index}.answer`, {
                  required: true,
                })}
              />

              <Box
                display="flex"
                gap="8px"
                alignItems="center"
                alignSelf="center"
              >
                <Checkbox
                  defaultChecked={answer.is_correct}
                  {...register(`answer.${index}.is_correct`)}
                />

                <Box
                  display="flex"
                  alignItems="center"
                  component="a"
                  sx={{ cursor: "pointer" }}
                  onClick={() => remove(index)}
                >
                  <RemoveCircleOutlineIcon sx={{ color: "red.main" }} />
                </Box>
              </Box>
            </Box>
          ))}

          <Box>
            <Button
              type="button"
              variant="contained"
              startIcon={<AddCircleOutlineIcon sx={{ color: "white" }} />}
              color="green"
              size="large"
              onClick={onAddAnswer}
            >
              <Typography color="white">เพิ่มคำตอบ</Typography>
            </Button>
          </Box>

          <Box display="flex" justifyContent="center" gap="16px">
            <Button
              type="submit"
              variant="contained"
              startIcon={<SaveIcon sx={{ color: "white" }} />}
              color="green"
              size="large"
              disabled={!isValid || loading}
            >
              <Typography color="white">บันทึก</Typography>
            </Button>

            <Button
              variant="contained"
              startIcon={<CancelIcon sx={{ color: "white" }} />}
              color="red"
              size="large"
              type="button"
              onClick={() => navigate(-1)}
            >
              <Typography color="white">ยกเลิก</Typography>
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EditLessonQuiz;
