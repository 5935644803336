import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";

import { boxStyle, configToast, modalStyle } from "../../constants";
import { StyledTableCell, StyledTableRow } from "../../shared/style";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../state/hook";
import { useEffect, useState } from "react";
import {
  deleteHealthPlaceByIdService,
  getHealthPlacesService,
} from "../../services/healthPlace.service";
import { useForm } from "react-hook-form";
import {
  getDistrictService,
  getProvinceService,
  getSubdistrictService,
} from "../../services/masterData.service";
import { toast } from "react-toastify";
import DeleteModal from "../../components/modals/DeleteModal";
import { setApp } from "../../state/libs/appSlice";
import { getItem } from "../../services/localStorage.service";
import {
  isAdmin,
  isAdminHealthPlace,
  isAdminZone,
} from "../../utils/authorize.util";

const contentToolbar = {
  toolbar: {
    title: "Health Place",
    subtitles: ["Health Place", "รายการ Health Place"],
  },
};

const HealthPlaceList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const accountProfile = getItem("account_profile");

  const { register, getValues, setValue } = useForm({
    defaultValues: {
      zone: !isAdmin() ? accountProfile.zone : "",
      province_id: isAdminHealthPlace() ? accountProfile.province_id : "",
      health_place_name: "",
      health_place_type: "",
      district_id: "",
      subdistrict_id: "",
    },
  });

  const [provinces, setProvinces] = useState<any[]>([]);
  const [provincesFittered, setProvincesFittered] = useState<any[]>([]);
  const [provinceId, setProvinceId] = useState<any>("");
  const [districtId, setDistrictId] = useState<any>("");
  const [subdistrictId, setSubdistrictId] = useState<any>("");
  const [districs, setDistrics] = useState([]);
  const [subDistrics, setSubDistrics] = useState([]);
  const [healthPlaces, setHealthPlaces] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [healthPlaceId, setHealthPlaceId] = useState<number | null>(null);

  const getHealthPlaces = () => {
    getHealthPlacesService({
      size: rowsPerPage,
      page: page + 1,
      ...getValues(),
    }).then((resp) => {
      setHealthPlaces(resp.data.items);
      setCount(resp.data.total_row);
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setProvinceId(event.target.value);
    setDistrictId("");
    setSubdistrictId("");

    getDistricts(event.target.value);

    setValue("district_id", "");
    setValue("subdistrict_id", "");

    setSubDistrics([]);
  };

  const handleDistrictChange = (event: SelectChangeEvent) => {
    setDistrictId(event.target.value);
    setSubdistrictId("");

    getSubDistricts(event.target.value);

    setValue("subdistrict_id", "");
  };

  const handleSubDistrictChange = (event: SelectChangeEvent) => {
    setSubdistrictId(event.target.value);
  };

  const handleZoneChange = (event: SelectChangeEvent) => {
    setProvinceId("");
    setDistrictId("");
    setSubdistrictId("");

    setValue("province_id", "");
    setValue("subdistrict_id", "");
    setValue("district_id", "");

    setDistrics([]);
    setSubDistrics([]);

    setProvincesFittered(
      !event.target.value
        ? provinces
        : provinces.filter((t: any) => t.zone === parseInt(event.target.value))
    );
  };

  const getProvinces = () => {
    getProvinceService().then((resp) => {
      setProvinces(resp.data);
      if (isAdminZone()) {
        setProvincesFittered(
          resp.data.filter((t: any) => t.zone === accountProfile.zone)
        );
      } else if (isAdminHealthPlace()) {
        setProvinceId(accountProfile.province_id);
        getDistricts(accountProfile.province_id);
        setProvincesFittered(resp.data);
      } else if (isAdmin()) {
        setProvincesFittered(resp.data);
      }
      setProvinces(resp.data);
    });
  };

  const getDistricts = (provinceId: string) => {
    getDistrictService(provinceId).then((resp) => setDistrics(resp.data));
  };

  const getSubDistricts = (districtId: string) => {
    getSubdistrictService(districtId).then((resp) => setSubDistrics(resp.data));
  };

  const handleOnSearch = () => {
    getHealthPlaces();
  };

  const handleWarningOpen = (id: number) => {
    setHealthPlaceId(id);
    setWarningOpen(true);
  };

  const handleWarningClose = () => {
    setHealthPlaceId(null);
    setWarningOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const id = healthPlaceId as number;
      handleWarningClose();
      await deleteHealthPlaceByIdService(id);
      getHealthPlaces();
      toast.success("ลบ Health Place สำเร็จ", configToast);
    } catch (error) {
      toast.error("ลบ Health Place ไม่สำเร็จ", configToast);
    }
  };

  const handleNavigateEditHealthPlace = (id: string) => {
    navigate(`/edit-health-place/${id}`);
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(setContentToolbar, [dispatch]);

  useEffect(getHealthPlaces, [getValues, page, rowsPerPage]);

  useEffect(getProvinces, []);

  return (
    <Box component="form" flexDirection="column" display="flex" gap="16px">
      <Box sx={boxStyle}>
        <Box sx={boxStyle}>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            gap="16px"
          >
            <Grid container spacing={4}>
              {(isAdmin() || isAdminZone()) && (
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="zone-label">เขตสุขภาพ</InputLabel>
                    <Select
                      defaultValue={isAdminZone() ? accountProfile.zone : ""}
                      disabled={isAdminZone()}
                      labelId="zone-label"
                      id="province"
                      label="เขตสุขภาพ"
                      {...register("zone")}
                      onChange={handleZoneChange}
                    >
                      <MenuItem key={0} value="">
                        ทั้งหมด
                      </MenuItem>
                      {Array.from(Array(13).keys()).map((index: any) => (
                        <MenuItem key={index} value={(index + 1).toString()}>
                          {"เขตสุขภาพที่ " + (index + 1)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="province-label">จังหวัด</InputLabel>
                  <Select
                    disabled={isAdminHealthPlace()}
                    labelId="province-label"
                    id="province"
                    label="จังหวัด"
                    {...register("province_id")}
                    value={provinceId}
                    onChange={handleProvinceChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {provincesFittered.map(
                      (p: { id: number; name: string }) => (
                        <MenuItem key={p.id} value={p.id.toString()}>
                          {p.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="district-label">อำเภอ</InputLabel>
                  <Select
                    labelId="district-label"
                    id="district"
                    label="อำเภอ"
                    {...register("district_id", { required: true })}
                    value={districtId}
                    onChange={handleDistrictChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {districs.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.id.toString()}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="subdistrict-label">ตำบล</InputLabel>
                  <Select
                    labelId="subdistrict-label"
                    id="subdistrict"
                    label="ตำบล"
                    {...register("subdistrict_id", { required: true })}
                    value={subdistrictId}
                    onChange={handleSubDistrictChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {subDistrics.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.id.toString()}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="health-place-type">ประเภทสถานที่</InputLabel>

                  <Select
                    fullWidth
                    labelId="health-place-type"
                    id="health_place_type"
                    label="ประเภทสถานที่"
                    {...register("health_place_type", { required: true })}
                  >
                    <MenuItem value="">ทั้งหมด</MenuItem>
                    <MenuItem value={1}>สวนสุขภาพ</MenuItem>
                    <MenuItem value={2}>สนามกีฬา</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    type="text"
                    placeholder="ค้นหาด้วยชื่อสถานที่"
                    {...register("health_place_name")}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Box display="flex" flexDirection="column" gap="8px">
              <Button
                variant="contained"
                startIcon={<SearchIcon sx={{ color: "white" }} />}
                color="green"
                onClick={handleOnSearch}
              >
                <Typography color="white">ค้นหา</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={boxStyle}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ชื่อสถานที่ออกกำลังกาย</StyledTableCell>
                <StyledTableCell>ประเภทสถานที่</StyledTableCell>
                <StyledTableCell>จังหวัด</StyledTableCell>
                <StyledTableCell>อำเภอ</StyledTableCell>
                <StyledTableCell>ตำบล</StyledTableCell>
                <StyledTableCell>ผู้ลงทะเบียน</StyledTableCell>
                <StyledTableCell>จัดการ</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {healthPlaces.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{row.name}</StyledTableCell>
                  <StyledTableCell>{row.place_type_name}</StyledTableCell>
                  <StyledTableCell>{row.province}</StyledTableCell>
                  <StyledTableCell>{row.district}</StyledTableCell>
                  <StyledTableCell>{row.subdistrict}</StyledTableCell>
                  <StyledTableCell>{row.registered_by}</StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex" gap="8px">
                      <Box
                        component="a"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleNavigateEditHealthPlace(row.id)}
                      >
                        <ModeEditIcon />
                      </Box>
                      <Box
                        component="a"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleWarningOpen(row.id)}
                      >
                        <DeleteIcon />
                      </Box>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!healthPlaces.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={7}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {/* modals */}
        <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
          <Box sx={modalStyle} width={340}>
            {healthPlaceId && (
              <DeleteModal
                handleConfirmDelete={handleConfirmDelete}
                handleWarningClose={handleWarningClose}
              />
            )}
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default HealthPlaceList;
