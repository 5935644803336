import axiosService from "./axios.service";

interface Filters {
  page: any;
  size: any;
  type_id?: any;
  community_code?: any;
}

export const getCommunitiesService = (params: Filters) => {
  return axiosService.get("/community?" + new URLSearchParams({ ...params }));
};

export const getCommunityByIdService = (id: any) => {
  return axiosService.get(`/community/${id}`);
};

export const getCommunityMemberService = (id: any) => {
  return axiosService.get(`/community/${id}/members`);
};

export const postCommunityUploadService = (id: any, formData: FormData) => {
  return axiosService.patch(`/community/upload/${id}`, formData);
};

export const patchCommunityByIdService = (id: any, body: any) => {
  return axiosService.patch(`/community/${id}`, body);
};

export const deleteCommunityService = (id: any) => {
  return axiosService.delete(`/community/${id}`);
};
