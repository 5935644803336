import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Button,
  debounce,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import th from "dayjs/locale/th";
import { toast } from "react-toastify";
import Resizer from "react-image-file-resizer";
import dayjs from "dayjs";

import { boxStyle, configToast } from "../../../constants";
import {
  getMemberService,
  Member,
  patchMemberService,
  postMemberAvatarUpload,
  validateFormatCitizenId,
  validateWeight,
  validateHeight,
  validateUsername,
} from "../../../services/member.service";
import { OverwriteAdapterDayjs } from "../../../utils/common.util";
import {
  getProvinceService,
  getDistrictService,
  getSubdistrictService,
  getPostcodeService,
} from "../../../services/masterData.service";

import CropImageModal from "../../../components/modals/CropImageModal";
import { getCroppedImg } from "../../../utils/cropper.util";
import { useAppDispatch } from "../../../state/hook";
import { setApp } from "../../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "จัดการกลุ่มผู้ใช้งาน",
    subtitles: ["ผู้ใช้งานหน้าบ้าน", "แก้ไขผู้ใช้งานหน้าบ้าน"],
  },
};

const EditMember = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // const accountProfile = getItem("account_profile");
  const readOnly = false;

  const [saving, setSaving] = useState<boolean>(false);
  const [member, setMember] = useState<any>(null);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [districs, setDistrics] = useState([]);
  const [subdistrict, setSubdistricts] = useState([]);
  const [postcodes, setPostcodes] = useState([]);
  const [openCropImage, setCropImageOpen] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const [image, setImage] = useState<any>();
  const [previewImage, setPreviewImage] = useState<any>();

  const inputRef = useRef<HTMLInputElement>(null);
  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    reset,
    getValues,
    setError,
    clearErrors,
    trigger,
    watch,
  } = useForm();
  const { isValid, errors } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const getProvinces = () => {
    getProvinceService().then((resp) => setProvinces(resp.data));
  };

  const getDistricts = async (provinceId: string) => {
    const response = await getDistrictService(provinceId);
    setDistrics(response.data);
  };

  const getSubdistricts = async (districtId: any) => {
    const response = await getSubdistrictService(districtId);
    setSubdistricts(response.data);
  };

  const getPostcodes = async (subdistrictId: any) => {
    const response = await getPostcodeService(subdistrictId);
    setPostcodes(response.data);
  };

  const getMember = () => {
    getMemberService(id).then((resp) => {
      setMember(resp.data);
    });
  };

  const setForm = () => {
    if (member) {
      getDistricts(member.current_province_id);
      getSubdistricts(member.current_district_id);
      getPostcodes(member.current_subdistrict_id);

      setValue("username", member.username);
      setValue("phone_no", member.phone_no);
      setValue("first_name", member.first_name);
      setValue("last_name", member.last_name);
      setValue("gender", member.gender);
      setValue("type_id", member.type_id);
      setValue("identification_number", member.identification_number);
      setValue("date_of_birth", member.date_of_birth);
      setValue("height", member.height);
      setValue("weight", member.weight);
      setValue("self_verification", member.self_verification);
      // address
      setValue("current_address", member.current_address);
      setValue("current_moo", member.current_moo);
      setValue("current_province_id", member.current_province_id);
      setValue("current_district_id", member.current_district_id);
      setValue("current_subdistrict_id", member.current_subdistrict_id);
      setValue("current_postcode", member.current_postcode);

      setDateOfBirth(member.date_of_birth);
      reset(getValues());

      verifyUsername(member.username);
      validateCitizenId(member.identification_number);
    }
  };

  const acceptCropAvatar = async (croppedAreaPixels: any) => {
    const { url, blob }: any = await getCroppedImg(image, croppedAreaPixels);

    const file = new File([blob], "name");
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      80,
      0,
      (file: any) => {
        setFile(file);
      },
      "file"
    );
    setPreviewImage(url);
    handleCropImageClose();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    if (file) {
      setImage(URL.createObjectURL(file));
      handleCropImageOpen();
    }
  };

  const handleCropImageOpen = () => setCropImageOpen(true);
  const handleCropImageClose = () => setCropImageOpen(false);

  const handleNavigateMemberList = () => {
    navigate("/member-list");
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setValue("current_district_id", "");
    setValue("current_subdistrict_id", "");
    setValue("current_postcode", "");

    setDistrics([]);
    setSubdistricts([]);
    setPostcodes([]);
    getDistricts(event.target.value);
  };

  const handleDistrictChange = (event: SelectChangeEvent) => {
    setValue("current_subdistrict_id", "");
    setValue("current_postcode", "");

    setSubdistricts([]);
    setPostcodes([]);
    getSubdistricts(event.target.value);
  };

  const handleSubdistrictsChange = (event: SelectChangeEvent) => {
    setValue("current_postcode", "");

    setPostcodes([]);
    getPostcodes(event.target.value);
  };

  const verifyUsername = async (event: any) => {
    const value = event?.target?.value || event;

    const { inValid, error } = await validateUsername(value, member.member_id);
    if (inValid) {
      setError("username", error);
      return;
    }

    clearErrors("username");
  };

  // const handlePhoneNoChange = async (event: any) => {
  //   const value = event.target.value;
  //   const { inValid, error } = await validatePhoneNo(value, member.member_id);
  //   if (inValid) {
  //     setError("phone_no", error);
  //     return;
  //   }

  //   clearErrors("phone_no");
  // };

  const validateCitizenId = async (event: any) => {
    const value = event?.target?.value || event;
    const { inValid, error } = await validateFormatCitizenId(
      value,
      member.member_id
    );
    if (inValid) {
      setError("identification_number", error);
      return;
    }

    clearErrors("identification_number");
  };

  const handleWeightChange = (event: any) => {
    const value = event.target.value;

    const { inValid, error } = validateWeight(value);
    if (inValid) {
      setError("weight", error);
      return;
    }

    clearErrors("weight");
  };

  const handleHeightChange = (event: any) => {
    const value = event.target.value;

    const { inValid, error } = validateHeight(value);
    if (inValid) {
      setError("height", error);
      return;
    }

    clearErrors("height");
  };

  const debouncedUsernameHandler = useMemo(
    () => debounce(verifyUsername, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [member]
  );

  const debouncedIdNumberHandler = useMemo(
    () => debounce(validateCitizenId, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [member]
  );

  // const debouncedPhoneNoHandler = useMemo(
  //   () => debounce(handlePhoneNoChange, 1000),
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [member]
  // );

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      setSaving(true);

      const body: Member = {
        ...values,
        height: parseInt(values.height),
        weight: parseInt(values.weight),
        current_subdistrict_id: parseInt(values.current_subdistrict_id),
        current_district_id: parseInt(values.current_district_id),
        current_province_id: parseInt(values.current_province_id),
        date_of_birth: dayjs(dateOfBirth).format("YYYY-MM-DD"),
        type_id: parseInt(values.type_id),
      };

      await patchMemberService(id, body);

      if (file) {
        const formdata = new FormData();
        formdata.append("file", file);
        postMemberAvatarUpload(id, formdata);
      }

      toast.success("แก้ไขผู้ใช้งานสำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateMemberList(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(resMessage || "แก้ไขผู้ใช้งานไม่สำเร็จ", configToast);
      setSaving(false);
    }
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setForm, [getValues, member, reset, setValue]);

  useEffect(getMember, [id]);

  useEffect(getProvinces, []);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      {member && (
        <>
          <Box
            display="flex"
            gap="16px"
            flexDirection={{ xs: "column-reverse", md: "row" }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmitHandler, onError)}
              sx={{ ...boxStyle, gap: "32px" }}
              width={{ xs: 1, md: "70%" }}
            >
              <Box display="flex" gap="32px">
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="ผู้ใช้งาน"
                    placeholder="กรอกผู้ใช้งาน"
                    error={!!errors["username"]}
                    helperText={
                      errors["username"]
                        ? (errors["username"].message as string)
                        : ""
                    }
                    {...register("username", {
                      required: true,
                      minLength: 6,
                      maxLength: 16,
                      onChange: debouncedUsernameHandler,
                    })}
                    onInput={(e: any) => {
                      e.target.value = e.target.value
                        .toString()
                        .replace(/[^A-Za-z0-9-_]/gi, "")
                        .slice(0, 16);
                    }}
                    inputProps={{
                      readOnly,
                    }}
                  />
                </FormControl>
              </Box>
              <Box display="flex" gap="32px">
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="เบอร์โทรศัพท์"
                    placeholder="กรอกเบอร์โทรศัพท์"
                    error={!!errors["phone_no"]}
                    helperText={
                      errors["phone_no"]
                        ? (errors["phone_no"].message as string)
                        : ""
                    }
                    {...register("phone_no", {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      // onChange: debouncedPhoneNoHandler,
                    })}
                    onInput={(e: any) => {
                      e.target.value = e.target.value
                        .toString()
                        .replace(/\D/g, "")
                        .slice(0, 10);
                    }}
                    inputProps={{
                      readOnly,
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="รหัสผ่าน"
                    placeholder="กรอกรหัสผ่าน"
                    {...register("password")}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toString().slice(0, 72);
                    }}
                    helperText="* รหัสผ่านหากไม่ต้องการเปลี่ยนไม่จำเป็นต้องกรอก"
                    inputProps={{
                      readOnly,
                    }}
                  />
                </FormControl>
              </Box>

              <Box display="flex" gap="32px">
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="ชื่อ"
                    placeholder="กรอกชื่อ"
                    {...register("first_name", { required: true })}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toString().slice(0, 100);
                    }}
                    inputProps={{
                      readOnly,
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="นามสกุล"
                    placeholder="กรอกนามสกุล"
                    {...register("last_name", { required: true })}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toString().slice(0, 100);
                    }}
                    inputProps={{
                      readOnly,
                    }}
                  />
                </FormControl>
              </Box>

              <Box display="flex" gap="32px">
                <FormControl fullWidth>
                  <InputLabel id="gender-label">เพศ</InputLabel>
                  <Select
                    defaultValue={member.gender}
                    labelId="gender-label"
                    id="gender"
                    label="เพศ"
                    {...register("gender", { required: true })}
                    size="medium"
                    inputProps={{
                      readOnly,
                    }}
                  >
                    <MenuItem value="M">ชาย</MenuItem>
                    <MenuItem value="F">หญิง</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="type_id-label">สถานะ</InputLabel>
                  <Select
                    defaultValue={member.type_id}
                    labelId="type_id-label"
                    id="type_id"
                    label="สถานะ"
                    {...register("type_id", { required: true })}
                    size="medium"
                    inputProps={{
                      readOnly,
                    }}
                  >
                    <MenuItem value={1}>ประชาชนทั่วไป</MenuItem>
                    <MenuItem value={2}>นักเรียน/นักศึกษา</MenuItem>
                    <MenuItem value={3}>อสม.</MenuItem>
                    <MenuItem value={4}>พนักงานภาคเอกชน</MenuItem>
                    <MenuItem value={5}>พนักงานภาครัฐฯ</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box display="flex" gap="32px">
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="เลขบัตรประชาชน"
                    placeholder="กรอกเลขบัตรประชาชน"
                    {...register("identification_number", {
                      required: true,
                      minLength: 13,
                      maxLength: 13,
                      onChange: debouncedIdNumberHandler,
                    })}
                    error={!!errors["identification_number"]}
                    helperText={
                      errors["identification_number"]
                        ? (errors["identification_number"].message as string)
                        : ""
                    }
                    onInput={(e: any) => {
                      e.target.value = e.target.value.toString().slice(0, 13);
                    }}
                    inputProps={{
                      readOnly,
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <LocalizationProvider
                    dateAdapter={OverwriteAdapterDayjs}
                    adapterLocale={th}
                  >
                    <DatePicker
                      label="วัน/เดือน/ปีเกิด"
                      disableFuture={true}
                      views={["year", "month", "day"]}
                      inputFormat="DD/MMM/BBBB"
                      value={dateOfBirth}
                      onChange={async (newValue: any) => {
                        const date = dayjs(newValue).format("YYYY-MM-DD");
                        setDateOfBirth(date);
                        setValue("date_of_birth", date);
                        await trigger("date_of_birth", { shouldFocus: true });
                      }}
                      disableOpenPicker={readOnly}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "วว/ดด/ปปปป",
                            readOnly: true,
                          }}
                          {...register("date_of_birth")}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>

              <Box display="flex" gap="32px">
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="น้ำหนัก"
                    error={!!errors["weight"]}
                    helperText={
                      errors["weight"]
                        ? (errors["weight"].message as string)
                        : ""
                    }
                    {...register("weight", {
                      required: true,
                      minLength: 0,
                      maxLength: 3,
                      onChange: handleWeightChange,
                    })}
                    onInput={(e: any) => {
                      e.target.value = e.target.value
                        .toString()
                        .replace(/\D/g, "")
                        .slice(0, 3);
                    }}
                    inputProps={{
                      readOnly,
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="ส่วนสูง"
                    error={!!errors["height"]}
                    helperText={
                      errors["height"]
                        ? (errors["height"].message as string)
                        : ""
                    }
                    {...register("height", {
                      required: true,
                      minLength: 0,
                      maxLength: 3,
                      onChange: handleHeightChange,
                    })}
                    onInput={(e: any) => {
                      e.target.value = e.target.value
                        .toString()
                        .replace(/\D/g, "")
                        .slice(0, 3);
                    }}
                    inputProps={{
                      readOnly,
                    }}
                  />
                </FormControl>
              </Box>

              <Box display="flex" flexDirection="column">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    แต้มสุขภาพ :
                  </Grid>
                  <Grid item xs={9}>
                    {member.health_point?.toLocaleString()}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    ค่าประสบการณ์ :
                  </Grid>
                  <Grid item xs={9}>
                    {member.experience_point?.toLocaleString()}
                  </Grid>
                </Grid>
              </Box>

              <FormControl>
                <FormLabel>ยืนยันตัวตน</FormLabel>

                <FormControlLabel
                  label="ยืนยันตัวตนแล้ว"
                  checked={member.self_verification}
                  control={
                    <Checkbox
                      {...register("self_verification")}
                      checked={watch("self_verification")}
                    />
                  }
                />
              </FormControl>

              <Typography typography="h5" color="cGrey.main">
                ที่อยู่ปัจจุบัน
              </Typography>

              <Box display="flex" gap="32px">
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="บ้านเลขที่/หมู่บ้าน/ซอย/ถนน"
                    {...register("current_address", { required: true })}
                    inputProps={{
                      readOnly,
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="หมู่ที่"
                    {...register("current_moo")}
                    inputProps={{
                      readOnly,
                    }}
                  />
                </FormControl>
              </Box>

              <Box display="flex" gap="32px">
                <FormControl fullWidth>
                  <InputLabel id="province-label">จังหวัด</InputLabel>
                  <Select
                    labelId="province-label"
                    id="province"
                    label="จังหวัด"
                    defaultValue={member.current_province_id}
                    {...register("current_province_id", { required: true })}
                    sx={{
                      "& .MuiSelect-select .notranslate::after":
                        "กรุณาเลือกจังหวัด"
                          ? {
                              content: `"กรุณาเลือกจังหวัด"`,
                              opacity: 0.42,
                            }
                          : {},
                    }}
                    onChange={handleProvinceChange}
                    inputProps={{
                      readOnly,
                    }}
                  >
                    {provinces.map((p: { id: number; name: string }) => (
                      <MenuItem key={p.id} value={p.id.toString()}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="district-label">อำเภอ/เขต</InputLabel>
                  <Select
                    labelId="district-label"
                    id="district"
                    label="อำเภอ/เขต"
                    defaultValue={member.current_district_id}
                    sx={{
                      "& .MuiSelect-select .notranslate::after":
                        "กรุณาเลือกอำเภอ"
                          ? {
                              content: `"กรุณาเลือกอำเภอ"`,
                              opacity: 0.42,
                            }
                          : {},
                    }}
                    {...register("current_district_id", { required: true })}
                    onChange={handleDistrictChange}
                    inputProps={{
                      readOnly,
                    }}
                  >
                    {districs.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.id.toString()}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box display="flex" gap="32px">
                <FormControl fullWidth>
                  <InputLabel id="subdistrict-label">ตำบล/แขวง</InputLabel>
                  <Select
                    labelId="subdistrict-label"
                    id="subdistrict"
                    label="ตำบล/แขวง"
                    defaultValue={member.current_subdistrict_id}
                    sx={{
                      "& .MuiSelect-select .notranslate::after":
                        "กรุณาเลือกตำบล"
                          ? {
                              content: `"กรุณาเลือกตำบล"`,
                              opacity: 0.42,
                            }
                          : {},
                    }}
                    {...register("current_subdistrict_id", { required: true })}
                    onChange={handleSubdistrictsChange}
                    inputProps={{
                      readOnly,
                    }}
                  >
                    {subdistrict.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.id.toString()}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="postcode-label">รหัสไปรษณีย์</InputLabel>
                  <Select
                    labelId="postcode-label"
                    id="postcode"
                    label="รหัสไปรษณีย์"
                    defaultValue={member.current_postcode}
                    sx={{
                      "& .MuiSelect-select .notranslate::after":
                        "กรุณาเลือกรหัสไปรษณีย์"
                          ? {
                              content: `"กรุณาเลือกรหัสไปรษณีย์"`,
                              opacity: 0.42,
                            }
                          : {},
                    }}
                    {...register("current_postcode", { required: true })}
                    inputProps={{
                      readOnly,
                    }}
                  >
                    {postcodes.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.name}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box display="flex" justifyContent="center" gap="16px">
                <Box>
                  <Button
                    variant="contained"
                    startIcon={<SaveIcon sx={{ color: "white" }} />}
                    color="green"
                    size="large"
                    type="submit"
                    disabled={
                      !isValid || Object.values(errors).length !== 0 || saving
                    }
                  >
                    <Typography color="white">บันทึก</Typography>
                  </Button>
                </Box>

                <Box>
                  <Button
                    variant="contained"
                    startIcon={<CancelIcon sx={{ color: "white" }} />}
                    color="red"
                    size="large"
                    type="button"
                    component={RouterLink}
                    to="/member-list"
                  >
                    <Typography color="white">ยกเลิก</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{ ...boxStyle, height: "fit-content" }}
              width={{ xs: 1, md: "30%" }}
            >
              <Typography color="cGrey.main">อัพโหลดรูปภาพ</Typography>

              <Box>
                <Button
                  variant="contained"
                  color="green"
                  size="large"
                  onClick={handleUploadClick}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    ref={inputRef}
                    onChange={handleFileChange}
                  />
                  <Typography color="white">UPLOAD FILE</Typography>
                </Button>
              </Box>

              <Box
                component="img"
                src={previewImage}
                sx={{
                  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                  objectFit: "contain",
                  width: 1,
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              ...boxStyle,
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DetailContainer title={`Level`} value={member?.experience_level} />
            <DetailContainer title={`Exp`} value={member?.experience_point} />
            <DetailContainer title={`การเผาผลาญรวม`} value={member?.calories} />
            <DetailContainer title={`ระยะทางรวม`} value={member?.distances} />
            <DetailContainer title={`ระยะเวลารวม`} value={member?.minutes} />
          </Box>
        </>
      )}

      <CropImageModal
        image={image}
        openCropImage={openCropImage}
        acceptCropAvatar={acceptCropAvatar}
        handleCropImageClose={handleCropImageClose}
      />
    </Box>
  );
};

const DetailContainer = ({ title, value }: any) => {
  return (
    <Box
      sx={{
        width: 150,
        height: 100,
        background: "orange",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography color="white">{title}</Typography>
      <Typography typography="h5" color="white">
        {value | 0}
      </Typography>
    </Box>
  );
};

export default EditMember;
