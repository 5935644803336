import axiosService from "./axios.service";

interface Filters {
  page: any;
  size: any;
}

export const getPdpaService = (params: Filters) => {
  return axiosService.get("/pdpa?" + new URLSearchParams({ ...params }));
};

export const getPdpaByIdService = (id: any) => {
  return axiosService.get(`/pdpa/${id}`);
};

export const patchPdpaService = (id: any, body: any) => {
  return axiosService.patch(`/pdpa/${id}`, body);
};
