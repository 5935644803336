import axiosService from "./axios.service";

interface Filters {
  page: any;
  size: any;
  username: any;
  email: any;
  phone_no: any;
}

export const getAdminsService = (params: Filters) => {
  return axiosService.get("/admin-user?" + new URLSearchParams({ ...params }));
};

export const getAdminByUsernameService = (username: any) => {
  return axiosService.get(`/admin-user/${username}`);
};

export const postAdminService = (body: any) => {
  return axiosService.post("/admin-user", body);
};

export const postVerifyUsername = (params: any) => {
  return axiosService.post(
    "/admin-user/verify-username?" + new URLSearchParams({ ...params })
  );
};

export const patchAdminService = (id: any, body: any) => {
  return axiosService.patch(`/admin-user/${id}`, body);
};

export const deleteAdminService = (id: any) => {
  return axiosService.delete(`/admin-user/${id}`);
};

export const postAdminTempAvatarUpload = (formData: FormData) => {
  return axiosService.post(`/admin-user/temp-avatar-upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postAdminAvatarUpload = (formData: FormData) => {
  return axiosService.post(`/admin-user/avatar-upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const validateUsername = async (value: any) => {
  if (value.length < 6 || value.length > 16)
    return {
      inValid: true,
      error: {
        type: "custom",
        message: "ชื่อผู้ใช้ต้องมีความยาว 6-16 ตัวอักษร",
      },
    };

  const resp = await postVerifyUsername({ username: value });

  if (!resp.data.is_available)
    return {
      inValid: true,
      error: {
        type: "custom",
        message: "ผู้ใช้งานนี้ มีอยู่ในระบบแล้ว",
      },
    };

  return {
    inValid: false,
    error: {},
  };
};
