import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Resizer from "react-image-file-resizer";

import { useAppDispatch } from "../../../state/hook";
import { setApp } from "../../../state/libs/appSlice";
import {
  boxStyle,
  configToast,
  roleTypes,
  statusUsers,
} from "../../../constants";
import {
  postAdminService,
  postAdminTempAvatarUpload,
  validateUsername,
} from "../../../services/admin.service";
import { getProvinceService } from "../../../services/masterData.service";
import { getCroppedImg } from "../../../utils/cropper.util";
import CropImageModal from "../../../components/modals/CropImageModal";

const contentToolbar = {
  toolbar: {
    title: "จัดการกลุ่มผู้ใช้งาน",
    subtitles: ["ผู้ใช้งานหลังบ้าน", "สร้างผู้ใช้งานหลังบ้าน"],
  },
};

const CreateAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [saving, setSaving] = useState<boolean>(false);
  const [provinces, setProvinces] = useState([]);
  const [provinceId, setProvinceId] = useState<any>("");
  const [zoneId, setZoneId] = useState<any>("");
  const [provincesFittered, setProvincesFittered] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [file, setFile] = useState<any>();
  const [image, setImage] = useState<any>();
  const [previewImage, setPreviewImage] = useState<any>();
  const [openCropImage, setCropImageOpen] = useState<boolean>(false);
  const [roleId, setRoleId] = useState<number | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const getProvinces = () => {
    getProvinceService().then((resp) => setProvinces(resp.data));
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleNavigateAdminList = () => {
    navigate("/admin-list");
  };

  const handleValidateConfirmPassword = (val: string) => {
    if (watch("password") !== val) {
      return "Your passwords do no match";
    }
  };

  const acceptCropAvatar = async (croppedAreaPixels: any) => {
    const { url, blob }: any = await getCroppedImg(image, croppedAreaPixels);

    const file = new File([blob], "name");
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      80,
      0,
      (file: any) => {
        setFile(file);
      },
      "file"
    );
    setPreviewImage(url);
    handleCropImageClose();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    if (file) {
      setImage(URL.createObjectURL(file));
      handleCropImageOpen();
    }
  };

  const handleCropImageOpen = () => setCropImageOpen(true);
  const handleCropImageClose = () => setCropImageOpen(false);

  const {
    register,
    handleSubmit,
    formState,
    watch,
    setError,
    clearErrors,
    setValue,
  } = useForm({
    mode: "all",
    defaultValues: {
      username: "",
      password: "",
      confirm_password: "",
      role_id: null,
      title: "",
      first_name: "",
      last_name: "",
      phone_no: "",
      email: "",
      zone: "",
      province_id: "",
      avatar_url: "",
      active_flag: null,
    },
  });

  const handleZoneChange = (event: SelectChangeEvent) => {
    setZoneId(event.target.value);
    setProvincesFittered(
      !event.target.value
        ? provinces
        : provinces.filter((t: any) => t.zone === parseInt(event.target.value))
    );
    setProvinceId("");
    setValue("province_id", "");
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setProvinceId(event.target.value);
  };

  const handleRoleChanged = (event: any) => {
    setRoleId(event.target.value);
    setValue("zone", "");
    setValue("province_id", "");

    setProvinceId("");
    setZoneId("");

    if (event.target.value === 2) setProvincesFittered(provinces);
  };

  const { isValid, isDirty, errors } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      setSaving(true);

      const {
        username,
        password,
        role_id,
        title,
        first_name,
        last_name,
        phone_no,
        email,
        province_id,
        zone,
        active_flag,
      } = values;

      let avatar_url = "";
      if (file) {
        const formdata = new FormData();
        formdata.append("file", file);
        const uploaded = await postAdminTempAvatarUpload(formdata);
        avatar_url = uploaded.data.file_url;
      }

      await postAdminService({
        username,
        password,
        role_id,
        title,
        first_name,
        last_name,
        phone_no,
        email,
        province_id,
        avatar_url,
        zone,
        active_flag: !active_flag ? true : false,
      });

      toast.success("สร้างผู้ใช้สำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateAdminList(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(resMessage || "สร้างผู้ใช้ไม่สำเร็จ", configToast);
      setSaving(false);
    }
  };

  const handleVerifyUsername = async (event: any) => {
    const value = event?.target?.value || event;

    const { inValid, error } = await validateUsername(value);
    if (inValid) {
      setError("username", error);
      return;
    }

    clearErrors("username");
  };

  const debouncedUsernameHandler = useMemo(
    () => debounce(handleVerifyUsername, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(setContentToolbar, [dispatch]);

  useEffect(getProvinces, []);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      display="flex"
      gap="16px"
      flexDirection={{ xs: "column-reverse", md: "row" }}
    >
      <Box
        sx={{ ...boxStyle, height: "fit-content" }}
        width={{ xs: 1, md: "60%" }}
      >
        <Typography typography="h5" color="cGrey.main">
          ข้อมูลผู้ใช้งานเบื้องต้น
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                type="text"
                label="ชื่อ"
                placeholder="กรอกชื่อ"
                {...register("first_name", { required: true })}
                size="medium"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                type="text"
                label="นามสกุล"
                placeholder="กรอกนามสกุล"
                {...register("last_name", { required: true })}
                size="medium"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="role-label">กลุ่มผู้ใช้งาน</InputLabel>
              <Select
                labelId="role-label"
                id="role-label"
                placeholder="เลือกกลุ่มผู้ใช้งาน"
                label="กลุ่มผู้ใช้งาน"
                {...register("role_id", { required: true })}
                size="medium"
                onChange={handleRoleChanged}
              >
                {roleTypes.map((row: any, index: number) => (
                  <MenuItem key={index} value={row.id}>
                    {row.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {(roleId === 2 || roleId === 4) && (
          <Grid container spacing={4}>
            {roleId === 4 && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="zone-label">เขตสุขภาพ</InputLabel>
                  <Select
                    labelId="zone-label"
                    id="province"
                    label="เขตสุขภาพ"
                    value={zoneId}
                    {...register("zone", { required: true })}
                    onChange={handleZoneChange}
                  >
                    {Array.from(Array(13).keys()).map((index: any) => (
                      <MenuItem key={index} value={(index + 1).toString()}>
                        {"เขตสุขภาพที่ " + (index + 1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {(roleId === 2 || roleId === 4) && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="province-label">จังหวัด</InputLabel>
                  <Select
                    labelId="province-label"
                    id="province"
                    label="จังหวัด"
                    value={provinceId}
                    {...register("province_id", { required: true })}
                    onChange={handleProvinceChange}
                  >
                    {provincesFittered.map(
                      (p: { id: number; name: string }) => (
                        <MenuItem key={p.id} value={p.id.toString()}>
                          {p.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        )}

        <Typography typography="h5" color="cGrey.main">
          ข้อมูลการเข้าใช้งานระบบ
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                type="text"
                label="ชื่อที่ใช้ในระบบ"
                placeholder="กรอกชื่อที่ใช้ในระบบ"
                {...register("username", {
                  required: true,
                  minLength: 6,
                  maxLength: 16,
                  onChange: debouncedUsernameHandler,
                })}
                size="medium"
                error={!!errors["username"]}
                helperText={
                  errors["username"]
                    ? (errors["username"].message as string)
                    : "* ชื่อที่ใช้แสดงบนระบบ example: ชื่อผู้สร้าง, แก้ไข เป็นต้น สามารถตั้งได้ครั้งเดียว"
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="status-label">สถานะการเข้าใช้งาน</InputLabel>
              <Select
                labelId="status-label"
                id="status-label"
                placeholder="สถานะการเข้าใช้งาน"
                label="สถานะการเข้าใช้งาน"
                {...register("active_flag", { required: true })}
                size="medium"
              >
                {statusUsers.map((str: any, index: number) => (
                  <MenuItem key={index} value={index}>
                    {str}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                label="รหัสผ่าน"
                placeholder="รหัสผ่าน"
                {...register("password", { required: true })}
                size="medium"
                helperText="* รหัสผ่านต้องมีอย่างน้อย 8 อักษรและประกอบไปด้วยตัวเลขเเละตัวอักษร"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                type={showConfirmPassword ? "text" : "password"}
                label="ยืนยันรหัสผ่าน"
                placeholder="ยืนยันรหัสผ่าน"
                {...register("confirm_password", {
                  required: true,
                  validate: handleValidateConfirmPassword,
                })}
                size="medium"
                error={!!errors["confirm_password"]}
                helperText={
                  errors["confirm_password"]
                    ? (errors["confirm_password"].message as string)
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Typography typography="h5" color="cGrey.main">
          ช่องทางที่ใช้ในการติดต่อ
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                type="text"
                label="อีเมล"
                placeholder="กรอกอีเมล"
                {...register("email", { required: true })}
                size="medium"
                helperText="* กรุณาระบุอีเมลที่ใช้ในการติดต่อได้"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                type="text"
                label="เบอร์โทรศัพท์"
                placeholder="กรอกเบอร์โทรศัพท์"
                {...register("phone_no")}
                size="medium"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" gap="16px">
          <Box>
            <Button
              variant="contained"
              startIcon={<SaveIcon sx={{ color: "white" }} />}
              color="green"
              size="large"
              type="submit"
              disabled={!isValid || !isDirty || saving}
            >
              <Typography color="white">บันทึก</Typography>
            </Button>
          </Box>

          <Box>
            <Button
              variant="contained"
              startIcon={<CancelIcon sx={{ color: "white" }} />}
              color="red"
              size="large"
              type="button"
              component={RouterLink}
              to="/admin-list"
            >
              <Typography color="white">ยกเลิก</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ ...boxStyle, height: "fit-content" }}
        width={{ xs: 1, md: "40%" }}
      >
        <Typography color="cGrey.main">อัพโหลดรูปภาพ</Typography>

        <Box>
          <Button
            variant="contained"
            color="green"
            size="large"
            onClick={handleUploadClick}
          >
            <input
              hidden
              accept="image/*"
              type="file"
              ref={inputRef}
              onChange={handleFileChange}
            />
            <Typography color="white">UPLOAD FILE</Typography>
          </Button>
        </Box>

        <Box
          component="img"
          src={previewImage}
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            objectFit: "contain",
            width: 1,
          }}
        />
      </Box>

      <CropImageModal
        image={image}
        openCropImage={openCropImage}
        acceptCropAvatar={acceptCropAvatar}
        handleCropImageClose={handleCropImageClose}
      />
    </Box>
  );
};

export default CreateAdmin;
