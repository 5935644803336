import {
  Box,
  Button,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { Login, authLoginService } from "../services/auth.service";
import { boxStyle, modalStyle } from "../constants";
import { useEffect, useState } from "react";
import { getItem, setItem } from "../services/localStorage.service";
import SuccessModal from "../components/modals/SuccessModal";
import ErrorModal from "../components/modals/ErrorModal";
import { useNavigate } from "react-router-dom";
import { isAdmin, isAdminZone } from "../utils/authorize.util";

const LoginPage = () => {
  const [openSuccess, setSuccesOpen] = useState<boolean>(false);
  const [openError, setErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSuccessOpen = () => setSuccesOpen(true);
  const handleSuccessClose = () => setSuccesOpen(false);
  const handleErrorOpen = () => setErrorOpen(true);
  const handleErrorClose = () => {
    setErrorMessage("");
    setErrorOpen(false);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const navigate = useNavigate();

  const { register, handleSubmit, formState } = useForm();
  const { isValid, isDirty } = formState;

  const onSubmitHandler: SubmitHandler<Login | any> = async (values) => {
    try {
      handleLoading();

      const loginResp = await authLoginService(values);

      setItem(
        "session",
        JSON.stringify({
          access_token: loginResp.data.access_token,
          refresh_token: loginResp.data.refresh_token,
        })
      );
      setItem("account_profile", JSON.stringify(loginResp.data));

      handleSuccessOpen();

      let url = "";
      setTimeout(() => {
        handleSuccessClose();
        if (!isAdmin() && !isAdminZone()) {
          url = "/health-place-list";
        } else {
          url = "/report/registered-member";
        }
        navigate(url);
      }, 1000);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setErrorMessage(resMessage);
      handleLoaded();
    }
  };

  useEffect(() => {
    if (errorMessage) handleErrorOpen();
  }, [errorMessage]);

  useEffect(() => {
    const session = getItem("session");
    if (session) navigate("/report/registered-member");
  }, [navigate]);

  return (
    <Box
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ background: "#E8EBF0" }}
    >
      <Box sx={boxStyle} width="400px">
        <Box
          alignSelf="center"
          component="img"
          alt="logo"
          src="/icons/logo.png"
          width={75}
          height={100}
        />

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          display="flex"
          flexDirection="column"
          gap="16px"
        >
          <TextField
            fullWidth
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PermIdentityOutlinedIcon />
                </InputAdornment>
              ),
            }}
            {...register("username", {
              required: true,
              minLength: 5,
              maxLength: 16,
            })}
          />

          <TextField
            fullWidth
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
            {...register("password", { required: true, minLength: 6 })}
          />

          <Button
            color="green"
            variant="contained"
            type="submit"
            disabled={!isDirty || !isValid || loading}
          >
            <Typography variant="subtitle2" color="white">
              เข้าสู่ระบบ
            </Typography>
          </Button>
        </Box>
      </Box>

      <Modal keepMounted open={openSuccess} onClose={handleSuccessClose}>
        <Box sx={modalStyle} width="340px">
          <SuccessModal
            message={"เข้าสู่ระบบสำเร็จ"}
            handleSuccessClose={handleSuccessClose}
          />
        </Box>
      </Modal>

      <Modal keepMounted open={openError} onClose={handleErrorClose}>
        <Box sx={modalStyle}>
          <ErrorModal
            message={errorMessage}
            handleErrorClose={handleErrorClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default LoginPage;
