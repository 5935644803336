import axiosService from "./axios.service";

interface Filters {
  page: any;
  size: any;
}

interface Rewards {}

export const getRewardsService = (params: Filters) => {
  return axiosService.get("/reward?" + new URLSearchParams({ ...params }));
};

export const getRewardService = (id: any) => {
  return axiosService.get(`/reward/${id}`);
};

export const getRewardReportService = (id: any) => {
  return axiosService.get(`/reward/${id}/report`, {
    responseType: "blob",
  });
};

export const postRewardService = (body: Rewards) => {
  return axiosService.post("/reward", body);
};

export const postRewardUploadService = (id: any, formData: FormData) => {
  return axiosService.post(`/reward/reward-upload/${id}`, formData);
};

export const patchRewardService = (id: any, body: Rewards) => {
  return axiosService.patch(`/reward/${id}`, body);
};

export const postRewardImportCodesService = (id: any, body: any) => {
  return axiosService.post(`/reward/${id}/import-codes`, body);
};
