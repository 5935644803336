import { createSlice } from "@reduxjs/toolkit";

interface AppState {
  toolbar: {
    title: string;
    subtitles: [];
  };
}

export const appSlice = createSlice({
  name: "app",
  initialState: {} as AppState,
  reducers: {
    setApp: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const getApp = (state: any) => state.app;

export const { setApp } = appSlice.actions;

export default appSlice.reducer;
