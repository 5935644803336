import { getItem } from "../services/localStorage.service";

export const isAdmin = () => {
  const accountProfile = getItem("account_profile");

  return (0b001 & accountProfile.role_id.toString(2)) > 0;
};

export const isAdminHealthPlace = () => {
  const accountProfile = getItem("account_profile");

  return (0b010 & accountProfile.role_id.toString(2)) > 0;
};

export const isAdminZone = () => {
  const accountProfile = getItem("account_profile");

  return (0b100 & accountProfile.role_id.toString(2)) > 0;
};
