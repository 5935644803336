import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-classic";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import th from "dayjs/locale/th";
import { toast } from "react-toastify";

import { OverwriteAdapterDayjs } from "../../utils/common.util";
import { boxStyle, configToast } from "../../constants";
import {
  getPdpaByIdService,
  patchPdpaService,
} from "../../services/pdpa.service";
import { useAppDispatch } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "จัดการคำยินยอม",
    subtitles: ["รายการคำยินยอม", "แก้ไขคำยินยอม"],
  },
};

const EditPdpa = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [pdpa, setPdpa] = useState<any>(null);
  const [activeDate, setActiveDate] = useState<string | null>(null);
  const [saving, setSaving] = useState<boolean>(false);

  const { register, handleSubmit, formState, setValue, trigger } = useForm();
  const { isValid } = formState;

  const handleNavigatePdapList = () => {
    navigate("/pdpa-list");
  };

  const handleEditorPdpaChange = (event: any, editor: any) => {
    const data = editor.getData();
    setValue("pdpa", data);
  };

  const getPdpa = () => {
    getPdpaByIdService(id).then((resp) => {
      setPdpa(resp.data);
    });
  };

  const setForm = () => {
    if (pdpa) {
      setValue("pdpa", pdpa.pdpa);

      const activeDate = dayjs(pdpa.active_date).format("YYYY-MM-DD");
      setValue("active_date", pdpa.active_date);
      setActiveDate(activeDate);
      trigger();
    }
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      setSaving(true);
      await patchPdpaService(id, {
        ...values,
        active_date: activeDate,
      });

      toast.success("แก้ไขคำยินยอมสำเร็จ", {
        ...configToast,
        onClose: () => handleNavigatePdapList(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "แก้ไขคำยินยอมไม่สำเร็จ", configToast);
      setSaving(false);
    }
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(setForm, [pdpa, setValue, trigger]);

  useEffect(getPdpa, [id]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler)}
      display="flex"
      gap="16px"
    >
      {pdpa && (
        <Box sx={{ ...boxStyle }} width={{ xs: 1, md: "60%" }}>
          <FormControl>
            <LocalizationProvider
              dateAdapter={OverwriteAdapterDayjs}
              adapterLocale={th}
            >
              <DatePicker
                label="วันเริ่มต้น เผยแพร่"
                views={["year", "month", "day"]}
                inputFormat="DD/MMM/BBBB"
                value={activeDate}
                onChange={async (newValue: any) => {
                  const date = dayjs(newValue).format("YYYY-MM-DD");
                  await trigger("active_date", { shouldFocus: true });
                  setActiveDate(date);
                  setValue("active_date", date);
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "วว/ดด/ปปปป",
                      readOnly: true,
                    }}
                    {...register("active_date", { required: true })}
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl>
            <CKEditor
              data={pdpa.pdpa}
              editor={Editor}
              config={{
                placeholder: "รายละเอียด",
              }}
              onChange={handleEditorPdpaChange}
              onReady={(editor: any) => {
                editor.editing.view.change((writer: any) => {
                  writer.setStyle(
                    "height",
                    "500px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
            />
          </FormControl>

          <Box display="flex" justifyContent="center" gap="16px">
            <Box>
              <Button
                variant="contained"
                startIcon={<SaveIcon sx={{ color: "white" }} />}
                color="green"
                size="large"
                type="submit"
                disabled={!isValid || saving}
              >
                <Typography color="white">บันทึก</Typography>
              </Button>
            </Box>

            <Box>
              <Button
                variant="contained"
                startIcon={<CancelIcon sx={{ color: "white" }} />}
                color="red"
                size="large"
                type="button"
                component={RouterLink}
                to="/pdpa-list"
              >
                <Typography color="white">ยกเลิก</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EditPdpa;
