import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useState } from "react";

const DeleteModal = ({
  handleConfirmDelete,
  handleWarningClose,
  confirm = false,
}: any) => {
  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = () => {
    setChecked(!checked);
  };
  return (
    <Box display="flex" flexDirection="column" p="48px" gap="16px">
      <Typography variant="h5">ยืนยันการลบข้อมูล</Typography>

      <Typography variant="subtitle1" color="cGrey.main">
        คุณต้องการลบข้อมูลใช่หรือไม่
      </Typography>

      <Box>
        {confirm && (
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label="ยืนยันการลบผู้ใช้งาน"
          />
        )}
      </Box>

      <Box display="flex" justifyContent="end" gap="8px">
        <Button
          color="red"
          variant="outlined"
          type="button"
          onClick={handleWarningClose}
        >
          <Typography variant="subtitle2">ยกเลิก</Typography>
        </Button>

        <Button
          color="green"
          variant="outlined"
          type="button"
          onClick={handleConfirmDelete}
          disabled={confirm && !checked}
        >
          <Typography variant="subtitle2">ยืนยัน</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteModal;
