import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { SubmitHandler, useForm } from "react-hook-form";

import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Resizer from "react-image-file-resizer";

import {
  Banner,
  postBannerService,
  postBannerUploadService,
} from "../../services/banner.service";
import { boxStyle, configToast } from "../../constants";
import { useAppDispatch } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "การจัดการแบนเนอร์",
    subtitles: ["รายการแบนเนอร์", "สร้างแบนเนอร์"],
  },
};

const CreateBanner = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [bannerFile, setBannerFile] = useState<File | null>(null);
  const [fileDataURL, setFileDataURL] = useState<string>("");
  const [saving, setSaving] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { register, handleSubmit, formState } = useForm({
    defaultValues: { redirect_url: "", banner_url: "", is_publish: null },
  });
  const { isValid, isDirty } = formState;

  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      100,
      0,
      (file: any) => {
        setBannerFile(file);
      },
      "file"
    );
  };

  const handleNavigateBannerList = () => {
    navigate("/banner-list");
  };

  const onSubmitHandler: SubmitHandler<Banner | any> = async (values) => {
    try {
      setSaving(true);
      const resp = await postBannerService({
        ...values,
        is_publish: values.is_publish ? true : false,
        banner_url: bannerFile?.name,
      });

      const formdata = new FormData();
      formdata.append("file", bannerFile as File);
      await postBannerUploadService(resp.data, formdata);
      toast.success("สร้างแบนเนอร์สำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateBannerList(),
      });
    } catch (error) {
      toast.error("สร้างแบนเนอร์ไม่สำเร็จ", configToast);
      setSaving(false);
    }
  };

  useEffect(() => {
    let fileReader: any,
      isCancel = false;
    if (bannerFile) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(bannerFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [bannerFile]);

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box display="flex" gap="16px">
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
        sx={{ ...boxStyle, gap: "32px" }}
        width="70%"
      >
        <FormControl>
          <TextField
            fullWidth
            type="text"
            label="Link"
            placeholder="กรอก Link"
            {...register("redirect_url", { required: true })}
            size="medium"
          />
        </FormControl>

        <FormControl>
          <InputLabel id="status-label">สถานะ</InputLabel>
          <Select
            labelId="status-label"
            id="status-label"
            placeholder="สถานะ"
            label="สถานะ"
            {...register("is_publish", { required: true })}
            size="medium"
          >
            <MenuItem value={1}>เผยแพร่</MenuItem>
            <MenuItem value={0}>ไม่เผยแพร่</MenuItem>
          </Select>
        </FormControl>

        <Box display="flex" justifyContent="center" gap="16px">
          <Box>
            <Button
              variant="contained"
              startIcon={<SaveIcon sx={{ color: "white" }} />}
              color="green"
              size="large"
              type="submit"
              disabled={!isValid || !isDirty || !bannerFile || saving}
            >
              <Typography color="white">บันทึก</Typography>
            </Button>
          </Box>

          <Box>
            <Button
              variant="contained"
              startIcon={<CancelIcon sx={{ color: "white" }} />}
              color="red"
              size="large"
              type="button"
              component={RouterLink}
              to="/banner-list"
            >
              <Typography color="white">ยกเลิก</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={{ ...boxStyle, height: "fit-content" }} width="30%">
        <Typography color="cGrey.main">อัพโหลดรูปภาพ</Typography>
        <Box>
          <Button
            variant="contained"
            color="green"
            size="large"
            onClick={handleUploadClick}
          >
            <input
              hidden
              accept="image/*"
              type="file"
              ref={inputRef}
              onChange={handleFileChange}
            />
            <Typography color="white">UPLOAD FILE</Typography>
          </Button>
        </Box>

        <Box
          component="img"
          src={fileDataURL}
          sx={{
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
            objectFit: "contain",
            width: 1,
          }}
        />
      </Box>
    </Box>
  );
};

export default CreateBanner;
