import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-classic";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { patchQuestionaireQuestionsService } from "../../../services/questionaire.service";
import { configToast, questionTypes, statusTypes } from "../../../constants";

const EditQuestionDialog = ({ question, handleClose }: any) => {
  const [questionType, setQuestionType] = useState<any>(null);
  const [saving, setSaving] = useState<boolean>(false);

  const { register, handleSubmit, control, formState, setValue, trigger } =
    useForm({
      mode: "all",
      defaultValues: {
        questionaire_id: question.questionaire_id,
        question_type: question.question_type,
        question: question.question,
        question_order: question.question_order,
        answer_option: {
          minimum: null,
          maximum: null,
        },
        is_active: question.is_active ? 0 : 1,
        remark: "",
        answers: [
          {
            answer_order: 1,
            answer: "",
            next_question: null,
          },
        ],
      },
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "answers",
  });

  const { isValid } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const onAddAnswer = () => {
    append({
      answer_order: fields.length + 1,
      answer: "",
      next_question: null,
    });
  };

  const handleQuestionTypeChange = (event: any) => {
    setValue("answers", [
      {
        answer_order: 1,
        answer: "",
        next_question: null,
      },
    ]);
    setValue("answer_option", {
      minimum: null,
      maximum: null,
    });
    setQuestionType(event.target.value);
  };

  const setForm = () => {
    if (question) {
      if (question.question_type === 3) {
        setValue("answer_option", question.answer_options);
      } else if (question.question_type === 4) {
        setValue("answers", question.answer_options.answers);
      }
      setQuestionType(question.question_type);
      trigger();
    }
  };

  const handleEditorQuestionChange = (event: any, editor: any) => {
    const data = editor.getData();
    setValue("question", data);
  };

  const getContentAnswerOptions = (type: any) => {
    switch (type) {
      case 3:
        return (
          <Box display="flex" justifyContent="space-between" gap="16px">
            <FormControl fullWidth>
              <Controller
                key="answer-option-minimum"
                name="answer_option.minimum"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    type="number"
                    label="ค่าต่ำสุด"
                    value={value}
                    onChange={onChange}
                    placeholder="กรอกค่าต่ำสุด"
                    size="medium"
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <Controller
                key="answer-option-maximum"
                name="answer_option.maximum"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    type="number"
                    label="ค่าสูงสุด"
                    value={value}
                    onChange={onChange}
                    placeholder="กรอกค่าสูงสุด"
                    size="medium"
                  />
                )}
              />
            </FormControl>
          </Box>
        );

      case 4:
        return (
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography typography="body1" color="cGrey.main">
              ตัวเลือก
            </Typography>

            {fields.map((question: any, index) => (
              <Box display="flex" gap="8px">
                <FormControl key={question.id} fullWidth>
                  <TextField
                    type="text"
                    label={`ตัวเลือกที่ ${index + 1}`}
                    placeholder="กรอกตัวเลือก"
                    size="small"
                    {...register(`answers.${index}.answer`, {
                      required: true,
                    })}
                  />
                </FormControl>

                <FormControl key={question.id}>
                  <TextField
                    type="number"
                    label={`คำถามถัดไป`}
                    placeholder="กรอกคำถามถัดไป"
                    size="small"
                    {...register(`answers.${index}.next_question`)}
                  />
                </FormControl>

                <Box
                  display="flex"
                  alignItems="center"
                  component="a"
                  sx={{ cursor: "pointer" }}
                  onClick={() => remove(index)}
                >
                  <RemoveCircleOutlineIcon sx={{ color: "red.main" }} />
                </Box>
              </Box>
            ))}

            <Box display="flex" justifyContent="end">
              <Typography
                typography="body1"
                color="primary.main"
                sx={{ cursor: "pointer" }}
                onClick={onAddAnswer}
              >
                เพิ่มตัวเลือก
              </Typography>
            </Box>
          </Box>
        );

      default:
        break;
    }
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      setSaving(true);

      let answer_options = null;

      if (values.question_type === 3) {
        answer_options = values.answer_option;
      } else if (values.question_type === 4) {
        answer_options = { answers: values.answers };
      }

      await patchQuestionaireQuestionsService(question.id, {
        questionaire_id: values.questionaire_id,
        question_order: values.question_order,
        question_type: values.question_type,
        question: values.question,
        remark: values.remark,
        answer_options,
        is_active: !values.is_active ? true : false,
      });

      handleClose(true);
      toast.success("แก้ไขคำถามสำเร็จ", configToast);
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "แก้ไขคำถามไม่สำเร็จ", configToast);
      setSaving(false);
    }
  };

  useEffect(setForm, [question, setValue, trigger]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmitHandler, onError)}>
      <DialogTitle>
        <Typography variant="h5">แก้ไขคำถาม</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column" gap="16px">
          <FormControl fullWidth>
            <CKEditor
              data={question.question}
              editor={Editor}
              config={{ placeholder: "คำถาม" }}
              onChange={handleEditorQuestionChange}
            />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="question-type">ประเภทคำถาม</InputLabel>
            <Select
              defaultValue={question.question_type}
              labelId="question-type"
              id="question-type"
              placeholder="เลือกประเภทคำถาม"
              label="ประเภทคำถาม"
              {...register("question_type", { required: true })}
              size="medium"
              onChange={handleQuestionTypeChange}
            >
              {questionTypes.map((type: any, index: number) => (
                <MenuItem key={index} value={index}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {getContentAnswerOptions(questionType)}

          <FormControl fullWidth>
            <InputLabel id="status-label">สถานะ</InputLabel>
            <Select
              defaultValue={question.is_active ? 0 : 1}
              labelId="status-label"
              id="status-label"
              placeholder="เลือกสถานะ"
              label="สถานะ"
              {...register("is_active", { required: true })}
              size="medium"
            >
              {statusTypes.map((status: string, index: number) => (
                <MenuItem key={index} value={index}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <TextField
              type="text"
              label="Remark"
              placeholder="กรอกRemark"
              {...register("remark")}
              size="medium"
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="red"
          variant="outlined"
          type="button"
          onClick={handleClose}
        >
          <Typography variant="subtitle2">ยกเลิก</Typography>
        </Button>

        <Button
          color="green"
          variant="outlined"
          type="submit"
          disabled={!isValid || saving}
        >
          <Typography variant="subtitle2">ยืนยัน</Typography>
        </Button>
      </DialogActions>
    </Box>
  );
};

export default EditQuestionDialog;
