import axiosService from "./axios.service";

export const getProvinceService = () => {
  return axiosService.get("/master-data/province");
};

export const getDistrictService = (provinceId: string) => {
  return axiosService.get(
    "/master-data/district?" +
      new URLSearchParams({
        province_id: provinceId,
      })
  );
};

export const getSubdistrictService = (districtId: string) => {
  return axiosService.get(
    "/master-data/subdistrict?" +
      new URLSearchParams({
        district_id: districtId,
      })
  );
};

export const getPostcodeService = (subdistrictId: string) => {
  return axiosService.get(
    "/master-data/postcode?" +
      new URLSearchParams({
        subdistrict_id: subdistrictId,
      })
  );
};

export const getActivityTypesService = () => {
  return axiosService.get("/master-data/activity-type");
};
