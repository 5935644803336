import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import CancelIcon from "@mui/icons-material/Cancel";

const ViewEvaluation = ({ evaluation, handleSubmit }: any) => {
  return (
    <>
      <DialogTitle id="alert-dialog-title">
        <Typography align="center" typography="h5">
          คำตอบแบบประเมิน
        </Typography>
        <Typography align="center" typography="body1">
          {evaluation.title}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ minWidth: 300 }}>
        <Typography typography="body1">{evaluation.answer || "-"}</Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="contained"
          startIcon={<PlaylistAddCheckIcon sx={{ color: "white" }} />}
          color="green"
          size="large"
          type="button"
          onClick={() => handleSubmit(true)}
        >
          <Typography color="white">ผ่าน</Typography>
        </Button>

        <Button
          variant="contained"
          startIcon={<CancelIcon sx={{ color: "white" }} />}
          color="red"
          size="large"
          type="button"
          onClick={() => handleSubmit(false)}
        >
          <Typography color="white">ไม่ผ่าน</Typography>
        </Button>
      </DialogActions>
    </>
  );
};

export default ViewEvaluation;
