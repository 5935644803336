import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../state/hook";
import {
  getTask,
  updateTaskById,
  removeTaskById,
} from "../state/libs/taskSlice";

import saveAs from "file-saver";
import {
  getDownloadReport,
  getGenerateReport,
  getStatusJobReport,
} from "./report.service";

const taskConfigs: any = {
  memberInfoReport: {
    url: "/member/report",
    fileName: "member-info",
  },
  activityReport: {
    url: "/member/report/activity",
    fileName: "member-activity-info",
  },
  bmiReport: {
    url: "/member/report/bmi",
    fileName: "member-bmi-info",
  },
  healthPlaceReport: {
    url: "/hp/report/job",
    fileName: "health-place-info",
  },
  registeredMemberReport: {
    url: "/event/report/event-member/job/${id}",
    fileName: "event-registered-member",
  },
  eventMemberActivityReport: {
    url: "/event/report/event-member/job/${id}",
    fileName: "event-member-activity",
  },
  courseReport: {
    url: "/course/${id}/report",
    fileName: "course-registered-member",
  },
  questionaireReport: {
    url: "/questionaire/${id}/report",
    fileName: "questionaire-answer",
  },
  activitySummaryReport: {
    url: "/report/activity-summary/excel",
    fileName: "activity-summary",
  },
  activityTypeSummaryReport: {
    url: "/report/activity-type-summary/excel",
    fileName: "activity-type-summary",
  },
  communitySummaryReport: {
    url: "/report/community-info/excel",
    fileName: "community-summary",
  },
  dpacExcelReport: {
    url: "/course/1/summary/report/excel",
    fileName: "course-summary",
  },
  registeredMemberExcelReport: {
    url: "/report/registered_member-summary/excel",
    fileName: "registered-member-summary",
  },
  rewardReport: {
    url: "/reward/${id}/report",
    fileName: "reward-reserved-member",
  },
  memberActivityHistoryReport: {
    url: "/report/member-activity-history/csv",
    fileName: "member-activity-history",
  },
  memberActivitySummaryReport: {
    url: "/report/member-activity-summary/csv",
    fileName: "member-activity-summary",
  },
};

export const useBackgroundService = () => {
  const taskState = useAppSelector(getTask);
  const dispatch = useAppDispatch();
  const [ipAddress, setIPAddress] = useState("");

  const getIPAddress = () => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip));
  };

  useEffect(() => {
    for (const task of taskState.tasks) {
      // task.id report name
      if (task.process) {
        if (!task.processing) {
          dispatch(
            updateTaskById({ id: task.id, updatedTask: { processing: true } })
          );

          const taskConfig = taskConfigs[task.reportName];

          let urlPath = taskConfig.url;
          if (task.id !== task.reportName) {
            const id = task.id.replace(task.reportName, "");
            urlPath = urlPath.replace("${id}", id);
          }

          getGenerateReport(urlPath, {
            ...task.filters,
            fe_ip: ipAddress,
          }).then((resp) => {
            const fileId = resp.data.id;

            const backgroundTask = async () => {
              // Perform background task
              const response = await getStatusJobReport(fileId);

              if (response.data.status) {
                getDownloadReport(fileId).then((target) => {
                  const file = new Blob([target.data], {
                    type: "application/zip",
                  });
                  saveAs(
                    file,
                    taskConfig.fileName +
                      (task.extraFileName ? `-${task.extraFileName}` : "")
                  );

                  dispatch(
                    removeTaskById({
                      id: task.id,
                    })
                  );

                  clearInterval(intervalId);
                });
              }
            };

            const intervalId = setInterval(() => {
              backgroundTask();
            }, 10000);
          });
        }
      }
    }
  }, [dispatch, taskState]);

  useEffect(getIPAddress, []);

  return;
};
