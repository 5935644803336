import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import SaveIcon from "@mui/icons-material/Save";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { toast } from "react-toastify";

import { boxStyle, configToast, modalStyle } from "../../constants";
import {
  getBannersService,
  deleteBannerService,
} from "../../services/banner.service";
import DeleteModal from "../../components/modals/DeleteModal";

import { useAppDispatch } from "../../state/hook";
import { StyledTableCell, StyledTableRow } from "../../shared/style";
import { setApp } from "../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "การจัดการแบนเนอร์",
    subtitles: ["รายการแบนเนอร์"],
  },
};

const BannerList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [banners, setBanners] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [bannerId, setBannerId] = useState<number | null>(null);

  const handleWarningOpen = (id: number) => {
    setBannerId(id);
    setWarningOpen(true);
  };
  const handleWarningClose = () => {
    setBannerId(null);
    setWarningOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleConfirmDelete = async () => {
    try {
      const id = bannerId as number;
      handleWarningClose();
      await deleteBannerService(id);
      getBanners();
      toast.success("ลบแบนเนอร์สำเร็จ", configToast);
    } catch (error) {
      toast.error("ลบแบนเนอร์ไม่สำเร็จ", configToast);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigateEditBanner = (bannerId: string) => {
    navigate(`/edit-banner/${bannerId}`);
  };

  const getBanners = () => {
    getBannersService({ size: rowsPerPage, page: page + 1 }).then((resp) => {
      setBanners(resp.data.items);
      setCount(resp.data.total_row);
    });
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(getBanners, [page, rowsPerPage]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box sx={boxStyle}>
      <Box>
        <Button
          color="green"
          variant="contained"
          startIcon={<SaveIcon sx={{ color: "white" }} />}
          component={RouterLink}
          to="/create-banner"
        >
          <Typography color="white">สร้าง</Typography>
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", borderRadius: 0 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>ลำดับ</StyledTableCell>
              <StyledTableCell>Link</StyledTableCell>
              <StyledTableCell>วันที่สร้าง</StyledTableCell>
              <StyledTableCell>การเผยแพร่</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {banners.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row">
                  {row.id}
                </StyledTableCell>
                <StyledTableCell>{row.redirect_url}</StyledTableCell>
                <StyledTableCell>
                  {row.created_date
                    ? dayjs(row.created_date).locale("th").format("DD MMM BBBB")
                    : "-"}
                </StyledTableCell>
                <StyledTableCell>
                  {row.is_publish ? "เผยแพร่" : "ไม่เผยแพร่"}
                </StyledTableCell>
                <StyledTableCell>
                  <Box display="flex" gap="8px">
                    <Box
                      component="a"
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleNavigateEditBanner(row.id)}
                    >
                      <ModeEditIcon />
                    </Box>
                    <Box
                      component="a"
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleWarningOpen(row.id)}
                    >
                      <DeleteIcon />
                    </Box>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
            {!banners.length && (
              <StyledTableRow>
                <StyledTableCell
                  component="th"
                  scope="row"
                  colSpan={5}
                  align="center"
                >
                  ไม่พบข้อมูล
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* modals */}
      <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
        <Box sx={modalStyle} width={340}>
          <DeleteModal
            handleConfirmDelete={handleConfirmDelete}
            handleWarningClose={handleWarningClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default BannerList;
