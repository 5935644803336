import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-classic";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  postLessonService,
  postLessonUploadService,
} from "../../../services/course.service";
import { boxStyle, configToast } from "../../../constants";
import { useAppDispatch } from "../../../state/hook";
import { setApp } from "../../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "การจัดการหลักสูตร",
    subtitles: ["รายการหลักสูตร", "แก้ไขหลักสูตร", "สร้างบทเรียน"],
  },
};

const CreateLesson = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { courseId } = useParams();

  const [lessonFile, setLessonFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | any>(null);

  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setLessonFile(file);
    inputRef.current.value = "";
  };
  const handleNavigateEditCourse = () => {
    navigate(`/edit-course/${courseId}`);
  };
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const { register, handleSubmit, formState, setValue } = useForm({
    defaultValues: {
      title: "",
      detail: "",
      minutes: 0,
      second: 0,
      minutes_minimum: 0,
      second_minimum: 0,
      passed_criteria: null,
      clip_url: "",
      lesson_order: null,
      gap_time: null,
    },
  });

  const { isValid, isDirty } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const editorConfig = { placeholder: "รายละเอียด" };

  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData();
    setValue("detail", data);
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();
      const {
        title,
        detail,
        minutes,
        second,
        minutes_minimum,
        second_minimum,
        passed_criteria,
        clip_url,
        lesson_order,
        gap_time,
      } = values;

      const created = await postLessonService(courseId, {
        title,
        detail,
        minimum_learning_time: minutes_minimum * 60 + parseInt(second_minimum),
        learning_time: minutes * 60 + parseInt(second),
        passed_criteria,
        clip_url,
        lesson_order,
        gap_time,
        course_id: courseId,
      });

      const formdata = new FormData();
      formdata.append("file", lessonFile as File);
      postLessonUploadService(created.data, formdata);

      toast.success("สร้างบทเรียนสำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateEditCourse(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "สร้างบทเรียนไม่สำเร็จ", configToast);
      handleLoaded();
    }
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      display="flex"
      flexDirection={{ xs: "column-reverse", md: "row" }}
      gap="16px"
    >
      <Box
        sx={{ ...boxStyle, height: "fit-content" }}
        width={{ xs: 1, md: "60%" }}
      >
        <FormControl>
          <TextField
            fullWidth
            type="text"
            label="ชื่อบทเรียน"
            placeholder="กรอกชื่อบทเรียน"
            {...register("title", { required: true })}
            size="medium"
          />
        </FormControl>

        <FormControl>
          <CKEditor
            editor={Editor}
            onChange={handleEditorChange}
            config={editorConfig}
          />
        </FormControl>

        <FormControl>
          <TextField
            fullWidth
            type="text"
            label="ลิงค์วิดีโอ"
            placeholder="กรอกลิงค์วิดีโอ"
            {...register("clip_url", { required: true })}
            size="medium"
          />
        </FormControl>

        <FormControl>
          <TextField
            fullWidth
            type="number"
            label="ลำดับบทเรียน"
            placeholder="กรอกลำดับบทเรียน"
            {...register("lesson_order", { required: true, min: 0 })}
            size="medium"
          />
        </FormControl>

        <Box display="flex" gap="16px">
          <FormControl fullWidth>
            <TextField
              fullWidth
              type="number"
              label="เวลาเรียนรู้ (นาที)"
              placeholder="กรอกเวลาเรียนรู้ (นาที)"
              {...register("minutes", { required: true, min: 0 })}
              size="medium"
            />
          </FormControl>

          <FormControl fullWidth>
            <TextField
              type="number"
              label="เวลาเรียนรู้ (วินาที)"
              placeholder="กรอกเวลาเรียนรู้ (วินาที)"
              {...register("second", { required: true, min: 0 })}
              size="medium"
            />
          </FormControl>
        </Box>

        <Box display="flex" gap="16px">
          <FormControl fullWidth>
            <TextField
              fullWidth
              type="number"
              label="เวลาเรียนรู้ขั้นต่ำ (นาที)"
              placeholder="กรอกเวลาเรียนรู้ขั้นต่ำ (นาที)"
              {...register("minutes_minimum", { required: true, min: 0 })}
              size="medium"
            />
          </FormControl>

          <FormControl fullWidth>
            <TextField
              type="number"
              label="เวลาเรียนรู้ขั้นต่ำ (วินาที)"
              placeholder="กรอกเวลาเรียนรู้ขั้นต่ำ (วินาที)"
              {...register("second_minimum", { required: true, min: 0 })}
              size="medium"
            />
          </FormControl>
        </Box>

        <FormControl>
          <TextField
            fullWidth
            type="number"
            label="เกณฑ์การผ่านบทเรียน (ข้อ)"
            placeholder="กรอกเกณฑ์การผ่านบทเรียน (ข้อ)"
            {...register("passed_criteria", { required: true, min: 0 })}
            size="medium"
          />
        </FormControl>

        <FormControl>
          <TextField
            fullWidth
            type="number"
            label="ระยะห่าง การทำแบบทดสอบ (ช.ม.)"
            placeholder="ระยะห่าง การทำแบบทดสอบ (ช.ม.)"
            {...register("gap_time", { required: true, min: 0 })}
            size="medium"
          />
        </FormControl>

        <Box display="flex" justifyContent="center" gap="16px">
          <Box>
            <Button
              variant="contained"
              startIcon={<SaveIcon sx={{ color: "white" }} />}
              color="green"
              size="large"
              type="submit"
              disabled={!isValid || !isDirty || !lessonFile || loading}
            >
              <Typography color="white">บันทึก</Typography>
            </Button>
          </Box>

          <Box>
            <Button
              variant="contained"
              startIcon={<CancelIcon sx={{ color: "white" }} />}
              color="red"
              size="large"
              type="button"
              component={RouterLink}
              to={`/edit-course/${courseId}`}
            >
              <Typography color="white">ยกเลิก</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{ ...boxStyle, height: "fit-content" }}
        width={{ xs: 1, md: "40%" }}
      >
        <Typography color="cGrey.main">อัพโหลดไฟล์</Typography>

        <Box>
          <Button variant="contained" color="green" onClick={handleUploadClick}>
            <Box
              component="input"
              hidden
              accept="application/pdf"
              type="file"
              ref={inputRef}
              onChange={handleFileChange}
            />
            <Typography color="white">UPLOAD FILE</Typography>
          </Button>
        </Box>

        {lessonFile && (
          <Typography color="cGrey.main">{lessonFile.name}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default CreateLesson;
