import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../../shared/style";

const CourseReport = ({ open, handleClose, summary }: any) => {
  const [value, setValue] = useState<any>("1");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        รายงาน
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="จำนวนคนเข้าเรียน" value="1" />
              <Tab label="ข้อมูลบทเรียน" value="2" />
              <Tab label="สอบประเมิน" value="3" />
            </TabList>
            <TabPanel value="1">
              <AttendedCounts summary={summary} />
            </TabPanel>
            <TabPanel value="2">
              <MemberTypeCounts summary={summary} />
            </TabPanel>
            <TabPanel value="3">
              <PassedCounts summary={summary} />
            </TabPanel>
          </Box>
        </TabContext>
      </DialogContent>
    </Dialog>
  );
};

const AttendedCounts = ({ summary }: any) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", borderRadius: 0 }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">ประเภท</StyledTableCell>
            <StyledTableCell align="center">จำนวน</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summary.attended_counts.map((row: any, index: number) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.member_type}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="center">
                {parseInt(row.attended_count || 0).toLocaleString()}
              </StyledTableCell>
            </StyledTableRow>
          ))}
          {!summary.attended_counts.length && (
            <StyledTableRow>
              <StyledTableCell
                component="th"
                scope="row"
                colSpan={2}
                align="center"
              >
                ไม่พบข้อมูล
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MemberTypeCounts = ({ summary }: any) => {
  return (
    <>
      {summary.member_type_counts.map((row: any, index: number) => (
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {index ? `บทที่ ${index}` : `บทนำ`}
                </StyledTableCell>
                <StyledTableCell align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  จำนวนคนเข้าเรียน
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  {parseInt(row.attended_count || 0).toLocaleString()}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  ผ่านระยะเวลา
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  {parseInt(row.passed_time_count || 0).toLocaleString()}
                </StyledTableCell>
              </StyledTableRow>{" "}
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  ผ่านแบบทดสอบ
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  {parseInt(row.passed_quiz_count || 0).toLocaleString()}
                </StyledTableCell>
              </StyledTableRow>
              {!summary.member_type_counts.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={2}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </>
  );
};

const PassedCounts = ({ summary }: any) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "none", borderRadius: 0 }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center"></StyledTableCell>
            <StyledTableCell align="center">ผ่าน</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summary.passed_counts.map((row: any, index: number) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.title}
              </StyledTableCell>
              <StyledTableCell component="th" scope="row" align="center">
                {parseInt(row.passed_count || 0).toLocaleString()}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CourseReport;
