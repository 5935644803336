import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import saveAs from "file-saver";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import th from "dayjs/locale/th";

import { useAppDispatch, useAppSelector } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";
import {
  getDistrictService,
  getProvinceService,
} from "../../services/masterData.service";
import { getReportCommunitySummaryService } from "../../services/report.service";

import { boxStyle, communityTypes } from "../../constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { OverwriteAdapterDayjs } from "../../utils/common.util";
import { StyledTableCell, StyledTableRow } from "../../shared/style";
import { getTask, setTask } from "../../state/libs/taskSlice";

const contentToolbar = {
  toolbar: {
    title: "หน้าหลัก (รายงาน)",
    subtitles: ["รายงานกลุ่ม"],
  },
};

const CommunitySummary = () => {
  const dispatch = useAppDispatch();
  const taskState = useAppSelector(getTask);

  const [communities, setCommunities] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [provinces, setProvinces] = useState([]);
  const [provinceId, setProvinceId] = useState<any>("");
  const [districs, setDistrics] = useState([]);
  const [provincesFittered, setProvincesFittered] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string | null>(null);
  const [toDate, setToDate] = useState<string | null>(null);

  const { register, getValues, setValue } = useForm({
    defaultValues: {
      community_code: "",
      type_id: "",
      district_id: "",
      province_id: "",
      zone: "",
      from_date: "",
      to_date: "",
    },
  });

  const getProvinces = () => {
    getProvinceService().then((resp) => setProvinces(resp.data));
  };

  const getDistricts = (provinceId: string) => {
    getDistrictService(provinceId).then((resp) => setDistrics(resp.data));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOnSearch = () => {
    setPage(0);
    getCommunities();
  };

  const handleZoneChange = (event: SelectChangeEvent) => {
    setProvincesFittered(
      !event.target.value
        ? provinces
        : provinces.filter((t: any) => t.zone === parseInt(event.target.value))
    );
    setProvinceId("");
    setValue("province_id", "");
    setValue("district_id", "");

    setDistrics([]);
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setProvinceId(event.target.value);
    getDistricts(event.target.value);
    setValue("district_id", "");
  };

  const handleFromDateChange = (value: any) => {
    const date = dayjs(value).format("YYYY-MM-DD");
    setFromDate(date);
    setValue("from_date", date);
  };

  const handleToDateChange = (value: any) => {
    const date = dayjs(value).format("YYYY-MM-DD");
    setToDate(date);
    setValue("to_date", date);
  };

  const handleExportReport = () => {
    dispatch(
      setTask({
        id: "communitySummaryReport",
        reportName: "communitySummaryReport",
        process: true,
        processing: false,
        filters: getValues(),
      })
    );
  };

  const getCommunities = () => {
    setLoading(true);
    getReportCommunitySummaryService({
      size: rowsPerPage,
      page: page + 1,
      ...getValues(),
    }).then((resp) => {
      setLoading(false);
      setCommunities(resp.data.items);
      setCount(resp.data.total_row);
    });
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(setContentToolbar, [dispatch]);

  useEffect(getCommunities, [getValues, page, rowsPerPage]);

  useEffect(getProvinces, []);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box sx={boxStyle}>
        <Box
          display="flex"
          justifyContent="space-between"
          gap="16px"
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box display="flex" flexDirection="column" gap={4} width={1}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="zone-label">เขตสุขภาพ</InputLabel>
                  <Select
                    labelId="zone-label"
                    id="province"
                    label="เขตสุขภาพ"
                    {...register("zone")}
                    onChange={handleZoneChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {Array.from(Array(13).keys()).map((index: any) => (
                      <MenuItem key={index} value={(index + 1).toString()}>
                        {"เขตสุขภาพที่ " + (index + 1)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="province-label">จังหวัด</InputLabel>
                  <Select
                    labelId="province-label"
                    id="province"
                    label="จังหวัด"
                    value={provinceId}
                    {...register("province_id")}
                    onChange={handleProvinceChange}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {provincesFittered.map(
                      (p: { id: number; name: string }) => (
                        <MenuItem key={p.id} value={p.id.toString()}>
                          {p.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="district-label">อำเภอ</InputLabel>
                  <Select
                    labelId="district-label"
                    id="district"
                    label="อำเภอ"
                    {...register("district_id", { required: true })}
                  >
                    <MenuItem key={0} value="">
                      ทั้งหมด
                    </MenuItem>
                    {districs.map((d: { id: number; name: string }) => (
                      <MenuItem key={d.id} value={d.id.toString()}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="รหัสกลุ่ม"
                    placeholder="ค้นหารหัสกลุ่ม"
                    {...register("community_code")}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="type-label">ประเภทกลุ่ม</InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    label="ประเภทกลุ่ม"
                    {...register("type_id", { required: true })}
                  >
                    <MenuItem key={0} value={""}>
                      ทั้งหมด
                    </MenuItem>
                    {communityTypes.map((str: string, index: number) => (
                      <MenuItem key={index} value={index + 1}>
                        {str}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              {" "}
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <LocalizationProvider
                    dateAdapter={OverwriteAdapterDayjs}
                    adapterLocale={th}
                  >
                    <DatePicker
                      label="วันเริ่ม"
                      views={["year", "month", "day"]}
                      inputFormat="DD/MMM/BBBB"
                      value={fromDate}
                      onChange={handleFromDateChange}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "วว/ดด/ปปปป",
                            readOnly: true,
                          }}
                          {...register("from_date", { required: true })}
                        />
                      )}
                    ></DatePicker>
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <LocalizationProvider
                    dateAdapter={OverwriteAdapterDayjs}
                    adapterLocale={th}
                  >
                    <DatePicker
                      label="วันสิ้นสุด"
                      views={["year", "month", "day"]}
                      inputFormat="DD/MMM/BBBB"
                      value={toDate}
                      onChange={handleToDateChange}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "วว/ดด/ปปปป",
                            readOnly: true,
                          }}
                          {...register("to_date", { required: true })}
                        />
                      )}
                    ></DatePicker>
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Box display="flex" gap="8px" height="fit-content">
            <Box sx={{ position: "relative" }}>
              <Button
                variant="contained"
                startIcon={<SearchIcon sx={{ color: "white" }} />}
                color="green"
                disabled={loading}
                onClick={handleOnSearch}
              >
                <Typography color="white">ค้นหา</Typography>
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>

            <Box sx={{ position: "relative" }}>
              <Button
                variant="contained"
                color="blue"
                disabled={taskState.tasks.find(
                  (task: any) => task.id === "communitySummaryReport"
                )}
                onClick={handleExportReport}
              >
                <Typography color="white">EXPORT</Typography>
              </Button>
              {taskState.tasks.find(
                (task: any) => task.id === "communitySummaryReport"
              ) && (
                <CircularProgress
                  color="blue"
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={boxStyle}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ลำดับ</StyledTableCell>
                <StyledTableCell>รหัสกลุ่ม</StyledTableCell>
                <StyledTableCell>ชื่อกลุ่ม</StyledTableCell>
                <StyledTableCell>ผู้สร้างกลุ่ม</StyledTableCell>
                <StyledTableCell>ประเภทกลุ่ม</StyledTableCell>
                <StyledTableCell>จำนวนสมาชิก</StyledTableCell>
                <StyledTableCell align="right">แคลอรี่</StyledTableCell>
                <StyledTableCell align="right">Health Point</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {communities.map((row, index) => (
                <TableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {(page - 1) * rowsPerPage + rowsPerPage + (index + 1)}
                  </StyledTableCell>
                  <StyledTableCell>{row.community_code}</StyledTableCell>
                  <StyledTableCell>{row.community_name}</StyledTableCell>
                  <StyledTableCell>{row.community_admin}</StyledTableCell>
                  <StyledTableCell>{row.community_type}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.member_count?.toLocaleString() || 0}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.calories?.toLocaleString() || 0}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.experiences?.toLocaleString() || 0}
                  </StyledTableCell>
                </TableRow>
              ))}
              {!communities.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={8}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default CommunitySummary;
