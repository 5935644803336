import axiosService from "./axios.service";

interface Filters {
  page: any;
  size: any;
}

export const getEventsService = (params: Filters) => {
  return axiosService.get("/event?" + new URLSearchParams({ ...params }));
};

export const getEventService = (id: any) => {
  return axiosService.get(`/event/${id}`);
};

export const postEventService = (body: any) => {
  return axiosService.post("/event", body);
};

export const patchEventService = (id: any, body: any) => {
  return axiosService.patch(`/event/${id}`, body);
};

export const postEventUploadService = (id: any, formData: FormData) => {
  return axiosService.post(`/event/event-upload/${id}`, formData);
};

export const deleteEventService = (id: any) => {
  return axiosService.delete(`/event/${id}`);
};

export const getEvenMemberActivityService = (id: any, ipAddress: string) => {
  return axiosService.get(
    "/event/report/event-member-activity/job" + "/" + id,
    {
      params: { fe_ip: ipAddress },
    }
  );
};

export const getRegisteredMemberService = (id: any, ipAddress: string) => {
  return axiosService.get("/event/report/event-member/job" + "/" + id, {
    params: { fe_ip: ipAddress },
  });
};
