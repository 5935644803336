import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export class OverwriteAdapterDayjs extends AdapterDayjs {
  format = (date: any, formatKey: any) => {
    switch (formatKey) {
      case "dayOfMonth":
        return date.$D;
      case "monthAndYear":
        return dayjs(date).locale("th").format("DD MMMM BBBB");
      case "year":
        return dayjs(date).format("BBBB");
      case "monthShort":
        return dayjs(date).format("MMM");
      default:
        return "";
    }
  };
}

export const getFileNameFromUrl = (url: string) => {
  const segments = url ? url.split("/") : [];
  const filename = segments.pop();
  return filename || "";
};
