export const setItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const getItem = (key: string) => {
  const string = localStorage.getItem(key);
  const value = string ? JSON.parse(string as string) : null;
  return value;
};

export const removeItem = (key: string) => {
  localStorage.removeItem(key);
};

