import axiosService from "./axios.service";

interface Filters {
  page: any;
  size: any;
}

// course
export const getCourseService = (params: Filters) => {
  return axiosService.get("/course?" + new URLSearchParams({ ...params }));
};

export const getCourseByIdService = (id: any) => {
  return axiosService.get(`/course/${id}`);
};

export const postCourseService = (body: any) => {
  return axiosService.post("/course", body);
};

export const patchCourseService = (id: any, body: any) => {
  return axiosService.patch(`/course/${id}`, body);
};

export const deleteCourseService = (id: any) => {
  return axiosService.delete(`/course/${id}`);
};

export const postCourseUploadService = (id: any, formData: FormData) => {
  return axiosService.post(`/course/course/${id}`, formData);
};

export const getCourseReport = (id: any) => {
  return axiosService.get(`/course/${id}/report`, {
    responseType: "blob",
  });
};

// lesson
export const getLessonService = (courseId: any) => {
  return axiosService.get(`/course/${courseId}/lesson`);
};

export const getLessonByIdService = (lessonId: any) => {
  return axiosService.get(`/course/lesson/${lessonId}`);
};

export const postLessonService = (courseId: any, body: any) => {
  return axiosService.post(`/course/${courseId}/lesson`, body);
};

export const patchLessonService = (lessonId: any, body: any) => {
  return axiosService.patch(`/course/lesson/${lessonId}`, body);
};

export const deleteLessonService = (lessonId: any) => {
  return axiosService.delete(`/course/lesson/${lessonId}`);
};

export const postLessonUploadService = (lessonId: any, formData: FormData) => {
  return axiosService.post(`/course/lesson/${lessonId}`, formData);
};

// lesson quiz
export const getLessonQuizService = (lessonId: any) => {
  return axiosService.get(`/course/${lessonId}/lesson-quiz`);
};

export const getLessonQuizByIdService = (lessonQuizId: any) => {
  return axiosService.get(`/course/lesson-quiz/${lessonQuizId}`);
};

export const postLessonQuizService = (
  courseId: any,
  lessonId: any,
  body: any
) => {
  return axiosService.post(
    `/course/${courseId}/lesson/${lessonId}/lesson-quiz`,
    body
  );
};

export const patchLessonQuizService = (lessonQuizId: any, body: any) => {
  return axiosService.patch(`/course/lesson-quiz/${lessonQuizId}`, body);
};

export const deleteLessonQuizService = (lessonQuizId: any) => {
  return axiosService.delete(`/course/lesson-quiz/${lessonQuizId}`);
};

// member evaluation
export const getMemberEvaluationService = (params: Filters) => {
  return axiosService.get(
    "/course/member/evaluation?" + new URLSearchParams({ ...params })
  );
};

export const patchMemberEvaluationService = (id: any, body: any) => {
  return axiosService.patch(`/course/member-evaluation/${id}`, body);
};
