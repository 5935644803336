import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-classic";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { boxStyle, configToast, statusTypes } from "../../constants";
import { postFaqService } from "../../services/faq.service";
import { useAppDispatch } from "../../state/hook";
import { setApp } from "../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "การตั้งค่าเว็บไซต์",
    subtitles: ["จัดการคำถามที่พบบ่อย", "สร้างคำถามที่พบบ่อย"],
  },
};

const CreateFaq = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [saving, setSaving] = useState<boolean>(false);

  const { register, handleSubmit, formState, setValue } = useForm({
    defaultValues: {
      question: "",
      answer: "",
      order_no: null,
      is_publish: null,
    },
  });
  const { isValid, isDirty } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const handleNavigateFaqList = () => {
    navigate("/faq-list");
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      setSaving(true);
      await postFaqService({
        ...values,
        is_publish: !values.is_publish ? true : false,
      });

      toast.success("สร้างคำถามที่พบบ่อยสำเร็จ", {
        ...configToast,
        onClick: handleNavigateFaqList,
        onClose: handleNavigateFaqList,
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "สร้างคำถามที่พบบ่อยไม่สำเร็จ", configToast);
      setSaving(false);
    }
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(() => {
    register("answer", { required: true });
  }, [register]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      display="flex"
      flexDirection={{ xs: "column-reverse", md: "row" }}
      gap="16px"
    >
      <Box
        sx={{ ...boxStyle, height: "fit-content" }}
        width={{ xs: 1, md: "60%" }}
      >
        <FormControl>
          <TextField
            fullWidth
            type="text"
            label="หัวข้อคำถาม"
            placeholder="กรอกหัวข้อคำถาม"
            multiline
            rows={3}
            {...register("question", { required: true })}
            size="medium"
          />
        </FormControl>

        <FormControl>
          <CKEditor
            editor={Editor}
            config={{ placeholder: "คำตอบ" }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              setValue("answer", data);
            }}
          />
        </FormControl>

        <FormControl>
          <InputLabel id="status-label">สถานะ</InputLabel>
          <Select
            labelId="status-label"
            id="status-label"
            placeholder="เลือกสถานะ"
            label="สถานะ"
            {...register("is_publish", { required: true })}
            size="medium"
          >
            {statusTypes.map((status: string, index: number) => (
              <MenuItem key={index} value={index}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <TextField
            fullWidth
            type="number"
            label="ลำดับ"
            placeholder="กรอกลำดับ"
            {...register("order_no", { required: true, min: 0 })}
            size="medium"
          />
        </FormControl>

        <Box display="flex" justifyContent="center" gap="16px">
          <Box>
            <Button
              variant="contained"
              startIcon={<SaveIcon sx={{ color: "white" }} />}
              color="green"
              size="large"
              type="submit"
              disabled={!isValid || !isDirty || saving}
            >
              <Typography color="white">บันทึก</Typography>
            </Button>
          </Box>

          <Box>
            <Button
              variant="contained"
              startIcon={<CancelIcon sx={{ color: "white" }} />}
              color="red"
              size="large"
              type="button"
              component={RouterLink}
              to="/faq-list"
            >
              <Typography color="white">ยกเลิก</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateFaq;
