import { createSlice } from "@reduxjs/toolkit";

interface Task {
  id: string;
  reportName: string;
  process: boolean;
  processing: boolean;
  filters: {};
  extraFileName?: string;
}

interface TaskState {
  tasks: Task[];
}

export const taskSlice = createSlice({
  name: "task",
  initialState: { tasks: [] } as TaskState,
  reducers: {
    setTask: (state, { payload }) => {
      // Adding a new task to the array
      state.tasks = [...state.tasks, payload];
    },
    updateTaskById: (state, { payload }) => {
      const { id, updatedTask } = payload;

      // Replace the task with the matching ID
      state.tasks = state.tasks.map((task) =>
        task.id === id ? { ...task, ...updatedTask } : task
      );
    },
    removeTaskById: (state, { payload }) => {
      const { id } = payload;

      // Filter out the task with the matching ID
      state.tasks = state.tasks.filter((task) => task.id !== id);
    },
  },
});

export const getTask = (state: any) => state.task;

export const { setTask, updateTaskById, removeTaskById } = taskSlice.actions;

export default taskSlice.reducer;
