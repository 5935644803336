import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import {
  deleteAdminService,
  getAdminsService,
} from "../../../services/admin.service";
import { useAppDispatch } from "../../../state/hook";
import {
  boxStyle,
  configToast,
  modalStyle,
  statusUsers,
} from "../../../constants";
import { StyledTableCell, StyledTableRow } from "../../../shared/style";
import DeleteModal from "../../../components/modals/DeleteModal";
import { setApp } from "../../../state/libs/appSlice";

const contentToolbar = {
  toolbar: {
    title: "จัดการกลุ่มผู้ใช้งาน",
    subtitles: ["ผู้ใช้งานหลังบ้าน"],
  },
};

const AdminList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [admins, setAdmins] = useState<any[]>([]);
  const [openWarning, setWarningOpen] = useState<boolean>(false);
  const [adminId, setAdminId] = useState<number | null>(null);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const { register, getValues } = useForm({
    defaultValues: {
      username: "",
      email: "",
      phone_no: "",
    },
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigateEditAdmin = (username: string) => {
    navigate(`/edit-admin/${username}`);
  };

  const handleWarningOpen = (id: number) => {
    setAdminId(id);
    setWarningOpen(true);
  };
  const handleWarningClose = () => {
    setAdminId(null);
    setWarningOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const id = adminId as number;
      handleWarningClose();
      await deleteAdminService(id);
      getAdmins();
      toast.success("ลบผู้ใช้งานสำเร็จ", configToast);
    } catch (error) {
      toast.error("ลบผู้ใช้งานไม่สำเร็จ", configToast);
    }
  };

  const handleOnSearch = () => {
    getAdmins();
  };

  const getAdmins = () => {
    getAdminsService({
      size: rowsPerPage,
      page: page + 1,
      ...getValues(),
    }).then((resp) => {
      setAdmins(resp.data.items);
      setCount(resp.data.total_row);
    });
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(getAdmins, [getValues, page, rowsPerPage]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box sx={boxStyle}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          gap="16px"
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                type="text"
                placeholder="ค้นหาผู้ใช้งาน"
                {...register("username")}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                type="text"
                placeholder="ค้นหาอีเมล"
                {...register("email", {
                  minLength: 10,
                  maxLength: 10,
                })}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                type="text"
                placeholder="ค้นหาเบอร์โทรศัพท์"
                {...register("phone_no", {
                  minLength: 10,
                  maxLength: 10,
                })}
              />
            </Grid>
          </Grid>

          <Box display="flex" flexDirection="column" gap="8px">
            <Button
              variant="contained"
              startIcon={<SearchIcon sx={{ color: "white" }} />}
              color="green"
              onClick={handleOnSearch}
            >
              <Typography color="white">ค้นหา</Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box sx={boxStyle}>
        <Box>
          <Button
            color="green"
            variant="contained"
            startIcon={<SaveIcon sx={{ color: "white" }} />}
            component={RouterLink}
            to="/create-admin"
          >
            <Typography color="white">สร้าง</Typography>
          </Button>
        </Box>

        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>ลำดับ</StyledTableCell>
                <StyledTableCell>ชื่อ</StyledTableCell>
                <StyledTableCell>นามสกุล</StyledTableCell>
                <StyledTableCell>กลุ่มผู้ใช้</StyledTableCell>
                <StyledTableCell>EMAIL</StyledTableCell>
                <StyledTableCell>สถานะ</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {admins.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.id}
                  </StyledTableCell>
                  <StyledTableCell>{row.first_name}</StyledTableCell>
                  <StyledTableCell>{row.last_name}</StyledTableCell>
                  <StyledTableCell>
                    {"Admin"} {row.role_id === 1 && "ส่วนกลาง"}
                    {row.role_id === 4 && "เขต"}
                  </StyledTableCell>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell>
                    {statusUsers[row.active_flag ? 0 : 1]}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box display="flex" gap="8px">
                      <Box
                        component="a"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleNavigateEditAdmin(row.username)}
                      >
                        <ModeEditIcon />
                      </Box>
                      <Box
                        component="a"
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleWarningOpen(row.id)}
                      >
                        <DeleteIcon />
                      </Box>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
              {!admins.length && (
                <StyledTableRow>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    colSpan={7}
                    align="center"
                  >
                    ไม่พบข้อมูล
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {/* modals */}
        <Modal keepMounted open={openWarning} onClose={handleWarningClose}>
          <Box sx={modalStyle} width={340}>
            {adminId && (
              <DeleteModal
                handleConfirmDelete={handleConfirmDelete}
                handleWarningClose={handleWarningClose}
                confirm={true}
              />
            )}
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default AdminList;
