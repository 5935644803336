import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Resizer from "react-image-file-resizer";

import { boxStyle, communityTypes, configToast } from "../../constants";
import { setApp } from "../../state/libs/appSlice";
import {
  getCommunityByIdService,
  getCommunityMemberService,
  patchCommunityByIdService,
  postCommunityUploadService,
} from "../../services/community.service";
import { useAppDispatch } from "../../state/hook";
import {
  getDistrictService,
  getProvinceService,
  getSubdistrictService,
} from "../../services/masterData.service";

const contentToolbar = {
  toolbar: {
    title: "จัดการกลุ่ม",
    subtitles: ["จัดการกลุ่ม", "แก้ไขกลุ่ม"],
  },
};

const EditCommunity = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [community, setCommunity] = useState<any>(null);
  const [provinces, setProvinces] = useState([]);
  const [districs, setDistrics] = useState([]);
  const [members, setMembers] = useState([]);
  const [communityFile, setCommunityFile] = useState<File | null>(null);
  const [subdistrict, setSubdistricts] = useState([]);
  const [fileDataURL, setFileDataURL] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);

  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    Resizer.imageFileResizer(
      file,
      500,
      500,
      "PNG",
      100,
      0,
      (file: any) => {
        setCommunityFile(file);
      },
      "file"
    );

    inputRef.current.value = "";
  };

  const handleFileReader = () => {
    let fileReader: any,
      isCancel = false;
    if (communityFile) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(communityFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  };

  const { register, handleSubmit, formState, setValue } = useForm({
    mode: "all",
  });
  const { isValid } = formState;
  const onError = (errors: any, e: any) => console.log(errors, e);

  const getCommunityById = () => {
    getCommunityByIdService(id).then((resp) => {
      setCommunity(resp.data);
    });
  };

  const getCommunityMember = () => {
    getCommunityMemberService(id).then((resp) => {
      setMembers(resp.data);
    });
  };

  const getProvinces = () => {
    getProvinceService().then((resp) => setProvinces(resp.data));
  };

  const getDistricts = async (provinceId: string) => {
    const response = await getDistrictService(provinceId);
    setDistrics(response.data);
  };

  const getSubdistricts = async (districtId: any) => {
    const response = await getSubdistrictService(districtId);
    setSubdistricts(response.data);
  };

  const setForm = () => {
    if (community) {
      getDistricts(community.province_id);
      getSubdistricts(community.district_id);

      setValue("community_code", community.community_code);
      setValue("type_id", community.type_id);
      setValue("community_name", community.community_name);
      setValue("contact_info", community.contact_info);
      setValue("community_desc", community.community_desc);
      setValue("max_member", community.max_member);
      setValue("is_active", community.is_active ? 0 : 1);
      setValue("province_id", community.province_id);
      setValue("district_id", community.district_id);
      setValue("subdistrict_id", community.subdistrict_id);

      setFileDataURL(community.image_url);
    }
  };

  const handleNavigateCommunityList = () => {
    navigate("/community-list");
  };

  const handleProvinceChange = (event: SelectChangeEvent) => {
    setValue("district_id", "");
    setValue("subdistrict_id", "");

    setDistrics([]);
    setSubdistricts([]);
    getDistricts(event.target.value);
  };

  const handleDistrictChange = (event: SelectChangeEvent) => {
    setValue("subdistrict_id", "");

    setSubdistricts([]);
    getSubdistricts(event.target.value);
  };

  const onSubmitHandler: SubmitHandler<any> = async (values) => {
    try {
      handleLoading();

      await patchCommunityByIdService(id, {
        ...values,
        is_active: !values.is_active ? true : false,
      });

      if (communityFile) {
        const formdata = new FormData();
        formdata.append("file", communityFile as File);
        postCommunityUploadService(id, formdata);
      }

      toast.success("แก้ไขกลุ่มสำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateCommunityList(),
      });
    } catch (error: any) {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      toast.error(resMessage || "แก้ไขกลุ่มไม่สำเร็จ", configToast);
      handleLoaded();
    }
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(getCommunityById, [id]);

  useEffect(getCommunityMember, [id]);

  useEffect(setForm, [community, setValue]);

  useEffect(getProvinces, []);

  useEffect(handleFileReader, [communityFile]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmitHandler, onError)}
      display="flex"
      flexDirection={{ xs: "column-reverse", md: "row" }}
      gap="16px"
    >
      {community && (
        <>
          <Box
            sx={{ ...boxStyle, height: "fit-content" }}
            width={{ xs: 1, md: "60%" }}
          >
            <FormControl>
              <TextField
                type="text"
                label="รหัสกลุ่ม"
                {...register("community_code", { disabled: true })}
                size="medium"
              />
            </FormControl>

            <FormControl>
              <InputLabel id="type-label">เลือกประเภทกลุ่ม</InputLabel>
              <Select
                defaultValue={community.type_id}
                labelId="type-label"
                id="type-label"
                label="เลือกประเภทกลุ่ม"
                sx={{
                  "& .MuiSelect-select .notranslate::after":
                    "กรุณาเลือกประเภทกลุ่ม"
                      ? {
                          content: `"กรุณาเลือกประเภทกลุ่ม"`,
                          opacity: 0.42,
                        }
                      : {},
                }}
                {...register("type_id", { required: true })}
              >
                {communityTypes.map((str: string, index: number) => (
                  <MenuItem key={index} value={index + 1}>
                    {str}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <TextField
                type="text"
                label="ชื่อกลุ่ม"
                placeholder="กรอกชื่อกลุ่ม"
                {...register("community_name", {
                  required: true,
                  maxLength: 150,
                })}
                helperText="* ชื่อกลุ่มสังกัด สามารถใส่ได้ไม่เกิน 150 ตัวอักษร"
                size="medium"
              />
            </FormControl>

            <FormControl>
              <InputLabel id="admin-id">ผู้ดูแล</InputLabel>
              <Select
                defaultValue={community.community_admin_id}
                labelId="admin-id"
                id="admin-id"
                label="เลือกผู้ดูแล"
                sx={{
                  "& .MuiSelect-select .notranslate::after": "กรุณาเลือกผู้ดูแล"
                    ? {
                        content: `"กรุณาเลือกผู้ดูแล"`,
                        opacity: 0.42,
                      }
                    : {},
                }}
                {...register("member_id", { required: true })}
              >
                {members.map((row: any, index: number) => (
                  <MenuItem key={index} value={row.member_id}>
                    {row.fullname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <TextField
                type="text"
                label="ข้อมูลการติดต่อผู้ดูแล"
                placeholder="กรอกข้อมูลการติดต่อผู้ดูแล"
                {...register("contact_info", {
                  required: true,
                })}
                size="medium"
              />
            </FormControl>

            <FormControl>
              <TextField
                type="text"
                label="คำอธิบายกลุ่ม"
                placeholder="กรอกคำอธิบายกลุ่ม"
                {...register("community_desc", {
                  required: true,
                })}
                size="medium"
              />
            </FormControl>

            <FormControl>
              <TextField
                fullWidth
                type="text"
                label="จำนวนสมาชิก"
                placeholder="กรอกจำนวนสมาชิก"
                {...register("max_member", {})}
                size="medium"
              />
            </FormControl>

            <Box display="flex" justifyContent="center" gap="16px">
              <Box>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon sx={{ color: "white" }} />}
                  color="green"
                  size="large"
                  type="submit"
                  disabled={!isValid || loading}
                >
                  <Typography color="white">บันทึก</Typography>
                </Button>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  startIcon={<CancelIcon sx={{ color: "white" }} />}
                  color="red"
                  size="large"
                  type="button"
                  component={RouterLink}
                  to="/community-list"
                >
                  <Typography color="white">ยกเลิก</Typography>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{ ...boxStyle, height: "fit-content" }}
            width={{ xs: 1, md: "40%" }}
          >
            <Typography typography="h5" color="cGrey.main">
              ที่อยู่กลุ่ม
            </Typography>

            <FormControl>
              <InputLabel id="province-label">จังหวัด</InputLabel>
              <Select
                labelId="province-label"
                id="province"
                label="จังหวัด"
                defaultValue={community.province_id}
                {...register("province_id", { required: true })}
                sx={{
                  "& .MuiSelect-select .notranslate::after": "กรุณาเลือกจังหวัด"
                    ? {
                        content: `"กรุณาเลือกจังหวัด"`,
                        opacity: 0.42,
                      }
                    : {},
                }}
                onChange={handleProvinceChange}
              >
                {provinces.map((p: { id: number; name: string }) => (
                  <MenuItem key={p.id} value={p.id.toString()}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="district-label">อำเภอ/เขต</InputLabel>
              <Select
                labelId="district-label"
                id="district"
                label="อำเภอ/เขต"
                defaultValue={community.district_id}
                sx={{
                  "& .MuiSelect-select .notranslate::after": "กรุณาเลือกอำเภอ"
                    ? {
                        content: `"กรุณาเลือกอำเภอ"`,
                        opacity: 0.42,
                      }
                    : {},
                }}
                {...register("district_id", { required: true })}
                onChange={handleDistrictChange}
              >
                {districs.map((d: { id: number; name: string }) => (
                  <MenuItem key={d.id} value={d.id.toString()}>
                    {d.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="subdistrict-label">ตำบล/แขวง</InputLabel>
              <Select
                labelId="subdistrict-label"
                id="subdistrict"
                label="ตำบล/แขวง"
                defaultValue={community.subdistrict_id}
                sx={{
                  "& .MuiSelect-select .notranslate::after": "กรุณาเลือกตำบล"
                    ? {
                        content: `"กรุณาเลือกตำบล"`,
                        opacity: 0.42,
                      }
                    : {},
                }}
                {...register("subdistrict_id", { required: true })}
              >
                {subdistrict.map((d: { id: number; name: string }) => (
                  <MenuItem key={d.id} value={d.id.toString()}>
                    {d.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="status-label">สถานะ Official Groups</InputLabel>
              <Select
                defaultValue={community.is_active ? 0 : 1}
                labelId="status-label"
                id="status-label"
                placeholder="เลือกสถานะ"
                label="สถานะ Official Groups"
                {...register("is_active", { required: true })}
                size="medium"
              >
                <MenuItem value={0}>ใช้งาน</MenuItem>
                <MenuItem value={1}>ไม่ใช้งาน</MenuItem>
              </Select>
            </FormControl>

            <Typography color="cGrey.main">อัพโหลดรูปภาพ</Typography>

            <Box>
              <Button
                variant="contained"
                color="green"
                onClick={handleUploadClick}
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  ref={inputRef}
                  onChange={handleFileChange}
                />
                <Typography color="white">UPLOAD FILE</Typography>
              </Button>
            </Box>

            <Box
              component="img"
              src={fileDataURL}
              sx={{
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                objectFit: "contain",
                width: 1,
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default EditCommunity;
