import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../shared/style";

const CodeUploadModal = ({
  codes,
  openPreViewCodes,
  handlePreViewCodesClose,
  acceptCodesUpload,
}: any) => {
  return (
    <Dialog keepMounted disableEscapeKeyDown open={openPreViewCodes}>
      <DialogContent sx={{ width: "500px", padding: "10px" }}>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            borderRadius: 0,
            height: "calc(100vh - 180px)",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>Code</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {codes.map((row: any, index: number) => (
                <StyledTableRow
                  key={index}
                  sx={{ background: row.isExist ? "#FFB100 !important" : "" }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.code}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ p: "16px", justifyContent: "center" }}>
        <Button
          color="red"
          variant="outlined"
          type="button"
          onClick={handlePreViewCodesClose}
        >
          <Typography variant="subtitle2">ยกเลิก</Typography>
        </Button>
        <Button
          color="green"
          variant="contained"
          type="button"
          onClick={acceptCodesUpload}
          disabled={codes.find((t: any) => t.isExist)}
        >
          <Typography variant="subtitle2" color="white">
            ยอมรับ
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CodeUploadModal;
