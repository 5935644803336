import mem from "mem";
import axiosService from "./axios.service";
import { getItem, removeItem, setItem } from "./localStorage.service";

export interface Login {
  username: string;
  password: string;
}

export const authLoginService = (body: Login) => {
  return axiosService.post("/auth/login", body);
};

export const authLogoutService = () => {
  return axiosService.post("/auth/logout");
};

const authRefreshTokenService = async () => {
  const session = getItem("session");

  try {
    const response = await axiosService.post("/auth/refresh-token", {
      refresh_token: session?.refresh_token,
    });

    if (!response?.data?.accessToken) {
      removeItem("session");
    }

    setItem("session", JSON.stringify(response.data));

    return response.data;
  } catch (error) {
    removeItem("session");
  }
};

export const memoizedRefreshToken = mem(authRefreshTokenService, {
  maxAge: 10000,
});
