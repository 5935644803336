import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";

import { boxStyle, configToast, statusTypes } from "../../constants";
import {
  Banner,
  getBannerService,
  patchBannerService,
  postBannerUploadService,
} from "../../services/banner.service";
import { setApp } from "../../state/libs/appSlice";
import { useAppDispatch } from "../../state/hook";

const contentToolbar = {
  toolbar: {
    title: "การจัดการแบนเนอร์",
    subtitles: ["รายการแบนเนอร์", "แก้ไขแบนเนอร์"],
  },
};

const EditBanner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [banner, setBanner] = useState<any>(null);
  const [bannerFile, setBannerFile] = useState<File | null>(null);
  const [fileDataURL, setFileDataURL] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { register, handleSubmit, formState, setValue } = useForm();
  const { isValid } = formState;

  const handleUploadClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const handleNavigateBanerList = () => {
    navigate("/banner-list");
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      100,
      0,
      (file: any) => {
        setBannerFile(file);
      },
      "file"
    );
  };

  const onSubmitHandler: SubmitHandler<Banner | any> = async (values) => {
    try {
      setSaving(true);
      await patchBannerService(id, {
        ...values,
        is_publish: !values.is_publish ? true : false,
      });

      if (bannerFile) {
        const formdata = new FormData();
        formdata.append("file", bannerFile as File);
        await postBannerUploadService(id, formdata);
      }

      toast.success("แก้ไขแบนเนอร์สำเร็จ", {
        ...configToast,
        onClose: () => handleNavigateBanerList(),
      });
    } catch (error) {
      toast.error("แก้ไขแบนเนอร์ไม่สำเร็จ", configToast);
      setSaving(false);
    }
  };

  const getBanner = () => {
    getBannerService(id).then((resp) => {
      setBanner(resp.data);
    });
  };

  const setContentToolbar = () => {
    dispatch(setApp(contentToolbar));
  };

  useEffect(() => {
    let fileReader: any,
      isCancel = false;
    if (bannerFile) {
      fileReader = new FileReader();
      fileReader.onload = (e: any) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(bannerFile);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [bannerFile]);

  useEffect(getBanner, [id]);

  useEffect(() => {
    if (banner) {
      setValue("redirect_url", banner.redirect_url);
      setValue("is_publish", banner.is_publish ? 0 : 1);
      setFileDataURL(banner.banner_url);
      setLoading(false);
    }
  }, [banner, setValue]);

  useEffect(setContentToolbar, [dispatch]);

  return (
    <Box display="flex" gap="16px">
      {!loading && (
        <>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            sx={{ ...boxStyle, gap: "32px" }}
            width="70%"
          >
            <FormControl>
              <TextField
                fullWidth
                type="text"
                label="Link"
                placeholder="กรอก Link"
                {...register("redirect_url", { required: true })}
                size="medium"
              />
            </FormControl>

            <FormControl>
              <InputLabel id="status-label">สถานะ</InputLabel>
              <Select
                defaultValue={banner?.is_publish ? 0 : 1}
                labelId="status-label"
                id="status-label"
                placeholder="สถานะ"
                label="สถานะ"
                {...register("is_publish", { required: true })}
                size="medium"
              >
                {statusTypes.map((status: string, index: number) => (
                  <MenuItem value={index}>{status}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box display="flex" justifyContent="center" gap="16px">
              <Box>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon sx={{ color: "white" }} />}
                  color="green"
                  size="large"
                  type="submit"
                  disabled={!isValid || saving}
                >
                  <Typography color="white">บันทึก</Typography>
                </Button>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  startIcon={<CancelIcon sx={{ color: "white" }} />}
                  color="red"
                  size="large"
                  type="button"
                  component={RouterLink}
                  to="/banner-list"
                >
                  <Typography color="white">ยกเลิก</Typography>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={{ ...boxStyle, height: "fit-content" }} width="30%">
            <Typography color="cGrey.main">อัพโหลดรูปภาพ</Typography>
            <Box>
              <Button
                variant="contained"
                color="green"
                size="large"
                onClick={handleUploadClick}
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  ref={inputRef}
                  onChange={handleFileChange}
                />
                <Typography color="white">UPLOAD FILE</Typography>
              </Button>
            </Box>

            <Box
              component="img"
              src={fileDataURL}
              sx={{
                boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.15)",
                objectFit: "contain",
                width: 1,
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default EditBanner;
